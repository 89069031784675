import { SESION_LOGOUT, USER_SESION } from "../constants";
export const initialState = {
    user: {},
    authenticated: false,
    token: ""
}

const userSesionReducer = (state = initialState, action) => {
    switch (action?.type) {
        case USER_SESION:
            return {
                ...state,
                user: action?.data,
                authenticated: action?.authenticated,
                token: action?.token
            };
        case SESION_LOGOUT:
            return {
                ...state,
                user: action?.data,
                authenticated: action?.authenticated,
                token: action?.token
            };

        default:
            return state;
    }
}

export default userSesionReducer;
