import { ICON_APPBAR } from "../constants";

const initialState = {
    icon: 0
};

const iconAppbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case ICON_APPBAR:
            
            return {
                ...state,
                icon: action.icon
            }
    
        default:
            return state;
    }
}
export default iconAppbarReducer;