// @vendors
import React, { useEffect } from "react";
import { InteractionType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";

// @style
import "./App.css";

// @components
import RoutesApp from "./components/routes/routes";
import ConfirmationMessage from "./utils/modal-confirm/confirm-message";

// @action
import {
  acquireAccessToken,
  getNotificationByIdEmployee,
  handleClickCallRedirect,
  jwtRol,
  saveTimeToken,
  SignOut,
  tokenExpirationTime,
} from "./utils/functions-utils";
import { getTokenByEmail } from "./actions/employee-action";
import { MSAL_DATA } from "./redux/constants";
import BackdropLoading from "./components/loading/backdrog";

function App({ config }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const userId = state?.userReducer.userData;
  const dateTime = window.localStorage.getItem("date_time");
  const instance = useMsal();

  const [message, setMessage] = React.useState({
    description: "",
    textButtonSubmit: "",
    handleClick: () => {},
    handleClickOut: () => {},
    oneButtons: false,
    type: "",
    open: false,
  });

  useEffect(() => {
    if (userId?.email) {
      const getTokenUserByEmail = async () => {
        const response = await getTokenByEmail(userId?.email);
        jwtRol(response?.data?.token, dispatch);
      };
      getTokenUserByEmail();
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (userId?.id) {
      getNotificationByIdEmployee(dispatch, userId?.id);
    }
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch({
      type: MSAL_DATA,
      config: config,
    });
  }, [dispatch, config]);

  const dateNow = new Date();
  useEffect(() => {
    setInterval(() => {
      if (dateNow.getTime() >= dateTime) {
        acquireAccessToken(config).then((accesstoken) => {
          const timeExp = tokenExpirationTime(accesstoken);
          if (timeExp <= dateNow) {
            handleClickCallRedirect(setMessage, instance);
            SignOut(dispatch);
          } else {
            saveTimeToken(timeExp);
            window.localStorage.setItem("token_app", accesstoken);
            window.location.reload();
          }
        });
      }
    }, 3000);
  }, [config, dateNow, dateTime, dispatch, instance]);

  return (
    <>
      <div className="App">
        <MsalProvider instance={config}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
          >
            <AuthenticatedTemplate>
              <RoutesApp />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              <h5 className="card-title">
                Please sign-in to see your profile information.
              </h5>
            </UnauthenticatedTemplate>
          </MsalAuthenticationTemplate>
        </MsalProvider>
        <BackdropLoading />
      </div>
      <ConfirmationMessage
        description={message.description}
        textButtonSubmit={message.textButtonSubmit}
        handleClick={message.handleClick}
        handleClickOut={message.handleClickOut}
        oneButtons={message.oneButtons}
        type={message.type}
        open={message.open}
      />
    </>
  );
}

export default App;
