import { BACKDROP_LOADING } from "../constants";

const initialState = {
  isOpenBackdrop: false,
};

const backdropLoading = (state = initialState, action) => {
  switch (action.type) {
    case BACKDROP_LOADING:
      return {
        ...state,
        isOpenBackdrop: action.isOpenBackdrop,
      };

    default:
      return state;
  }
};
export default backdropLoading;
