import { LOCATION_TYPE } from "../constants";

const initialState = {
    types: []
}

const locationTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOCATION_TYPE:
            
            return {
                ...state,
                types: action?.types
            }
    
        default:
            return state;
    }
}

export default locationTypeReducer;