export const USER_SESION = "USER_SESION";
export const SESION_LOGOUT = "SESION_LOGOUT";
export const USER_DATA = "USER_DATA";
export const ICON_REDUCER = "ICON_REDUCER";
export const FILTER_RESERVATION = "FILTER_RESERVATION";
export const FILTER_LOCATION = "FILTER_LOCATION";
export const LOCATION_DETAIL = "LOCATION_DETAIL";
export const LOCATION_TYPE = "LOCATION_TYPE";
export const ICON_APPBAR = "ICON_APPBAR";
export const NOTIFICATION_DATA = "NOTIFICATION_DATA";
export const LIST_ROLES = "LIST_ROLES";
export const GRAPH_DATA = "GRAPH_DATA";
export const IS_VALID_TOKEN = "IS_VALID_TOKEN";
export const MSAL_DATA = "MSAL_DATA";
export const BACKDROP_LOADING = "BACKDROP_LOADING";
