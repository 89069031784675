// @vendors
import React from 'react';
import { AppBar } from '@mui/material';
import {useSelector} from 'react-redux';

// @components
import NavigationBar from './navigation-bar';

// @style
import './style-navigation.css';

const Navbar = () => {
    const state = useSelector((state) => state?.userSesionReducer);

  return state ? (
    state?.authenticated === true
        ?   <AppBar
                position='sticky'
                elevation={0}
                className='appBar'
            >
                <NavigationBar/>
            </AppBar>
        : null
    ) : null
}

export default Navbar;