//this data is used in the keep in mind component (reserve)
export const keepInMnd = [
    {id: 1, value: 'The length of time you have been employed at the company.' },
    {id: 2, value: 'Have more than 6 months tenure as an employee.' },
    {id: 3, value: 'In case of traveling with children, you must select a family location.' },
    {id: 4, value: 'By default, the tool adds you as a guest; in the number of guests, please add the additional parties that will go with you.' },
]

// data used in select (all reservations)
export const reservationStatus = [
    {id: 'Approved', name: 'Approved'},
    //{id: 'Canceled', name: 'Canceled'},
    {id: 'Pending', name: 'Pending'},
    {id: 'Rejected', name: 'Rejected'},
];

// roles availables
export const listRoles = [
    {id: 'HR', value: 'Human Resources'},
    {id: 'SUPERADMIN', value: 'Super Admin'},
    {id: 'MANAGER', value: 'Manager'}
]

export const listNumbers = [
    0,1,2,3,4,5,6
];

export const listNumbersGuest = [
    {id: 0, value: 0},
    {id: 1, value: 1},
    {id: 2, value: 2},
    {id: 3, value: 3},
    {id: 4, value: 4},
    {id: 5, value: 5},
    {id: 6, value: 6},
    {id: 7, value: 7},
    {id: 8, value: 8},
    {id: 9, value: 9},
    {id: 10, value: 10},
    {id: 11, value: 11},
    {id: 12, value: 12},
    {id: 13, value: 13},
    {id: 14, value: 14},
    {id: 15, value: 15},
    {id: 16, value: 16},
    {id: 17, value: 17},
    {id: 18, value: 18},
    {id: 19, value: 19},
    {id: 20, value: 20},
    {id: 21, value: 21},
    {id: 22, value: 22},
    {id: 23, value: 23},
    {id: 24, value: 24},
    {id: 25, value: 25},
];

// data used in select (is group)
export const dataIsGroup = [
    {id: true, value: 'True'},
    {id: false, value: 'False'},
]