import HttpClient from '../services/http-client';
import { HEADERS, LOCATIONTYPE } from './contants';

export const getLocationTypes = () => {
    return new Promise((resolve) => {
        HttpClient.get(`${LOCATIONTYPE}`, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}