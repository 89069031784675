// @vendors
import React from "react";
import Accordion from "@mui/material/Accordion";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import moment from "moment";
import { useDispatch } from "react-redux";

// @constants
import { headers } from "./headers";
import {
  AccordionSummary,
  MAXDAYSFORCANCEL,
  MESSAGECHANGESTATUS,
  MESSAGEERRORGENERAL,
} from "../../utils/constants-utils";

// @style
import "./style-my-reservation.css";

// @action
import { updateReservation } from "../../actions/reservations-action";

// @components
import { durationDays, handleOpenBackdrop } from "../../utils/functions-utils";

// @hooks
import { useMessageRequest } from "../../hooks/use-message-request";

const Reservation = ({
  info,
  cancel,
  openMessage,
  handleClickClose,
  getMyReservation,
  setIsBtnDisabled,
  isBtnDisabled,
  openMessageWarning,
}) => {
  const dispatch = useDispatch();
  const data = cancel ? info : { ...info, status: "Finished" };
  const { messageByRequest } = useMessageRequest();

  const getValueByKey = (object, header) => {
    if (header.child === null) {
      if (header.field === "location") {
        return data?.location?.name;
      }
      if (header.field === "isGroup") {
        const group = data?.isGroup === true ? "Yes" : "No";
        return group;
      }
      if (header.field === "days") {
        return durationDays(data?.arrival, data?.departure);
      }
      if (header.field === "arrival") {
        return moment(data?.arrival).format("DD-MM-YYYY");
      }
      if (header.field === "departure") {
        return moment(data?.departure).format("DD-MM-YYYY");
      }
      if (header.field) {
        let result = "";
        if (header.field.includes(",")) {
          header.field.split(/\s*,\s*/).forEach(function (item) {
            result += `${object[item] ?? ""}`;
          });
          return result;
        }
        return object[header.field];
      }
      return "";
    }
    return object[header.field] !== null ? object[header.field] : "";
  };

  const messageDateToCancel = (message) => {
    const msj = message ? message : MESSAGEERRORGENERAL;
    openMessageWarning(msj);
    return;
  };

  const handleClickCancelReser = (info) => {
    const date = moment(info?.arrival).toDate() - moment().toDate();
    const days = date / 86400000;
    if (days <= 15 && info?.status === "Approved")
      messageDateToCancel(MAXDAYSFORCANCEL);
    if (days <= 15 && info?.status !== "Approved") objBySentToCancel(info);
    if (days > 15) objBySentToCancel(info);
  };

  const objBySentToCancel = (info) => {
    const newData = {
      locationId: info?.location.id,
      employeeId: info?.employee.id,
      arrival: info?.arrival,
      departure: info?.departure,
      guests: info?.guests,
      numberBedrooms: info?.numberBedrooms,
      numberDoubleRooms: info?.numberDoubleRooms,
      status: "Canceled",
      active: true,
      description: info?.description,
      origin: info?.origin,
      reservationGuest: [],
    };
    messageHandleClickSent(info, newData);
  };

  const messageHandleClickSent = (info, newData) => {
    const msj = MESSAGECHANGESTATUS("cancel");
    const createObj = {
      id: info?.id,
      data: newData,
    };
    openMessage(msj, handleClickCancel, createObj);
  };

  const handleClickCancel = async (info) => {
    handleOpenBackdrop(dispatch, true);
    setIsBtnDisabled(true);
    handleClickClose();
    const response = await updateReservation(info?.id, info?.data);
    messageByRequest(response, openMessageWarning, "put", getMyReservation);
    setIsBtnDisabled(false);
    handleOpenBackdrop(dispatch, false);
  };

  return (
    <div>
      <Grid item xs={12} md={12} lg={12} mb={1}>
        <Accordion style={{ borderRadius: "10px", pointerEvents: "none" }}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <div className="contHeaderTableRes">
              {headers.initialHeaders.map((header, index) => (
                <div className="column" key={index}>
                  <Typography style={{ fontSize: 14 }}>
                    {header.title}
                  </Typography>
                  <Typography
                    className="secondaryHeading"
                    style={{ fontSize: 13 }}
                  >
                    {getValueByKey(data, header)}
                  </Typography>
                </div>
              ))}

              {cancel &&
              (data?.status === "Pending" || data?.status === "Approved") ? (
                <Grid item sm={12} md={12} lg={2}>
                  <center>
                    <Button
                      variant="contained"
                      className="btnCancelMyReser"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickCancelReser(data);
                      }}
                      disabled={isBtnDisabled}
                    >
                      Cancel
                    </Button>
                  </center>
                </Grid>
              ) : null}
            </div>
          </AccordionSummary>
        </Accordion>
      </Grid>
    </div>
  );
};

export default Reservation;
