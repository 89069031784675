// @vendors
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TablePagination, useMediaQuery } from "@mui/material";

// @constants
import {
  FILTER_LOCATION,
  ICON_REDUCER,
  LOCATION_DETAIL,
} from "../../redux/constants";
import {
  MESSAGEDELETE,
  MESSAGEERRORDELETE,
  MESSAGEERRORGENERAL,
} from "../../utils/constants-utils";
import {
  debounce,
  getRequestErrorMessage,
  handleOpenBackdrop,
} from "../../utils/functions-utils";

// @style
import "../../App.css";
import "./style-location.css";

// @components
import SearchLocation from "./search-location";
import CardLocation from "../../components/card-location/card-location";
import Empty from "../../components/empty/empty";
import Loading from "../../components/loading/loading";
import ConfirmationMessage from "../../utils/modal-confirm/confirm-message";

// @action
import { getLocation, inactiveLocation } from "../../actions/location-action";
import { getCitys } from "../../actions/city-country-action";

// @hooks
import { useModalMessage } from "../../hooks/use-modal-message";

const Locations = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const filterLocation = state?.filterLocationReducer;
  const locationType = state?.locationTypeReducer?.types;
  const [listLocation, setListLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [citys, setCitys] = useState([]);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const maxWidthScreen = useMediaQuery("(max-width:1500px)");
  const [dataFilter, setDataFilter] = useState({
    location: null,
    name: null,
    locationType: null,
  });

  const { message, handleClickClose, openMessage, openMessageWarning } =
    useModalMessage();

  const [paginatorRequest, setPaginatorRequest] = useState({
    page: 0,
    size: 6,
  });

  const [paginator, setPaginator] = useState({
    responseData: [],
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
  });

  const changeNumberrecords = (event) => {
    setPaginatorRequest((anterior) => ({
      ...anterior,
      size: parseInt(event.target.value),
      page: 0,
    }));
  };

  const changePage = (event, newPage) => {
    setPaginatorRequest((anterior) => ({
      ...anterior,
      page: parseInt(newPage),
    }));
  };

  useEffect(() => {
    dispatch({
      type: ICON_REDUCER,
      index: 1,
    });

    dispatch({
      type: LOCATION_DETAIL,
      data: {},
    });
  }, [dispatch]);

  const getAllLocation = async () => {
    setLoading(true);
    const filters = {
      Name: dataFilter.name === null ? null : dataFilter.name,
      city: dataFilter.location === null ? null : dataFilter.location,
      locationType:
        dataFilter.locationType === null ? null : dataFilter.locationType,
    };
    const response = await getLocation(paginatorRequest, filters);
    if (response?.status === 200 || response?.status === 201) {
      const data = response?.data?.locations;
      setPaginator({
        responseData: data?.resource,
        pageNumber: data?.pageNumber,
        pageSize: data?.pageSize,
        totalPages: data?.totalPages,
        totalRecords: data?.totalRecords,
      });
      setLoading(false);
    } else if (response?.status > 399 && response?.status !== 401) {
      setLoading(false);
      getRequestErrorMessage(setPaginator, openMessageWarning, "WARNING");
    } else {
      setLoading(false);
      getRequestErrorMessage(setPaginator, openMessageWarning, "ERROR");
    }
  };

  if (
    filterLocation &&
    filterLocation?.hasFilter &&
    dataFilter !== filterLocation.data
  ) {
    const filter = filterLocation.data;
    dataFilter.name = filter.name;
    dataFilter.location = filter?.location?.name;
    dataFilter.locationType = filter?.locationType;
    setPaginatorRequest.page = 0;
    dispatch({
      type: FILTER_LOCATION,
      hasFilter: false,
    });
    getAllLocation();
  }

  useEffect(() => {
    getAllLocation();
  }, [paginatorRequest]);

  const handleClickInactiveLocation = (id) => {
    const msj = "Are you sure to disabled this location?";
    openMessage(msj, handleClickInactive, id);
  };

  const messageRequestinactivate = (type) => {
    setIsBtnDisabled(false);
    const text = type === "WARNING" ? MESSAGEERRORDELETE : MESSAGEERRORGENERAL;
    openMessageWarning(text, "Try Again");
  };

  const handleClickInactive = async (locacion) => {
    handleOpenBackdrop(dispatch, true);
    setIsBtnDisabled(true);
    handleClickClose();
    const response = await inactiveLocation(locacion);
    if (response?.status === 200 || response?.status === 201) {
      openMessageWarning(MESSAGEDELETE, "Done", "SUCCESS", getAllLocation);
    } else if (response?.status > 399 && response?.status !== 401) {
      messageRequestinactivate("WARNING");
    } else {
      messageRequestinactivate("ERROR");
    }
    setIsBtnDisabled(false);
    handleOpenBackdrop(dispatch, false);
  };

  const getAllcitys = async (city) => {
    const filter = { name: city };
    const response = await getCitys(filter);
    if (response?.status === 200 || response?.status === 201) {
      setCitys(response?.data);
    } else {
      setCitys([]);
    }
  };

  const queryLocationValue = debounce(function (e) {
    if (e.target.value.length > 2) {
      getAllcitys(e.target.value);
    }
  }, 1000);

  return (
    <div className="rootLocation">
      <Grid container>
        <h3 className="titleMain">Locations</h3>

        <div className="containerSearch">
          <SearchLocation
            citys={citys}
            setListLocation={setListLocation}
            listLocation={listLocation}
            locationType={locationType}
            queryLocationValue={queryLocationValue}
          />
        </div>

        <Grid container className="containerCard">
          {paginator?.responseData?.length > 0 ? (
            paginator?.responseData?.map((item, index) => (
              <Grid item xs={12} md={6} lg={maxWidthScreen ? 4 : 3} key={index}>
                <CardLocation
                  key={index}
                  data={item}
                  handleClickInactiveLocation={() =>
                    handleClickInactiveLocation(item?.id)
                  }
                  isBtnDisabled={isBtnDisabled}
                  openMessage={openMessage}
                  openMessageWarning={openMessageWarning}
                  handleClickClose={handleClickClose}
                />
              </Grid>
            ))
          ) : loading ? (
            <div style={{ width: "100%" }}>
              <Loading />
            </div>
          ) : (
            <Empty />
          )}
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        {paginator?.responseData.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[6, 10, 20, 50]}
            count={paginator?.totalRecords}
            rowsPerPage={paginator?.pageSize}
            page={paginator?.pageNumber}
            labelRowsPerPage="Rows per page:"
            onPageChange={changePage}
            onRowsPerPageChange={changeNumberrecords}
          />
        ) : null}
      </Grid>

      <ConfirmationMessage
        description={message.description}
        textButtonSubmit={message.textButtonSubmit}
        handleClick={message.handleClick}
        handleClickOut={message.handleClickOut}
        oneButtons={message.oneButtons}
        type={message.type}
        open={message.open}
      />
    </div>
  );
};

export default Locations;
