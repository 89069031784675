// @vendors
import React from 'react';
import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';
import { Controller } from 'react-hook-form';

// @constants
import { CssTextField } from '../../../utils/constants-utils';

// @style
import './add-citys.css';
import '../../../App.css';

const FormCity = ({
    countries,
    onSubmit,
    register,
    control,
    handleSubmit,
    errors,
    cleanForm
}) => {

    const handleClickNavigate = () => {
        cleanForm();
    }

  return (
    <div className='contFormSecondCitys'>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>

            <Grid item xs={12} md={12} lg={12}>
                  <FormControl fullWidth>
                    <CssTextField
                      InputLabelProps={{shrink: true}}
                      {...register('name')}
                      control={control}
                      type="text"
                      name='name'
                      className='inputForm'
                      defaultValue=""
                      variant="outlined"
                      fullWidth
                      label="Name *"
                      size="small"
                      error={errors.hasOwnProperty("name") && errors["name"].message}
                    />
                    <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                      {errors.hasOwnProperty("name") && errors["name"].message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <FormControl 
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={ errors.hasOwnProperty("country") && errors["country"].message}
                    >
                        <InputLabel id="outlined-select-country" shrink={true} >Country *</InputLabel>
                        <Controller
                            fullWidth
                            control={control}
                            {...register('country')}
                            size='small'
                            name='country'
                            defaultValue=""
                            render={({field: {onChange, value}}) => (
                                <Select
                                    label="Country *"
                                    {...register('country')}
                                    notched={true}
                                    className='inputForm'
                                    value={value}
                                    onChange={onChange}
                                    inputProps={{
                                        name: "country",
                                        id: "outlined-select-country"
                                    }}
                                >
                                    {countries?.map((resp) => (
                                        <MenuItem 
                                            className='menuItemSelelct'
                                            key={resp?.id} 
                                            value={resp?.id}
                                            >
                                                {resp?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                        <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                            {errors.hasOwnProperty("country") && errors["country"].message}
                        </FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth>
                    <CssTextField
                      InputLabelProps={{shrink: true}}
                      {...register('code')}
                      control={control}
                      type="text"
                      name='code'
                      className='inputForm'
                      defaultValue=""
                      variant="outlined"
                      fullWidth
                      label="IATA Code *"
                      size="small"
                      error={errors.hasOwnProperty("code") && errors["code"].message}
                    />
                    <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                      {errors.hasOwnProperty("code") && errors["code"].message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} lg={12} className='contBtnModals'>

                    <Button
                        variant='contained'
                        onClick={handleClickNavigate}
                        className='btnSubmitModal'
                    >
                        clean up
                    </Button>

                    <Button
                        variant='contained'
                        type='submit'
                        className='btnSubmitModal'
                    >
                        save
                    </Button>

                </Grid>

            </Grid>
        </form>
    </div>
  )
}

export default FormCity