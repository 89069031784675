// @vendors
import React, { useState } from "react";
import { Avatar, IconButton, Toolbar } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import NotificationIcon from "@mui/icons-material/NotificationsOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import { useMsal } from "@azure/msal-react";

// @constants
import { BootstrapTooltip, StyledBadge } from "../../utils/constants-utils";
import { ICON_APPBAR } from "../../redux/constants";

// @style
import "./style-navigation.css";

// @img
import avatar from "../../assets/svg/profile_icon.svg";
import avatar2 from "../../assets/svg/profile_two_icon.svg";

// @components
import LeftProfile from "../modals/menu-logout/left-profile";
import ModalNotification from "../modals/notification/modal-notification";
import { SignOut } from "../../utils/functions-utils";
import DrawerApp from "./drawer";

const NavigationBar = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const stateIconAppbar = state?.iconAppbarReducer.icon;
  const notifications = state?.notificationReducer.notification;
  const [anchorEl, setAnchorEl] = useState(null);
  const openModalNotification = Boolean(anchorEl);
  const [anchorElLogout, setAnchorElLogout] = useState(null);
  const closeSesion = Boolean(anchorElLogout);
  const [open, setOpen] = useState(false);
  const { instance } = useMsal();

  const handleClickNotification = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch({
      type: ICON_APPBAR,
      icon: 1,
    });
  };

  const handleCloseModalNotification = () => {
    setAnchorEl(null);
    resetIconAppbar();
  };

  const handleClickOpenLogout = (event) => {
    setAnchorElLogout(event.currentTarget);
    dispatch({
      type: ICON_APPBAR,
      icon: 2,
    });
  };

  const handleClickCloseLogout = () => {
    setAnchorElLogout(null);
    resetIconAppbar();
  };

  const resetIconAppbar = () => {
    dispatch({
      type: ICON_APPBAR,
      icon: 0,
    });
  };

  const handleClickSignUp = () => {
    SignOut(dispatch);
    handleClickCloseLogout();
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <DrawerApp open={open} handleDrawerClose={handleDrawerClose} />

      <Toolbar className="toolbar">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ display: { sm: "none" } }}
          className="iconBtnMenu"
        >
          <MenuIcon className="iconAppbar" />
        </IconButton>

        <div className="grow"></div>

        <BootstrapTooltip title="Notifications" arrow className="tooltip">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={openModalNotification ? "long-menu" : undefined}
            aria-expanded={openModalNotification ? "true" : undefined}
            aria-haspopup="true"
            size="large"
            color="inherit"
            className="iconBtnNotification"
            onClick={handleClickNotification}
          >
            <StyledBadge
              badgeContent={notifications?.length}
              color="secondary"
              overlap="circular"
            >
              <NotificationIcon
                className={
                  stateIconAppbar === 1 ? "iconAppbarColor" : "iconAppbar"
                }
              />
            </StyledBadge>
          </IconButton>
        </BootstrapTooltip>

        <BootstrapTooltip title="Sign out" arrow className="tooltip">
          <IconButton
            aria-label="logout"
            id="longout-button"
            aria-controls={closeSesion ? "longout-menu" : undefined}
            aria-expanded={closeSesion ? "true" : undefined}
            aria-haspopup="true"
            size="large"
            edge="start"
            color="inherit"
            className="iconLogout"
            onClick={handleClickOpenLogout}
            aria-describedby={"id"}
          >
            <Avatar
              alt="Profile image"
              src={stateIconAppbar === 2 ? avatar2 : avatar}
              className="iconProfile"
            />
          </IconButton>
        </BootstrapTooltip>
      </Toolbar>

      <ModalNotification
        open={openModalNotification}
        handleClose={handleCloseModalNotification}
        anchorEl={anchorEl}
      />

      <LeftProfile
        open={closeSesion}
        handleClose={handleClickCloseLogout}
        logout={handleClickSignUp}
        anchorEl={anchorElLogout}
      />
    </div>
  );
};

export default NavigationBar;
