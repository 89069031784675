// @vendors
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

// @components
import ConfirmationMessage from '../../utils/modal-confirm/confirm-message';
import { ROUTES } from '../routes/constants';

// @constants
import {
  LOCATION_TYPE,
  USER_DATA,
  USER_SESION
} from '../../redux/constants';
import { MESSAGEPROBLEM, MESSAGETRYAGAIN } from '../../utils/constants-utils';
import {
  getNotificationByIdEmployee,
  jwtRol,
  saveTimeToken,
  SignOut,
  tokenExpirationTime
} from '../../utils/functions-utils';

// @action
import { createUser, getEmployeeById, getTokenByEmail } from '../../actions/employee-action';
import { getLocationTypes } from '../../actions/location-types-action';

import { loginRequest, loginRequestGraph } from '../../services/auth-config';
import { callMsGraph } from '../../services/graph';

const Login = () => {
  
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const [userGraph, setUserGraph] = useState({});
  const [isEmptyUser, setIsEmptyUser] = useState(false);
  
  const [message, setMessage] = useState({
    description: "",
    textButtonSubmit: "",
    handleClick: () => {}, 
    handleClickOut: () => {},
    oneButtons: false, 
    type: "",
    open: false,
  });

  const handleClickClose = () => {
    setMessage({ ...message, open: false});
  }

  useEffect(() => {
    if(isEmptyUser){
      registerUser();
    }
  }, [isEmptyUser, userGraph])
  
  const registerUser = () => {
    const names = userGraph?.names[0];
    const email = userGraph?.emails[0];
    const phone = userGraph?.phones[0];
    const newData = {
      firtsName: names?.first,
      secondName: '',
      surName: names?.last,
      secondSurName: '',
      documentNumber: null,
      email: email?.address,
      hiredDate: names?.createdDateTime,
      nationality: '',
      origin:'',
      phone: phone?.number ? phone?.number : '',
      birthDate: null,
      active: true
    }
    handleClickRegister(newData);
  }

  const handleClickRegister = async (user) => {
    const response = await createUser(user);
    if(response?.status === 200 || response?.status === 201){
      //navigator(ROUTES.LOCATION);
      navigator(ROUTES.TERMSANDCONDITIONS);
      getTokenUserByEmail(response?.data?.email);
      dispatch({
        type: USER_DATA,
        userData: response?.data,
      })
      const id = response?.data?.id;
      getNotificationByIdEmployee(dispatch, id)
    }else{
      SignOut(dispatch);
      instance.logoutRedirect({
        postLogoutRedirectUri:"/"
      })
    }
  }

  //get data about user sesion
  const getUserByEmail = async (email) => {
    if(email){
      const filter = {Email:email}
      const response = await getEmployeeById(filter);
      if(response?.status === 200 || response?.status === 201){
       
        if(response?.data?.length > 0){
          navigator(ROUTES.TERMSANDCONDITIONS);
          response?.data?.map((employee) => {
            dispatch({
              type: USER_DATA,
              userData: employee,
            });
            getTokenUserByEmail(employee?.email)
            getNotificationByIdEmployee(dispatch, employee?.id);
            return employee;
          });
        }else{
          setIsEmptyUser(true);
        }
      }else if(response?.status > 399){
        messageError(response?.data);
      }else{
        messageProblem();
      }
    }
  }

  const getAllLocationTypes = async () => {
    const response = await getLocationTypes();
    const resp = response?.data;
    dispatch({
      type: LOCATION_TYPE,
      types: resp
    });
  }

  const getTokenUserByEmail = async (email) => {
    const response = await getTokenByEmail(email);
    jwtRol(response?.data?.token, dispatch);
  }

  useEffect(() => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then((response) => {
      const timeExp = tokenExpirationTime(response?.accessToken);
      saveTimeToken(timeExp);
      window.localStorage.setItem("token_app", response?.accessToken);
      messageSuccessResponse(response);
    }).catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect(...loginRequest);
      }
    });
  }, [])

  useEffect(() => {
    instance.acquireTokenSilent({
      ...loginRequestGraph,
      account: accounts[0]
    }).then((response) => {
      callMsGraph(response.accessToken).then(response => {
        setUserGraph(response);
      });
    });
  }, [])

  const messageSuccessResponse = (info) => {
    getUserByEmail(info?.account?.username);
    dispatch({
      type: USER_SESION,
      data: info?.account,
      authenticated: true,
      token: info?.accessToken
    });
    getAllLocationTypes();
  }

  const messageError = (info) => {
    setMessage({
      description: info?.Details
        ? info?.Details
        : info?.Errors?.length > 0 
          ? info?.Errors?.map(x => x).toString() 
          : MESSAGETRYAGAIN,
      textButtonSubmit: "Try Again",
      handleClick: () => handleClickClose(), 
      oneButtons: true,
      type: "WARNING",
      open: true,
    });
  }

  const messageProblem = () => {
    setMessage({
      description: MESSAGEPROBLEM,
      textButtonSubmit: "Try again",
      handleClick: () => handleClickClose(), 
      oneButtons: true,
      type: "ERROR",
      open: true,
    });
  }

  return (
    <div>
      <ConfirmationMessage
        description={message.description}
        textButtonSubmit={message.textButtonSubmit}
        handleClick={message.handleClick}
        handleClickOut={message.handleClickOut}
        oneButtons={message.oneButtons}
        type={message.type}
        open={message.open}
      />
    </div>
  )
}

export default Login