// @vendors
import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// @pages
import Locations from "../../pages/locations/locations";
import AddLocations from "../../pages/add-locations/add-locations";
import Reservations from "../../pages/reservations/reservations";
import MyReservation from "../../pages/my-reservations/my-reservation";
import DetailLocation from "../../pages/detail-location/detail-location";
import Reserve from "../../pages/reserve/reserve";
import TermsAndConditions from "../../pages/terms-and-conditions/terms-and-conditions";
import GroupReservation from "../../pages/group-reservation/group-reservation";
import PageNotFound from "../page-not-found/page-not-found";
import TabsSettings from "../../pages/tabs-settings/tabs-settings";
import ChangePasswordToEmail from "../../pages/change-password-to-email/change-password-to-email";

// @constants
import { ROUTES } from "./constants";

// @components
import PrivateRoutes from "./private-routes";
import Navbar from "../navigation/navbar";
import Login from "../app-security/login";
import CanceledReservations from "../../pages/cancel-reservations/canceled-reservations";

const RoutesApp = () => {
  const state = useSelector((state) => state);
  const userRole = state?.listRolesReducer.roles;
  const rolSuperAdmin = userRole === "SUPERADMIN" ? true : false;
  const rolRH = userRole === "HR" ? true : false;
  const rolManager = userRole === "MANAGER" ? true : false;

  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path={ROUTES.PAGENOTFOUND} element={<PageNotFound />} />
          <Route exact path={ROUTES.MAIN} element={<Login />} />

          <Route
            path={ROUTES.LOCATION}
            element={
              <PrivateRoutes>
                <Locations />
              </PrivateRoutes>
            }
          />
          <Route
            path={ROUTES.LOCATIONDETAIL}
            element={
              <PrivateRoutes>
                <DetailLocation />
              </PrivateRoutes>
            }
          />
          <Route
            path={ROUTES.RESERVE}
            element={
              <PrivateRoutes>
                <Reserve />
              </PrivateRoutes>
            }
          />

          <Route
            path={
              rolSuperAdmin || rolRH ? ROUTES.ADDLOCATION : ROUTES.PAGENOTFOUND
            }
            element={
              <PrivateRoutes>
                <AddLocations />
              </PrivateRoutes>
            }
          />

          <Route
            path={
              rolSuperAdmin || rolRH ? ROUTES.EDITLOCATION : ROUTES.PAGENOTFOUND
            }
            element={
              <PrivateRoutes>
                <AddLocations />
              </PrivateRoutes>
            }
          />

          <Route
            path={ROUTES.MYRESERVATION}
            element={
              <PrivateRoutes>
                <MyReservation />
              </PrivateRoutes>
            }
          />

          <Route
            path={
              rolSuperAdmin || rolRH ? ROUTES.RESERVATIONS : ROUTES.PAGENOTFOUND
            }
            element={
              <PrivateRoutes>
                <Reservations />
              </PrivateRoutes>
            }
          />

          <Route
            path={
              rolSuperAdmin || rolRH ? ROUTES.TABSSETTINGS : ROUTES.PAGENOTFOUND
            }
            element={
              <PrivateRoutes>
                <TabsSettings />
              </PrivateRoutes>
            }
          />

          <Route
            path={
              rolManager || rolSuperAdmin || rolRH
                ? ROUTES.GROUPRESERVATION
                : ROUTES.PAGENOTFOUND
            }
            element={
              <PrivateRoutes>
                <GroupReservation />
              </PrivateRoutes>
            }
          />

          <Route
            path={ROUTES.TERMSANDCONDITIONS}
            element={
              <PrivateRoutes>
                <TermsAndConditions />
              </PrivateRoutes>
            }
          />
          <Route
            path={
              rolSuperAdmin ? ROUTES.CHANGEPASSWORDTOEMAIL : ROUTES.PAGENOTFOUND
            }
            element={
              <PrivateRoutes>
                <ChangePasswordToEmail />
              </PrivateRoutes>
            }
          />
          <Route
            path={
              rolSuperAdmin ? ROUTES.CANCELEDRESERVATION : ROUTES.PAGENOTFOUND
            }
            element={
              <PrivateRoutes>
                <CanceledReservations />
              </PrivateRoutes>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default RoutesApp;
