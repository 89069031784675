// @vendors
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { Divider, Grid } from "@mui/material";
import { useDispatch } from "react-redux";

// @constants
import {
  AccordionSummary,
  MESSAGECHANGESTATUS,
} from "../../utils/constants-utils";
import { headers } from "./headers";
import {
  getValueByKeyForReservations,
  handleOpenBackdrop,
} from "../../utils/functions-utils";

// @style
import "./style-reservations.css";
import "../../App.css";

// @action
import { updateReservation } from "../../actions/reservations-action";

// @hooks
import { useMessageRequest } from "../../hooks/use-message-request";

export default function ReservationDetails({
  data,
  getAllReservation,
  setBtnIsDisabled,
  btnIsDisabled,
  handleClickClose,
  openMessageWarning,
  openMessage,
}) {
  const dispatch = useDispatch();
  const { messageByRequest } = useMessageRequest();

  const handleClickApproveOrReject = (info, type) => {
    const newData = {
      locationId: info?.location.id,
      employeeId: info?.employee.id,
      arrival: info?.arrival,
      departure: info?.departure,
      guests: info?.guests,
      numberBedrooms: info?.numberBedrooms,
      numberDoubleRooms: info?.numberDoubleRooms,
      status:
        type === "approved"
          ? "Approved"
          : type === "cancel"
          ? "Canceled"
          : "Rejected",
      description: info?.description,
      origin: info?.origin,
      active: true,
      reservationGuest: [],
    };
    messageQuestion(info, type, newData);
  };

  const returnMessageFortypeAction = (type) => {
    if (type === "approved") return MESSAGECHANGESTATUS("approve");
    if (type === "cancel") return MESSAGECHANGESTATUS("cancel");
    if (type === "reject") return MESSAGECHANGESTATUS("reject");
  };

  const messageQuestion = (info, type, newData) => {
    const newObj = {
      id: info?.id,
      data: newData,
    };
    const msj = returnMessageFortypeAction(type);
    openMessage(msj, handleClickStatus, newObj);
  };

  const handleClickStatus = async (info) => {
    handleOpenBackdrop(dispatch, true);
    setBtnIsDisabled(true);
    handleClickClose();
    const response = await updateReservation(info?.id, info?.data);
    messageByRequest(response, openMessageWarning, "put", getAllReservation);
    setBtnIsDisabled(false);
    handleOpenBackdrop(dispatch, false);
  };

  const columDetailGuest = (title, item, index) => {
    return (
      <div className="columnGuest" key={index}>
        <Typography style={{ fontSize: 14 }}>{title ?? ""}</Typography>
        <Typography className="headingGuest" style={{ fontSize: 13 }}>
          {item ?? ""}
        </Typography>
      </div>
    );
  };

  return (
    <div style={{ margin: "0 0 10px 0", width: "100%" }} key={data?.id}>
      <Accordion style={{ borderRadius: "10px" }}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={
            <ArrowForwardIosSharpIcon
              sx={{
                fontSize: "1.4rem",
                marginLeft: 0,
                color: "#5f788d",
              }}
            />
          }
        >
          <div className="containerDetailTitle" key={data?.id}>
            {headers.initialHeaders.map((header, index) => (
              <div className="column" key={index}>
                <Typography style={{ fontSize: 14 }}>{header.title}</Typography>
                <Typography
                  className="secondaryHeading"
                  style={{ fontSize: 13 }}
                >
                  {getValueByKeyForReservations(data, header)}
                </Typography>
              </div>
            ))}
          </div>
        </AccordionSummary>
        <AccordionDetails className="detailTable">
          <Grid container className="containerDetail">
            {headers.otherHeaders.map((header, index) => (
              <div className="columnReservation" key={index}>
                <Typography style={{ fontSize: 14 }}>{header.title}</Typography>
                <Typography
                  className="secondaryHeading"
                  style={{ fontSize: 13 }}
                >
                  {getValueByKeyForReservations(data, header)}
                </Typography>
              </div>
            ))}
          </Grid>

          <Grid container className="containGuestBook">
            {data?.reservationGuest?.length > 0 ? (
              data?.reservationGuest?.map((guest, index) => (
                <>
                  <Divider className="dividerGuest" />
                  <Grid container>
                    {columDetailGuest("Guest Name", guest?.name, index)}
                    {columDetailGuest("Guest ID", guest?.documentNumber, index)}
                    {columDetailGuest("Nationality", guest?.nationality, index)}
                    {columDetailGuest("Origin", guest?.origin, index)}
                    {columDetailGuest(
                      "BirthDate",
                      guest?.birthDate
                        ? moment(guest?.birthDate).format("DD-MM-YYYY")
                        : "",
                      index
                    )}
                  </Grid>
                </>
              ))
            ) : (
              <Grid container>
                <Typography className="gusetEqualZero">Guest</Typography>
                <Typography className="secondaryHeading">0</Typography>
              </Grid>
            )}
          </Grid>

          {data?.description && data?.description !== "Block for admin" ? (
            <Grid container className="containDescription">
              <Typography
                style={{ fontSize: 14, textAlign: "start", width: "100%" }}
              >
                Description
              </Typography>
              <Typography
                style={{ fontSize: 13, textAlign: "start", color: "#647d91" }}
              >
                {data?.description}
              </Typography>
            </Grid>
          ) : null}

          <Grid container className="divBtnTabReser">
            {data?.status === "Pending" ? (
              <Button
                variant="contained"
                onClick={() => handleClickApproveOrReject(data, "approved")}
                className="btnAccordionReser"
                disabled={btnIsDisabled}
              >
                Aprove
              </Button>
            ) : null}

            {data?.status === "Pending" ? (
              <Button
                variant="contained"
                onClick={() => handleClickApproveOrReject(data, "reject")}
                className="btnAccordionReser"
                disabled={btnIsDisabled}
              >
                Reject
              </Button>
            ) : null}

            {data?.status !== "Canceled" && data?.status !== "Rejected" ? (
              <Button
                variant="contained"
                onClick={() => handleClickApproveOrReject(data, "cancel")}
                className="btnAccordionReser"
                disabled={btnIsDisabled}
              >
                Cancel
              </Button>
            ) : null}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
