import axios from "axios";
import { ROUTE_ROOT } from "../actions/contants";

axios.defaults.baseURL = ROUTE_ROOT;
//axios.defaults.baseURL = "https://housingwebapidesarrollo.azurewebsites.net";
axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("token_app");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const request = {
  get: (url) => axios.get(url),
  post: (url, body) => axios.post(url, body),
  put: (url, body) => axios.put(url, body),
  patch: (url, body) => axios.patch(url, body),
  delete: (url, body) => axios.delete(url, body),
};

export default request;
