// @vendors
import React from 'react';
import { Checkbox, Grid } from '@mui/material';
import parse from 'html-react-parser';

// style
import './notification.css';

// constants
import { BootstrapTooltip } from '../../../utils/constants-utils';

const CardNotification = ({data, handleClickCheckNotification, checked}) => {

  return (
    <div className='contCardNotification'>
      <Grid container>
        <Grid item xs={10} md={10} lg={10}>
          <h5 className='titleCardNotification'>{data?.title}</h5>
        </Grid>

        <Grid item xs={2} md={2} lg={2}>
          <BootstrapTooltip title="mark as read" placement="top" arrow>
            <Checkbox 
              value={data?.id}
              checked={checked === data?.id ? data.id : false}
              onChange={(e) => handleClickCheckNotification(e, data)}
              sx={{color: '#5f788d !important'}}
              inputProps={{'aria-label':'primary checkbox'}}
              id={data?.id}
            />
          </BootstrapTooltip>
        </Grid>
      </Grid>
      <div className='divTextNotification'>
        <p className='textNotification'>{parse(data?.message.length > 100 ? `${data?.message.substring(0,100)}...` : data?.message)}</p>
      </div>
    </div>
  )
}

export default CardNotification