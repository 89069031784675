// @vendors
import React from 'react';
import {
  Divider,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import { useSelector } from 'react-redux';

// @style
import './style-signup.css';

const LeftProfile = ({open, handleClose, logout, anchorEl}) => {
  const state = useSelector((state) => state?.userReducer?.userData);

  return (
    <div>
      <Menu
        id="longout-menu"
        MenuListProps={{
          'aria-labelledby': 'longout-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minHeight: 120,
            width: '30ch',
            borderRadius: 10
          },
        }}
      >
        <Grid container className='contMainLogout'>
          <Grid item xs={12} md={12} lg={12} className='usernameLogout'>
              <Typography variant='h6' component="h6" style={{color: '#5f788d', fontSize: '14px'}} >
                  {`${state?.firtsName ?? 'user'} ${state?.surName ?? 'name'}`}
              </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Divider xs={{color: '#020 !important'}} />
          </Grid>
            
          <Grid item xs={12} md={12} lg={12} className='contBtnLogout'>
            <MenuItem onClick={logout} className='menuItem'> 
              <ListItemIcon >
                <Logout fontSize="small" />
              </ListItemIcon>
              Sign out
            </MenuItem>
          </Grid>
        </Grid>
      </Menu>
    </div>
  )
}

export default LeftProfile;