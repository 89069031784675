// @vendors
import React, { useEffect, useRef } from 'react';
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import JoditEditor from 'jodit-react';
import { useNavigate } from 'react-router-dom';

// @constants
import {
  CssTextField,
  ENTERMAX,
  ENTERMIN,
  ENTERONLYNUMBER,
  ONLYNUMBER,
  REQUIRED_FIELD,
  VALIDEMAIL,
} from '../../utils/constants-utils';
import { handleUserKeyPress } from '../../utils/functions-utils';

// @style
import './style-add-location.css';

// @components
import { ROUTES } from '../../components/routes/constants';
import AddImage from './file-img/add-image';
import AddGallery from './file-img/add-gallery';

const defaultValue = {
  location: '',
  nbedrooms: '',
  doubleRoom: '',
  email: '',
  phone: '',
  address: '',
}

const LocationForm = ({
  citys,
  setListLocation,
  setContentDescription,
  contentDescription,
  onSubmit,
  setMainPhoto,
  setListGallery,
  locationTypes,
  locationId,
  queryLocationValue,
  listLocation,
  getAllcitys,
  isBtnDisabled,
  isAdult,
  isFamily,
  setIsAdult,
  setIsFamily
}) => {
  const editor = useRef(null);
  const navigate = useNavigate();
  
  const schema = yup.object().shape({
    nbedrooms: yup.string()
      .nullable()
      .required(REQUIRED_FIELD)
      .matches(ONLYNUMBER, ENTERONLYNUMBER),
    name: yup.string()
      .required(REQUIRED_FIELD)
      .min(3, ENTERMIN(3))
      .max(200, ENTERMAX(200)),
    locationType: yup.string()
      .nullable()
      .required(REQUIRED_FIELD),
    doubleRoom: isFamily
      ? yup.string()
        .nullable()
        .required(REQUIRED_FIELD)
        .matches(ONLYNUMBER, ENTERONLYNUMBER)
      : null,
    email: yup.string()
      .nullable()
      .required(REQUIRED_FIELD)
      .email(VALIDEMAIL)
      .min(8, ENTERMIN(8))
      .max(90, ENTERMAX(90)),
    phone: yup.string()
      .nullable()
      .required(REQUIRED_FIELD)
      .min(7, ENTERMIN(7))
      .matches(ONLYNUMBER, ENTERONLYNUMBER), 
    address:yup.string()
      .nullable()
      .required(REQUIRED_FIELD)
      .min(5, ENTERMIN(5))
      .max(100, ENTERMAX(100))
  })

  const {register, handleSubmit, setValue, control, formState: { errors }} = useForm({
    resolver: yupResolver(schema),
    defaultValue,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const resetForm = () => {
    setValue('nbedrooms', '');
    setValue('name', '');
    setValue('locationType', '');
    setValue('doubleRoom', '');
    setValue('email', '');
    setValue('phone', '');
    setValue('address', '');
    navigate(ROUTES.LOCATION);
  }

  const returnTypeLocation = (type) => {
    if(type === 'Family'){
      setIsFamily(true);
      setIsAdult(false);
    }
    if(type === 'Adults'){
      setIsAdult(true);
      setIsFamily(false);
      setValue('doubleRoom', '');
    }
  }

  useEffect(() => {
    if(locationId){
      returnTypeLocation(locationId?.type);
      setValue('name', locationId.name);
      setValue('nbedrooms', locationId?.bedrooms);
      setValue('doubleRoom', locationId?.doubleRooms);
      setValue('email', locationId?.email);
      setValue('phone', locationId?.phone);
      setValue('address', locationId?.adress);
      setValue('locationType', locationId?.type);
      getAllcitys(locationId?.city, 'isFirts');
    }
  }, [locationId, locationTypes, setValue])

  const handleChangeLocationType = (e) => {
    const {value} = e.target;
    returnTypeLocation(value);
  }
  
  return ( 
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12} className='divbtnsAddLocation'>
            <Button
              variant="contained"
              type='submit'
              className='btSaveAddLocation'
              disabled={isBtnDisabled}
            >
              Save
            </Button>
            <Button
              variant="contained"
              onClick={resetForm}
              className='btCancelAddLocation'
            >
              Cancel
            </Button>
          </Grid>

          <Grid container spacing={2} style={{marginTop: 10, marginRight: 0}}>
            <Grid item xs={12} md={9} lg={9} style={{marginTop: 10}}>
              <Grid container spacing={2}>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth>
                    <CssTextField
                      InputLabelProps={{shrink: true}}
                      {...register('name')}
                      control={control}
                      type="text"
                      name='name'
                      className='inputForm'
                      defaultValue=""
                      variant="outlined"
                      fullWidth
                      label="Location Name *"
                      size="small"
                      error={errors.hasOwnProperty("name") && errors["name"].message}
                    />
                    <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                      {errors.hasOwnProperty("name") && errors["name"].message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl  fullWidth >
                    <Autocomplete
                      freeSolo
                      value={listLocation}
                      name="location"
                      options={citys}
                      className='autoComplete'
                      onChange={(e, newVal) => {
                        setListLocation(newVal);
                      }}
                      getOptionLabel={(x) => x?.name ?? ''}
                      renderInput={(params) =>( 
                        <CssTextField 
                          {...params} 
                          label="Location City *" 
                          variant='outlined'
                          {...register('location')}
                          defaultValue=""
                          InputProps={{...params.InputProps}}
                          size="small"
                          onChange={(e) => queryLocationValue(e)}
                          placeholder='Enter a name'
                          className='autoComplete'
                          InputLabelProps={{shrink: true}}
                          error={ errors.hasOwnProperty("location") && errors["location"].message}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <FormControl 
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={ errors.hasOwnProperty("locationType") && errors["locationType"].message}
                    >
                        <InputLabel id="outlined-select-locationType" shrink={true} >Location Type *</InputLabel>
                        <Controller
                            fullWidth
                            control={control}
                            {...register('locationType')}
                            size='small'
                            name='locationType'
                            defaultValue=""
                            render={({field: {onChange, value}}) => (
                                <Select
                                    label="Location Type *"
                                    {...register('locationType')}
                                    notched={true}
                                    className='inputForm'
                                    value={value}
                                    //onChange={onChange}
                                    onChange={(e) => {
                                      onChange(e);
                                      handleChangeLocationType(e)
                                    }}
                                    inputProps={{
                                        name: "locationType",
                                        id: "outlined-select-locationType"
                                    }}
                                >
                                    {locationTypes?.map((resp) => (
                                        <MenuItem 
                                            className='menuItemSelelct'
                                            key={resp?.id} 
                                            value={resp.name}
                                            >
                                                {resp?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                        <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                            {errors.hasOwnProperty("locationType") && errors["locationType"].message}
                        </FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth>
                    <CssTextField
                      InputLabelProps={{shrink: true}}
                      {...register('address')}
                      control={control}
                      type="text"
                      name='address'
                      className='inputForm'
                      defaultValue=""
                      variant="outlined"
                      fullWidth
                      label="Address *"
                      size="small"
                      error={errors.hasOwnProperty("address") && errors["address"].message}
                    />
                    <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                      {errors.hasOwnProperty("address") && errors["address"].message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {isAdult || isFamily
                  ? <Grid item xs={12} md={6} lg={6}>
                      <FormControl fullWidth>
                        <CssTextField
                          InputLabelProps={{shrink: true}}
                          {...register('nbedrooms')}
                          control={control}
                          type="number"
                          name='nbedrooms'
                          className='inputForm'
                          defaultValue=""
                          variant="outlined"
                          fullWidth
                          label="Double Accommodation *"
                          size="small"
                          onKeyPress={handleUserKeyPress}
                          error={errors.hasOwnProperty("nbedrooms") && errors["nbedrooms"].message}
                        />
                        <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                          {errors.hasOwnProperty("nbedrooms") && errors["nbedrooms"].message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  : null
                }

                {isFamily
                  ? <Grid item xs={12} md={6} lg={6}>
                      <FormControl fullWidth>
                        <CssTextField
                          InputLabelProps={{shrink: true}}
                          {...register('doubleRoom')}
                          control={control}
                          type="number"
                          name='doubleRoom'
                          className='inputForm'
                          defaultValue=""
                          variant="outlined"
                          fullWidth
                          label="Multiple Accommodation *"
                          size="small"
                          onKeyPress={handleUserKeyPress}
                          error={errors.hasOwnProperty("doubleRoom") && errors["doubleRoom"].message}
                        />
                        <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                          {errors.hasOwnProperty("doubleRoom") && errors["doubleRoom"].message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  : null
                }

                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant='body1' style={{fontSize: '14px'}}>
                    Description *
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <JoditEditor
                    ref={editor}
                    //onChange={(content) => setContentDescription(content)}
                    tabIndex={1}
                    value={contentDescription}
                    onBlur={newContent => setContentDescription(newContent)}  
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6} style={{marginTop: '15px'}}>
                  <FormControl fullWidth>
                    <CssTextField
                      InputLabelProps={{shrink: true}}
                      {...register('email')}
                      control={control}
                      type="text"
                      name='email'
                      className='inputForm'
                      defaultValue=""
                      variant="outlined"
                      fullWidth
                      label="Email *"
                      size="small"
                      error={errors.hasOwnProperty("email") && errors["email"].message}
                    />
                    <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                      {errors.hasOwnProperty("email") && errors["email"].message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6} style={{marginTop: '15px'}}>
                  <FormControl fullWidth>
                    <CssTextField
                      InputLabelProps={{shrink: true}}
                      {...register('phone')}
                      control={control}
                      type="number"
                      name='phone'
                      className='inputForm'
                      defaultValue=""
                      variant="outlined"
                      fullWidth
                      label="Phone *"
                      size="small"
                      onKeyPress={handleUserKeyPress}
                      error={errors.hasOwnProperty("phone") && errors["phone"].message}
                    />
                    <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                      {errors.hasOwnProperty("phone") && errors["phone"].message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12} md={3} lg={3}>

              <AddImage setMainPhoto={setMainPhoto}/>
              <AddGallery setListGallery={setListGallery} />
  
            </Grid>

          </Grid>
            
          </Grid>
       
      </form>
    </div> 
)}
  
export default LocationForm;