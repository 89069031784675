// @vendors
import React from 'react';
import { Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/ScreenSearchDesktopOutlined';

// @style
import './style-empty.css';

const Empty = () => {
    return (
        <div className='containerEmpty' style={{height: '100%'}}>
            <div className='containerSecondaryEmpty'>
                <div className='contBodyEmpty'>
                    <SearchIcon className='iconEmpty'/>
                </div>
                <div className='contTextEmpty'>
                    <Typography variant='h4' className='textIsEmpty'>
                        No data found
                    </Typography>
                </div>
            </div>
        </div>
    )
}

export default Empty;