import moment from "moment";
import { decodeToken } from "react-jwt";
import { getFileByLocation } from "../actions/add-file-action";
import { getNotification } from "../actions/notification-action";
import {
  BACKDROP_LOADING,
  GRAPH_DATA,
  ICON_REDUCER,
  LIST_ROLES,
  LOCATION_DETAIL,
  LOCATION_TYPE,
  NOTIFICATION_DATA,
  SESION_LOGOUT,
  USER_DATA,
} from "../redux/constants";
import { MESSAGEERRORGENERAL, MESSAGETRYAGAIN } from "./constants-utils";

export const handleUserKeyPress = (e) => {
  let invalidChars = ["-", "e", "+", "E", ".", ","];
  if (invalidChars.includes(e.key)) {
    e.preventDefault();
  }
};

export const dateFormat = (date) => {
  let year = date?.getFullYear();
  let month = (1 + date?.getMonth()).toString().padStart(2, "0");
  let day = date?.getDate().toString().padStart(2, "0");

  return `${month}/${day}/${year}`;
};

// obtener locacion(city) por coincidencia de nombre
export const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

// mensaje de respuesta a peticiones get con paginacion y filtro
export const getRequestErrorMessage = (
  setPaginator,
  openMessageWarning,
  type
) => {
  setPaginator({
    responseData: [],
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
  });
  const msj = type === "WARNING" ? MESSAGETRYAGAIN : MESSAGEERRORGENERAL;
  openMessageWarning(msj, "Try again");
};

//get number days between dates
export const durationDays = (arrival, departure) => {
  const from = new Date(arrival);
  const to = new Date(departure);
  const numberDays = moment.duration(moment(to).diff(moment(from)));
  const diferent = Math.ceil(numberDays.asDays());
  return diferent;
};

//get notifications by employee
export const getNotificationByIdEmployee = async (dispatch, userId) => {
  const paginador = { page: 0, size: 100 };
  const response = await getNotification(userId, paginador);
  const data = response?.data?.notifications.resource;
  dispatch({
    type: NOTIFICATION_DATA,
    notification: data,
  });
};

// clear dispatch
export const SignOut = (dispatch) => {
  window.localStorage.removeItem("token_app");
  window.localStorage.removeItem("persist:persist-key");
  window.localStorage.removeItem("date_time");
  dispatch({
    type: SESION_LOGOUT,
    data: {},
    authenticated: false,
    token: "",
  });
  dispatch({
    type: LOCATION_DETAIL,
    data: {},
  });
  dispatch({
    type: LOCATION_TYPE,
    types: [],
  });
  dispatch({
    type: USER_DATA,
    userData: {},
  });
  dispatch({
    type: NOTIFICATION_DATA,
    notification: [],
  });
  dispatch({
    type: ICON_REDUCER,
    index: 0,
  });
  dispatch({
    type: LIST_ROLES,
    roles: "",
  });
  dispatch({
    type: GRAPH_DATA,
    data: {},
  });
};

export const converImageOfBase64 = (baseStr64) => {
  return "data:image/jpg;base64," + baseStr64;
};

export const convertB64ToBlob = async (base64, filename) => {
  let arr = base64.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], `${filename}.jpg`, { type: mime });
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      resolve(reader.result.split(",")[1]);
    };
  });
};

//validar rango de fechas
export const changeDate = (date, getValues, setMaxDate, newDate) => {
  let getData = getValues();
  if (!getData.arrival) {
    setMaxDate(date);
  } else {
    setMaxDate(dateFormat(newDate));
  }
};

export const MaxInitDate = (getValues, setMaxDate) => {
  const getData = getValues();
  if (getData.departure) {
    setMaxDate(dateFormat(getData.departure));
  }
};

export const handleClickCallRedirect = (setMessage, instance) => {
  setMessage({
    description: "Your session has expired, please log in again.",
    textButtonSubmit: "Accept",
    handleClick: () => handleClickRedirect(instance),
    type: "WARNING",
    oneButtons: true,
    open: true,
  });
};

const handleClickRedirect = (instance) => {
  instance.logoutRedirect({
    postLogoutRedirectUri: "/",
  });
};

// assign role
export const jwtRol = (token, dispatch) => {
  const jwt = decodeToken(token);
  const jwtToken =
    jwt["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
  if (jwtToken && jwtToken !== undefined) {
    const listRole = typeof jwtToken === "string" ? [jwtToken] : jwtToken;
    const role = listRole.find((item) => item === "SUPERADMIN");
    const roleManager = listRole.find((item) => item === "MANAGER");
    const roleHR = listRole.find((x) => x === "HR");
    if (role) {
      returnDispatch(role, dispatch);
    } else if (roleHR) {
      returnDispatch(roleHR, dispatch);
    } else {
      if (roleManager) {
        returnDispatch(roleManager, dispatch);
      } else {
        returnDispatch("", dispatch);
      }
    }
  } else {
    returnDispatch("", dispatch);
  }
};

export const returnDispatch = (rol, dispatch) => {
  return dispatch({
    type: LIST_ROLES,
    roles: rol,
  });
};

// refresh token

export const acquireAccessToken = async (msalInstance) => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();
  if (!activeAccount && accounts.length === 0) {
  }
  const request = {
    scopes: ["api://81959db0-40f2-47b3-a898-e0d2ae3932ea/api.scope"],
    account: activeAccount || accounts[0],
  };
  const authResult = await msalInstance.acquireTokenSilent(request);
  return authResult.accessToken;
};

//token expiration time
export const tokenExpirationTime = (accesstoken) => {
  const jwt = decodeToken(accesstoken);
  const jwtToken = jwt["exp"];
  const tokExp = jwtToken + "000";
  return tokExp;
};

export const saveTimeToken = (tokExp) => {
  window.localStorage.setItem("date_time", JSON.parse(tokExp) + 360000);
};

// only if the location is santa marta
const dowloadFileSantaMarta = (fileURL, fileName) => {
  let link = document.createElement("a");
  link.href = fileURL;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// get files to download about the location
export const verifyFileToDowload = async (id) => {
  const response = await getFileByLocation(id);
  if (response?.status === 200 || response?.status === 201) {
    if (response?.data.length) {
      const fileOne = response?.data[0];
      const fileTwo = response?.data[1];
      dowloadFileSantaMarta(fileOne.filePath, fileOne.name);
      setTimeout(() => {
        dowloadFileSantaMarta(fileTwo.filePath, fileTwo.name);
      }, 1000);
    }
  }
};

//verify if the dates is older to current date
export const areOlderDatesToCurrentDate = (start, end, openMessageWarning) => {
  const currentDate = moment().toDate();
  const arrival = moment(start).toDate();
  const departure = moment(end).toDate();
  if (currentDate > arrival || currentDate > departure) {
    const text =
      "Arrival and departure dates must be greater than the current date.";
    openMessageWarning(text, "Try Again");
    return true;
  }
  if (departure < arrival) {
    const text = "The departure date cannot be less than the arrival date.";
    openMessageWarning(text, "Try Again");
    return true;
  }
};

export const handleOpenBackdrop = (dispatch, value) => {
  dispatch({
    type: BACKDROP_LOADING,
    isOpenBackdrop: value,
  });
};

const validateNameEmployee = (name) => {
  const nameEmployee =
    name !== "" && name !== "string" && name !== "null" && name !== null
      ? name
      : "";
  return nameEmployee;
};

export const getValueByKeyForReservations = (object, header) => {
  if (header.child === null) {
    if (header.field === "names") {
      const secondName = validateNameEmployee(object?.employee?.secondName);
      return `${object?.employee.firtsName} ${secondName}`;
    }
    if (header.field === "lastnames") {
      const secondSurname = validateNameEmployee(
        object?.employee?.secondSurName
      );
      return `${object?.employee.surName} ${secondSurname}`;
    }
    if (header.field === "location") {
      return object?.location.name;
    }
    if (header.field === "requestday") {
      return moment(object?.createdAt).format("DD-MM-YYYY");
    }
    if (header.field === "from") {
      return moment(object?.arrival).format("DD-MM-YYYY");
    }
    if (header.field === "to") {
      return moment(object?.departure).format("DD-MM-YYYY");
    }
    if (header.field === "birthDate") {
      const birthDate = object?.employee?.birthDate
        ? moment(object?.employee?.birthDate).format("DD-MM-YYYY")
        : "";
      return birthDate;
    }
    if (header.field === "days") {
      return durationDays(object?.arrival, object?.departure);
    }
    if (header.field === "city") {
      return object?.location.city;
    }
    if (header.field === "guests") {
      return object?.guests + 1;
    }
    if (header.field === "phone") {
      return object?.employee?.phone ?? "";
    }
    if (header.field === "nationality") {
      return object?.employee?.nationality ?? "";
    }
    if (header.field === "idEmploy") {
      return object?.employee?.documentNumber ?? "";
    }
    if (header.field === "origin") {
      return object?.origin ?? "";
    }
    if (header.field === "isGroup") {
      const isGroup =
        object?.description === "Block for admin" && object?.isGroup
          ? "Blocked by administrator"
          : object?.isGroup
          ? "Yes"
          : "No";
      return isGroup;
    }
    if (header.field) {
      let result = "";
      if (header.field.includes(",")) {
        header.field.split(/\s*,\s*/).forEach(function (item) {
          result += `${object[item] ?? ""}`;
        });
        return result;
      }
      return object[header.field];
    }
    return "";
  }
  return object[header.field] !== null ? object[header.field] : "";
};
