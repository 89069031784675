// @vendors
import React, { useState } from 'react';
import {
    Autocomplete,
    Button,
    FormControl,
    FormHelperText,
    Grid
} from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
//import Locale  from 'date-fns/locale/es';
import Locale  from 'date-fns/locale/en-US';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

// @constants
import {
    CssTextField,
    ENTERMAX,
    ENTERMIN,
    MINDATE,
    REQUIRED_FIELD,
    VALIDFORMATDATE
} from '../../utils/constants-utils';
import { changeDate, MaxInitDate } from '../../utils/functions-utils';

// @style
import './group-reservation.css';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../components/routes/constants';

const defaultValue = {
    employee: '',
    location: '',
    arrival: '',
    departure: '',
    description: '',
}

const FormGroupReservation = ({
    listLocation,
    citys,
    setListLocation,
    onSubmit,
    employees,
    queryLocationValue,
    setListEmployees,
    listEmployees,
    queryEmployeeValue,
    isBtnDisabled
}) => {
    const newDate = new Date();
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(minDate);
    const navigator = useNavigate();

    const schema = yup.object().shape({
        employee: listEmployees.length > 0 ? null : yup.string()
          .required(REQUIRED_FIELD)
          .min(3, ENTERMIN(3))
          .max(200, ENTERMAX(200)),
        location: yup.string()
          .nullable()
          .required(REQUIRED_FIELD),
        arrival: yup.date()
          .nullable()
          .required(REQUIRED_FIELD)
          .min(new Date(1900, 11, 31, 23,59,29), MINDATE)
          .typeError(VALIDFORMATDATE),
        departure: yup.date()
          .nullable()
          .required(REQUIRED_FIELD)
          .min(new Date(1900, 11, 31, 23,59,29), MINDATE)
          .typeError(VALIDFORMATDATE),
        description: yup.string()
            .nullable()
            .required(REQUIRED_FIELD)
            .min(10, ENTERMIN(10))
            .max(2000, ENTERMAX(2000))
    })
    
    const {register, handleSubmit, setValue, getValues, control, formState: { errors }} = useForm({
    resolver: yupResolver(schema),
    defaultValue,
    mode: 'all',
    reValidateMode: 'onChange',
    });

    const onchangedate = (val) => {
        setMinDate(val);
    }

    const cancelReservation = () => {
        setValue('location', '');
        setValue('arrival', null);
        setValue('departure', null);
        setValue('bedroom', '');
        setValue('doublebed', '');
        setValue('description', '');
        setMinDate(null);
        setMaxDate(null);
        setListEmployees([]);
        navigator(ROUTES.LOCATION);
    }

  return (
    <div className='contFormGroupReservation'>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl  fullWidth >
                    <Autocomplete
                      multiple
                      value={listEmployees}
                      name="employee"
                      options={employees}
                      className='autoComplete'
                      onChange={(e, newVal) => {
                        setListEmployees(newVal);
                      }}
                      getOptionLabel={(x) => `${x?.firtsName ?? ''} ${x?.secondName && x?.secondName !== 'string' && x?.secondName !== 'null' ? x?.secondName : ''} ${x?.surName ?? ''} ${x?.secondSurName && x?.secondSurName !== 'string' && x?.secondSurName !== 'null' ? x?.secondSurName : ''}`}
                      renderInput={(params) =>( 
                        <CssTextField
                          {...params} 
                          label="Guest *" 
                          variant='outlined'
                          {...register('employee')}
                          defaultValue=""
                          size="small"
                          name='employee'
                          onChange={(e) => queryEmployeeValue(e)}
                          className='autoComplete'
                          placeholder="Enter a name"
                          InputLabelProps={{shrink: true}}
                          error={listEmployees?.length > 0 ? null : errors.hasOwnProperty("employee") && errors["employee"].message}
                        />
                      )}
                    />
                    <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                        {listEmployees?.length > 0 ? null : errors.hasOwnProperty("employee") && errors["employee"].message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl  fullWidth >
                    <Autocomplete
                      freeSolo
                      value={listLocation}
                      name="location"
                      options={citys}
                      className='autoComplete'
                      onChange={(e, newVal) => {
                        setListLocation(newVal);
                      }}
                      getOptionLabel={(x) => x?.name ?? ''}
                      renderInput={(params) =>( 
                        <CssTextField
                          {...params} 
                          label="Location name*" 
                          variant='outlined'
                          {...register('location')}
                          defaultValue=""
                          InputProps={{...params.InputProps}}
                          size="small"
                          name="location"
                          onChange={(e) => queryLocationValue(e)}
                          placeholder='Enter a name'
                          className='autoComplete'
                          InputLabelProps={{shrink: true}}
                          error={ errors.hasOwnProperty("location") && errors["location"].message}
                        />
                      )}
                    />
                    <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                        {errors.hasOwnProperty("location") && errors["location"].message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth>
                        <LocalizationProvider locale={Locale} dateAdapter={AdapterDateFns}>
                            <Controller
                                fullWidth
                                control={control}
                                name='arrival'
                                size="small"
                                defaultValue={null}
                                render={({ field: { onChange, value, }}) => (
                                    <DatePicker
                                        {...register("arrival")}
                                        variant="outlined"
                                        allowSameDateSelection={true}
                                        label="Check-in *"
                                        name='arrival'
                                        defaultValue={null}
                                        onAccept={onchangedate}
                                        onOpen={() => MaxInitDate(getValues, setMaxDate)}
                                        value={value}
                                        onChange={ onChange}
                                        minDate={new Date()}
                                        maxDate={maxDate}
                                        renderInput={(params) => 
                                            <CssTextField 
                                                {...params} 
                                                InputLabelProps={{shrink: true}}
                                                {...register("arrival")}
                                                name='arrival'
                                                size="small" 
                                                variant="outlined"
                                                defaultValue={null}
                                                className='inputForm'
                                                error={errors.hasOwnProperty("arrival") && errors["arrival"].message}
                                            />
                                        }

                                    />
                                )}
                            /> 
                            <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                                {errors.hasOwnProperty("arrival") && errors["arrival"].message}
                            </FormHelperText>
                        </LocalizationProvider>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth>
                        <LocalizationProvider locale={Locale} dateAdapter={AdapterDateFns}>
                            <Controller
                                fullWidth
                                control={control}
                                name='departure'
                                size="small"
                                defaultValue={null}
                                render={({ field: { onChange, value, }}) => (
                                    <DatePicker
                                        {...register("departure")}
                                        variant="outlined"
                                        label="Check-out *"
                                        name='departure'
                                        defaultValue={null}
                                        onAccept={(e) => changeDate(e, getValues, setMaxDate, newDate)}
                                        minDate={minDate ? minDate : new Date()}
                                        value={value}
                                        onChange={(newValue) => {
                                            onChange(newValue)
                                        }}
                                        renderInput={(params) => 
                                            <CssTextField 
                                                {...params} 
                                                InputLabelProps={{shrink: true}}
                                                {...register("departure")}
                                                name='departure'
                                                size="small" 
                                                value={value}
                                                defaultValue={null}
                                                className='inputForm'
                                                error={errors.hasOwnProperty("departure") && errors["departure"].message}
                                            />
                                        }

                                    />
                                )}
                            /> 
                            <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                                {errors.hasOwnProperty("departure") && errors["departure"].message}
                            </FormHelperText>
                        </LocalizationProvider>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth>
                        <CssTextField
                            InputLabelProps={{shrink: true}}
                            {...register('description')}
                            control={control}
                            type="text"
                            name='description'
                            className='inputForm'
                            defaultValue=""
                            variant="outlined"
                            fullWidth
                            multiline
                            minRows={3}
                            maxRows={4}
                            label="Description *"
                            size="small"
                            error={errors.hasOwnProperty("description") && errors["description"].message}
                        />
                        <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                            {errors.hasOwnProperty("description") && errors["description"].message}
                        </FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={8} lg={6}>
                    <Button
                        variant='contained'
                        type="submit"
                        className='btnGroupReserveForm'
                        disabled={isBtnDisabled}
                    >
                        Book
                    </Button>
                    <Button
                        variant='contained'
                        className='btnGroupCancelForm'
                        onClick={cancelReservation}
                    >
                        cancel
                    </Button>
                </Grid>

            </Grid>
        </form>
    </div>
  )
}

export default FormGroupReservation