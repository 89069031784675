import { NOTIFICATION_DATA } from "../constants";

const initialState = {
    notification: []
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION_DATA:
            
            return {
                ...state,
                notification: action.notification
            }
    
        default:
            return state;
    }
}

export default notificationReducer;