// @vendors
import React from "react";

// @style
import "./style-conditions.css";

const ViewGroup = () => {
  return (
    <div className="containAdults">
      <h3 className="subtitleMainH3">
        Project Collaboration/Team Buildings: Adults only property
      </h3>
      <div>
        <ul>
          <li>
            Team Managers can request to reserve the entire house for the
            project collaboration or Team Building Event.
          </li>
          <li>
            For the purpose of project collaboration or team building event the
            group must consist of at least 4 full time Team
            employees/contractors for a short trip of 3 nights.
          </li>
          <li>
            All reservation for these purposes will be evaluated and approved by
            HR. Team managers requesting the full reservation must submit a
            comprehensive plan for the event, and at the end of the stay must
            submit the report (templates will be provided). Request approvals
            are subject to availability of the property.{" "}
          </li>
          <li>
            Reservations done under this model will not be counted towards the
            employees/contractor’s personal Housing benefit.{" "}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ViewGroup;
