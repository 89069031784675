import { FILTER_RESERVATION } from "../constants";

const initialState = {
    data:{
        location: null,
        from: null,
        to: null,
        status: null,
        isGroup: null,
        email: null
    },
    hasFilter: false
}

const filterReservationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FILTER_RESERVATION:
            return {
                ...state,
                data: action.data,
                hasFilter: action.hasFilter
            }
    
        default:
            return state;
    }
}

export default filterReservationReducer;