// @vendors
import React, {useEffect, useState} from 'react';
import * as yup from 'yup';
import {Controller, useForm} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/HighlightOffOutlined';
import { useDispatch } from 'react-redux';
import {
    Autocomplete,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';

// @constants
import {defaultValue} from './headers';
import { CssTextField, ENTERMIN } from '../../utils/constants-utils';
import { FILTER_LOCATION } from '../../redux/constants';

// @style
import './style-location.css';
import '../../App.css';

const SearchLocation = ({
    citys,
    listLocation,
    setListLocation,
    locationType,
    queryLocationValue
}) => {
    const [stateName, setStateName] = useState(false);
    const dispatch = useDispatch();

    const schema = yup.object().shape({
        name: stateName ? null : yup.string()
            .nullable()
            .notRequired()
            .min(3, ENTERMIN(3)),
        locationType: yup.string().nullable().notRequired()
    });

    const {register, handleSubmit, control, setValue, watch, formState: { errors }} = useForm({
        resolver: yupResolver(schema),
        defaultValue,
        mode: 'all',
        reValidateMode: 'onChange',
    });

    const onSubmit = (data) => {
        const newData = {...data, location: listLocation}
        if(newData.name !== null || newData.locationType !== null){
            dispatch({
                type: FILTER_LOCATION,
                data: newData,
                hasFilter: true
            })
        }
    }

    const clearForm = () => {
        setValue('name', '');
        setValue('locationType', '');
        setListLocation([]);
    }

    const clearFilter = () => {
        dispatch({
            type: FILTER_LOCATION,
            data:{
                location: null,
                name: null,
                locationType: null
            },
            hasFilter: true
        });
        clearForm();
    }

    const seeName = watch('name');

    useEffect(() => {
      if(seeName === ''){
        setStateName(true);
      }else{
        setStateName(false)
      }
    }, [seeName])

  return (
    <div style={{width: '100%'}}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                
                <Grid item xs={12} md={4} lg={3} className="divSearchLocation">
                    <FormControl  fullWidth >
                        <Autocomplete
                            name="location"
                            options={citys}
                            className='autoComplete'
                            onChange={(e, newVal) => {
                                setListLocation(newVal);
                            }}
                            getOptionLabel={(x) => x?.name}
                            renderInput={(params) =>( 
                                <CssTextField 
                                    {...params} 
                                    label="Location" 
                                    variant='outlined'
                                    {...register('location')}
                                    defaultValue=""
                                    size="small"
                                    onChange={(e) => queryLocationValue(e)}
                                    placeholder='Enter a name'
                                    className='autoComplete'
                                    InputLabelProps={{shrink: true}}
                                    error={ errors.hasOwnProperty("location") && errors["location"].message}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={4} lg={3} className="divSearchLocation">
                  <FormControl fullWidth>
                    <CssTextField
                      InputLabelProps={{shrink: true}}
                      {...register('name')}
                      control={control}
                      type="text"
                      name='name'
                      className='inputForm'
                      defaultValue=""
                      variant="outlined"
                      fullWidth
                      label="Name"
                      size="small"
                      error={stateName ? null : errors.hasOwnProperty("name") && errors["name"].message}
                    />
                    <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                      {stateName ? null : errors.hasOwnProperty("name") && errors["name"].message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4} lg={3} className="divSearchLocation">
                    <FormControl 
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={ errors.hasOwnProperty("locationType") && errors["locationType"].message}
                    >
                        <InputLabel id="outlined-select-locationType" shrink={true} >Location Type</InputLabel>
                        <Controller
                            fullWidth
                            control={control}
                            {...register('locationType')}
                            size='small'
                            name='locationType'
                            defaultValue=""
                            render={({field: {onChange, value}}) => (
                                <Select
                                    label="locationType"
                                    {...register('locationType')}
                                    notched={true}
                                    className='inputForm'
                                    value={value}
                                    onChange={onChange}
                                    inputProps={{
                                        name: "locationType",
                                        id: "outlined-select-locationType"
                                    }}
                                >
                                    {locationType?.map((resp) => (
                                        <MenuItem
                                            className='menuItemSelelct'
                                            key={resp?.name} 
                                            value={resp?.name}
                                            >
                                                {resp?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                        <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                            {errors.hasOwnProperty("locationType") && errors["locationType"].message}
                        </FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={3} className="containerSearchLocation">
                    <Button
                        variant="contained"
                        type='submit'
                        className='btnSearchFilter'
                    >
                        <SearchIcon fontSize='small' className='iconButonFormFilter'/>
                        Search
                    </Button>
                    <Button
                        variant="contained"
                        onClick={clearFilter}
                        className='btnCleanFilter'
                    >
                        <ClearIcon fontSize='small' className='iconButonFormFilter'/>
                        Clean up
                    </Button>
                    
                </Grid>
            </Grid>
        </form>
    </div>
  )
}

export default SearchLocation