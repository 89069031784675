// @vendors
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';

// @style
import './style-availability.css';

// @components
import { AvailabilityCalendar } from './availabilityCalendar/AvailabilityCalendar';

const AvailabilityModal = ({
  open,
  seeAvailability,
  location
}) => {

  return (
    <div>
      <Dialog open={open} fullWidth maxWidth={'md'}>
        <DialogTitle className='dialogTitleAvailable'>
          <Grid container className='titleAssign'>
            <Typography variant='h6' style={{fontWeight: 'bold', textTransform: 'uppercase', fontSize: 15}}>
              availability
            </Typography>
          </Grid>
        </DialogTitle>

        <DialogContent className='dialogContentAssign' >
          { true
            ? 
              <AvailabilityCalendar data={location} />                    
            : <div>
                <p className='textNoReservation' style={{fontWeight: 'normal'}}>There are no reservations for this location</p>
              </div>
          }
        </DialogContent>

        <DialogActions style={{marginTop: 0, paddingBottom: 10}}>
          <Grid container className='gridButtom'>
            <Button
              variant="contained"
              onClick={seeAvailability}
              className='btnCloseAvailability'
            >
              <Typography variant='subtitle2'>
                Close
              </Typography>
            </Button>
          </Grid>
        </DialogActions>
        
        </Dialog>
    </div>
  )
}

export default AvailabilityModal