export const ROUTES = {
  MAIN: "/",
  LOGIN: "/auth/login",
  REGISTER: "/auth/register",
  CHANGE_PASS: "/change-password",
  PAGENOTFOUND: "*",
  LOCATION: "/dashboard/location",
  LOCATIONDETAIL: "/dashboard/location/:idLocation/:name",
  ADDLOCATION: "/dashboard/new-location",
  MYRESERVATION: "/dashboard/my-reservations",
  RESERVATIONS: "/dashboard/reservations",
  RESERVE: "/dashboard/reserve/:idLocation",
  RESERVEID: "/dashboard/reserve/",
  EDITLOCATION: "/dashboard/edit-location/:idLocation",
  EDITLOCATIONBYID: "/dashboard/edit-location/",
  TABSSETTINGS: "/dashboard/tab-settings",
  GROUPRESERVATION: "/dashboard/group-reservation",
  MODALLOGOUT: "/dashboard/logout",
  TERMSANDCONDITIONS: "/dashboard/terms-and-conditions",
  CHANGEPASSWORDTOEMAIL: "/dashborad/change-password-to-email",
  CANCELEDRESERVATION: "/dashborad/canceled-reservations",
};
