// @vendors
import React from 'react';

const LookVideo = () => {

  return (
    <div style={{textAlign: 'end !important', margin: '20px 0 0 0'}}>     
      <div style={{maxWidth: '940px', textAlign: 'end'}}>
        <div style={{position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden'}}>
          <iframe
            width="940"
            height="315"
            src="https://www.youtube.com/embed/UtGuansQJZI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            style={{border:'none', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, height: '100%', maxWidth: '100%'}}
          ></iframe>
        </div>
      </div>
    </div>
  )
}

export default LookVideo;