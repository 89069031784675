import httpClient from '../services/http-client';
import { EMPLOYEES, GETTOKEN, HEADERS, ROLE } from './contants';

export const getEmployeeById = (filters) => {
    let filterString = '?';
    if(filters.id)filterString=`${filterString}Id=${filters.id}&`;
    if(filters.name)filterString=`${filterString}Name=${filters.name}&`;
    if(filters.surname)filterString=`${filterString}Surname=${filters.surname}&`;
    if(filters.FullName)filterString=`${filterString}FullName=${filters.FullName}&`;
    if(filters.Email)filterString=`${filterString}Email=${filters.Email}&`;
    if(filterString === '?'){
        filterString = '';
    }else{
        filterString = filterString.substring(0, filterString.length -1);
    }
    return new Promise((resolve) => {
        httpClient.get(`${EMPLOYEES}${filterString}`, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        }) 
    })
} 

export const getTokenByEmail = (email) => {
    return new Promise((resolve) => {
        httpClient.post(`${GETTOKEN}?Email=${email}`, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}

export const createUser = (body) => {
    return new Promise((resolve) => {
        httpClient.post(`${EMPLOYEES}`, body, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}

export const assignRolle = (body) => {
    return new Promise((resolve) => {
        httpClient.post(`${ROLE}`, body, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}

export const updatedEmploye = (id, body) => {
    return new Promise((resolve) => {
        httpClient.put(`${EMPLOYEES}/${id}`, body, HEADERS).then((response) => {
            resolve(response);
        }).catch((e) => {
            resolve(e?.response);
        })
    })
}