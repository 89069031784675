// @vendors
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl, FormHelperText, Grid } from '@mui/material';

// @constants
import {
    CssTextField,
    ENTERMAX,
    ENTERMIN,
    REQUIRED_FIELD
} from '../../../utils/constants-utils';

// @styles
import '../../../App.css';

const defaultValue = {
    name: ''
}

const FormFeature = ({onSubmit, handleClickNewFeature}) => {

    const schema = yup.object().shape({
        name: yup.string()
            .nullable()
            .required(REQUIRED_FIELD)
            .min(3,ENTERMIN(3))
            .max(30,ENTERMAX(30))
    })

    const {register, handleSubmit, setValue, control, formState: { errors }} = useForm({
        resolver: yupResolver(schema),
        defaultValue,
        mode: 'all',
        reValidateMode: 'onChange',
    });

    const handleClickCancelForm = () => {
        setValue('name', '');
        handleClickNewFeature();
    }
    
  return (
    <div style={{marginTop: '10px'}}>
         <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>

            <Grid item xs={12} md={12} lg={12}>
                  <FormControl fullWidth>
                    <CssTextField
                      InputLabelProps={{shrink: true}}
                      {...register('name')}
                      control={control}
                      type="text"
                      name='name'
                      className='inputForm'
                      defaultValue=""
                      variant="outlined"
                      fullWidth
                      label="Name *"
                      size="small"
                      error={errors.hasOwnProperty("name") && errors["name"].message}
                    />
                    <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                      {errors.hasOwnProperty("name") && errors["name"].message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} lg={12} className='contBtnModals'>
                    <Button
                        variant='contained'
                        onClick={handleClickCancelForm}
                        className='btnSubmitModal'
                    >
                        cancel
                    </Button>

                    <Button
                        variant='contained'
                        type='submit'
                        className='btnSubmitModal'
                    >
                        save
                    </Button>

                </Grid>

            </Grid>
        </form>
    </div>
  )
}

export default FormFeature