// @vendors
import React from 'react';

// @style
import './loading.css';

const Loading = () => {
  return (
    <div className="lds-ripple"><div></div><div></div></div>
  )
}

export default Loading