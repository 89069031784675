// @vendors
import React from 'react';
import {
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@mui/material'
import { Controller } from 'react-hook-form';

// @constants
import { listRoles } from '../../../utils/data-json'
import { CssTextField } from '../../../utils/constants-utils';

// @style
import '../../../App.css';

const FormAssign = ({
    register,
    control,
    errors,
    onSubmit,
    handleSubmit,
    employees,
    setListEmployee,
    getEmployeeByName,
    handleClickCancel,
    handleClickAssign,
    assignRole
}) => {

  return (
    <div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>

                <div className='contCheckAssign'>
                    <div className='contAssign'>
                        <div className='contAssignCheck'>
                            <Checkbox
                                value='true'
                                checked={assignRole === 'true' ? true: false}
                                onChange={(e) => handleClickAssign(e)}
                                sx={{color: '#5f788d !important'}}
                                inputProps={{'aria-label':'primary checkbox'}}
                                id='true'
                            />
                        </div>
                        <div className='contAssignText'>
                            <Typography>Assign</Typography>
                        </div>
                    </div>
                    <div className='contAssign'>
                        <div className='contUnAssignCheck'>
                            <Checkbox
                                value='false'
                                checked={assignRole === 'false' ? true: false}
                                onChange={(e) => handleClickAssign(e)}
                                sx={{color: '#5f788d !important'}}
                                inputProps={{'aria-label':'primary checkbox'}}
                                id='flase'
                            />
                        </div>
                        <div className='contAssignText'>
                            <Typography>Remove</Typography>
                        </div>
                    </div>
                </div>

                <Grid item xs={12} md={6} lg={6}>
                    <FormControl  fullWidth >
                        <Autocomplete
                            id='detectedScroll'
                            name="employee"
                            options={employees}
                            className='autoComplete'
                            onChange={(e, newVal) => {
                                setListEmployee(newVal);
                            }}
                            getOptionLabel={(x) => `${x?.firtsName} ${x?.surName}`}
                            renderInput={(params) =>( 
                                <CssTextField 
                                    id='detectedScroll'
                                    {...params} 
                                    label="User Name *" 
                                    name="employee"
                                    variant='outlined'
                                    {...register('employee')}
                                    defaultValue=""
                                    placeholder='Enter a name'
                                    onChange={(e) => getEmployeeByName(e)}
                                    size="small"
                                    className='autoComplete'
                                    InputLabelProps={{shrink: true}}
                                    error={errors.hasOwnProperty("employee") && errors["employee"].message}
                                />
                            )}
                        />
                        <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                            { errors.hasOwnProperty("employee") && errors["employee"].message}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                
                <Grid item xs={12} md={6} lg={6}>
                    <FormControl 
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={ errors.hasOwnProperty("rol") && errors["rol"].message}
                    >
                        <InputLabel id="outlined-select-rol" shrink={true} >Role *</InputLabel>
                        <Controller
                            fullWidth
                            control={control}
                            {...register('rol')}
                            size='small'
                            name='rol'
                            defaultValue=""
                            render={({field: {onChange, value}}) => (
                                <Select
                                    label="Role *"
                                    {...register('rol')}
                                    notched={true}
                                    className='inputForm'
                                    value={value}
                                    onChange={onChange}
                                    inputProps={{
                                        name: "rol",
                                        id: "outlined-select-rol"
                                    }}
                                >
                                    {listRoles?.map((resp) => (
                                        <MenuItem 
                                            className='menuItemSelelct'
                                            key={resp?.id} 
                                            value={resp.id}
                                            >
                                                {resp?.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                        <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                            {errors.hasOwnProperty("rol") && errors["rol"].message}
                        </FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={12} lg={12} className='contBtnModals'>

                    <Button
                        variant='contained'
                        onClick={handleClickCancel}
                        className='btnSubmitModal'
                    >
                        clean up
                    </Button>

                    <Button
                        variant='contained'
                        type='submit'
                        className='btnSubmitModal'
                    >
                        save
                    </Button>

                </Grid>

            </Grid>
        </form>
    </div>
  )
}

export default FormAssign