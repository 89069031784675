// @vendors
import { useState } from "react";

// @constants
import { ENTERMAX, ENTERMIN, VALIDFORMATDATE } from "../utils/constants-utils"

export const useListGuest = () => {
    const [errorsGuests, setErrorsGuests] = useState([]);
    const [numberGuest, setNumberGuest] = useState([]);

    const onNumberGuests = (e) => {
        e.preventDefault();
        const {value} = e.target;
        if(value === 0){
            valueEqualZero();
            return;
        }
        if(value && value >= numberGuest.length){
            returnObjectsForGuest(value);
        }
        if(value < numberGuest.length){
            subtractValue(value);
        }
    }

    //Reset the guest list to empty
    const valueEqualZero = () => {
        setNumberGuest([]);
        setErrorsGuests([]);
    }

    const returnObjectsForGuest = (value) => {
        const objGuest = value - numberGuest.length;
        const guest = [...Array(objGuest)].map((e) => {
            return {
                name: '',
                documentNumber: '',
                nationality: '',
                origin: '',
                birthDate: null
            };
        });
        setNumberGuest([...numberGuest, ...guest]);
    }

    const subtractValue = (value) => {
        const guest = numberGuest.filter((x,i) => i < value);
        setNumberGuest(guest);
        for (let i = value; i <= numberGuest.length; i++) {
            delete errorsGuests[`guest[${i}]name`];
            delete errorsGuests[`guest[${i}]nameMessage`];
            delete errorsGuests[`guest[${i}]documentNumber`];
            delete errorsGuests[`guest[${i}]documentNumberMessage`];
            delete errorsGuests[`guest[${i}]nationality`];
            delete errorsGuests[`guest[${i}]nationalityMessage`];
            delete errorsGuests[`guest[${i}]origin`];
            delete errorsGuests[`guest[${i}]originMessage`];
            delete errorsGuests[`guest[${i}]birthDateGuests`];
            delete errorsGuests[`guest[${i}]birthDateGuestsMessage`];
        }
    }

    const validFieldTextGuest = (value) => {
        if(value.length < 3){
            return{error: true, message: ENTERMIN(3)}
        }else if(value.length > 80){
            return{error: true, message: ENTERMAX(80)}
        }else{
            return{error: false, message: ''}
        }
    }

    const validFieldNumberGuest = (value) => {
        if(value.length < 5){
            return{error: true, message: ENTERMIN(5)}
        }else if(value.length > 16){
            return{error: true, message: ENTERMAX(16)}
        }else{
            return{error: false, message: ''}
        }
    }

    const validFieldBirthDate = (value) => {
        let isValidDate = Date.parse(value);
        if(new Date(value) > new Date()){
            return{error: true, message: 'Enter a date less than the current date.'}
        }else if(new Date(value) < new Date(1900, 11, 31, 23,59,29)){
            return{error: true, message: 'Enter a higher date.'}
        }else if(isNaN(isValidDate)){
            return{error: true, message: VALIDFORMATDATE}
        }else{
            return{error: false, message: ''}
        }
    }
    
    const hasErrorsValue = (index, name, valid) => {
        setErrorsGuests((err) => ({
            ...err,
            [`guest[${index}]${name}`] : valid.error,
            [`guest[${index}]${name}Message`]: valid.message
        }))
    }

    const handleChangeGuest = (index, event, type) => {
        const values = [...numberGuest];
        const {value} = event?.target;
        values[index][event.target.name] = value;
        const valid = validFieldTextGuest(value);
        if(type === 'name'){
            hasErrorsValue(index, 'name',valid);
        }
        if(type === 'idGuest'){
            const validField = validFieldNumberGuest(value);
            hasErrorsValue(index, 'documentNumber', validField);
        }
        if(type === 'nationality'){
            hasErrorsValue(index, 'nationality', valid);
        }
        if(type === 'origin'){
            hasErrorsValue(index, 'origin', valid);
        }
        setNumberGuest(values);
    }

    const isValidToBirthDateGuest = (index, e) => {
        const values = [...numberGuest];
        const value = e?.target?.value ? e?.target?.value : new Date(e);
        values[index]['birthDate'] = value;
        const valid = validFieldBirthDate(value);
        hasErrorsValue(index, 'birthDateGuests', valid);
        setNumberGuest(values);
    }

    return {
        handleChangeGuest,
        onNumberGuests,
        errorsGuests,
        numberGuest,
        isValidToBirthDateGuest
    }
}