// @vendors
import React, {useEffect, useState} from 'react';
import { Button, IconButton, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MoreIcon from '@mui/icons-material/MoreVertOutlined';

// @style
import './style-card.css';

// @img
import noPhoto from '../../assets/svg/no_image.svg';

// @constants
import { ROUTES } from '../routes/constants';
import {BootstrapTooltip} from '../../utils/constants-utils';
import { useSelector } from 'react-redux';

// @action
import { getGalleryByLocation } from '../../actions/photo-action';
import LockLocation from '../modals/lock-location/lock-location-modal';
import MenuMoreOptions from './menu-more-options';

const CardLocation = ({
    data,
    handleClickInactiveLocation,
    isBtnDisabled,
    openMessage,
    openMessageWarning,
    handleClickClose
}) => {
    const navigate = useNavigate();
    const state = useSelector((state) => state);
    const userRole = state?.listRolesReducer.roles;
    const [mainImg, setMainImg] = useState('');
    const maxWidthScreen = useMediaQuery("(max-width:3000px)");
    const minWidthScreen = useMediaQuery("(min-width:1700px)");
    const maxWidthScreen1 = useMediaQuery("(max-width:1699px)");
    const minWidthScreen1 = useMediaQuery("(min-width:1500px)");
    const maxWidthScreen2 = useMediaQuery("(max-width:1499px)");
    const minWidthScreen2 = useMediaQuery("(min-width:1300px)");
    const maxWidthScreen3 = useMediaQuery("(max-width:1299px)");
    const minWidthScreen3 = useMediaQuery("(min-width:900px)");
    const maxWidthScreen4 = useMediaQuery("(max-width:899px)");
    const minWidthScreen4 = useMediaQuery("(min-width:715px)");
    const maxWidthScreen5 = useMediaQuery("(max-width:714px)");
    const [openModalLock, setOpenModalLock] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMoreOption = Boolean(anchorEl);

    const handleClickMoreOption = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickReserve = (info) => {
        navigate(`${ROUTES.RESERVEID}${info?.id}`);
    }

    const handleClickMoreDetails = (info) => {
        navigate(`${ROUTES.LOCATION}/${info?.id}/${info.name}`);
    }

    const handleClickEditLocation = (idLocation) => {
        navigate(`${ROUTES.EDITLOCATIONBYID}${idLocation}`);
    }

    const getImages = async () => {
        if(data?.id){
            const response = await getGalleryByLocation(data?.id);
            const isMain = response?.data.find(x => x.isMain === true);
            setMainImg(isMain?.photo);
        }
    }

    useEffect(() => {
      getImages();
    }, [data]);

    const returnLengthName = (data) => {
        if(data.name.length > 0){
            if(maxWidthScreen && minWidthScreen){
                if(data.name.length > 30){
                   return `${data?.name.substring(0, 30)}...`;
                }else return data.name
            }
            if(maxWidthScreen1 && minWidthScreen1){
                if(data.name.length > 25){
                    return `${data?.name.substring(0, 25)}...`;
                }else return data.name
            }
            if(maxWidthScreen2 && minWidthScreen2){
                if(data.name.length > 25){
                    return `${data?.name.substring(0, 25)}...`;
                }else return data.name
            }
            if(maxWidthScreen3 && minWidthScreen3){
                if(data.name.length > 21){
                    return `${data?.name.substring(0, 21)}...`;
                }else return data.name
            }
            if(maxWidthScreen4 && minWidthScreen4){
                if(data.name.length > 35){
                    return `${data?.name.substring(0, 35)}...`;
                }else return data.name
            }
            if(maxWidthScreen5){
                if(data.name.length > 25){
                    return `${data?.name.substring(0, 25)}...`;
                }else return data.name
            }
        }else{
            return '';
        }
    }

    const handleCloceInactive = () => {
        handleClickInactiveLocation();
        handleClose();
    }

    const handleClickLockLocation = () => {
        handleClose();
        setOpenModalLock(true);
    }
   
  return (
    <div className='root'>
        <div className='contImg'>
            <img
                src={mainImg ? mainImg : noPhoto}
                alt='image_Location'
                className='img'
            />
        </div>
        <div className='contName'>
            <h5 className='nameLocation'>
                {returnLengthName(data)}
            </h5>

            <div className='containerIconLocation'>
                <BootstrapTooltip title='More options' placement='top' arrow>
                    <IconButton
                        size='small'
                        className='iconBtnCardLocation'
                        id="demo-positioned-button"
                        aria-controls={openMoreOption ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMoreOption ? 'true' : undefined}
                        onClick={handleClickMoreOption}
                    >
                        <MoreIcon fontSize='small' className='iconCardLocation'/>
                    </IconButton>
                </BootstrapTooltip>
            </div>

        </div>

        <div className='contBtn'>
            <Button
                variant="contained"
                onClick={() => handleClickReserve(data)}
                className='btnCardReserve'
            >
                Book
            </Button>
            <Button
                variant="contained"
                onClick={() => handleClickMoreDetails(data)}
                className='btnCardReserve'
            >
                more details
            </Button>
        </div>

        <MenuMoreOptions
            anchorEl={anchorEl}
            openMoreOption={openMoreOption}
            handleClose={handleClose}
            userRole={userRole}
            handleClickEditLocation={handleClickEditLocation}
            data={data}
            handleCloceInactive={handleCloceInactive}
            isBtnDisabled={isBtnDisabled}
            handleClickLockLocation={handleClickLockLocation}
        />

        <LockLocation
            open={openModalLock}
            setOpenModalLock={setOpenModalLock}
            data={data}
            openMessage={openMessage}
            openMessageWarning={openMessageWarning}
            handleClickClose={handleClickClose}
        />
    </div>
  )
}

export default CardLocation;