// @vendors
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, useMediaQuery } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

// @constants
import { ICON_REDUCER } from '../../redux/constants';

// @style
import './tabs.css';

// @components
import AddCitys from './add-citys/add-citys';
import AddCountry from './add-country/add-country';
import ConfirmationMessage from '../../utils/modal-confirm/confirm-message';
import AssignRole from './assign-role/assign-role';

// @hooks
import { useModalMessage } from '../../hooks/use-modal-message';
import { useMessageRequest } from '../../hooks/use-message-request';

const TabsSettings = () => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const userRole = state?.listRolesReducer.roles;
    const [value, setValue] = useState(0);
    const matches = useMediaQuery('(min-width:750px)');
    const {message, handleClickClose, openMessage, openMessageWarning} = useModalMessage();
    const {messageByRequest} = useMessageRequest();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
      dispatch({
        type: ICON_REDUCER,
        index: 6
      })
    }, [dispatch])
    
  return (
    <div className='rootSettings'>
        <div className='contEmptySettings'></div>

        <div className='containerSettings'>
            <div className='divTabs'>
                <TabContext value={value} className='tabContext'>
                    <div className='contTabList'>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="scrollable force tabs example"
                            sx={{ maxWidth: { xs: 300, sm: matches ? 610 : 340 }, minWidth: {xs: 200, sm: 200 }}}
                        >
                            <Tab label="Add City" className='itemTab' />
                            <Tab label="Add Country"  className='itemTab'/>
                            {userRole === 'SUPERADMIN' ? <Tab label="Assign Role" className='itemTab'/> : null}
                        </Tabs>
                    </div>

                    <TabPanel value={0}>
                        <AddCitys
                            openMessage={openMessage}
                            handleClickClose={handleClickClose}
                            messageByRequest={messageByRequest}
                            openMessageWarning={openMessageWarning}
                        />
                    </TabPanel>

                    <TabPanel value={1}>
                        <AddCountry
                            openMessage={openMessage}
                            handleClickClose={handleClickClose}
                            messageByRequest={messageByRequest}
                            openMessageWarning={openMessageWarning}
                        />
                    </TabPanel>

                    {userRole === 'SUPERADMIN'
                        ?   <TabPanel value={2}>
                                <AssignRole
                                    openMessage={openMessage}
                                    handleClickClose={handleClickClose}
                                    messageByRequest={messageByRequest}
                                    openMessageWarning={openMessageWarning}
                                />
                            </TabPanel>
                        :   null
                    }

                </TabContext>
            </div>
        </div>

        <ConfirmationMessage
            description={message.description}
            textButtonSubmit={message.textButtonSubmit}
            handleClick={message.handleClick}
            handleClickOut={message.handleClickOut}
            oneButtons={message.oneButtons}
            type={message.type}
            open={message.open}
        />
    </div>
  )
}

export default TabsSettings