import { combineReducers } from "redux";
import userReducer from "./user-reducer";
import userSesionReducer from "./user-sesion-reducer";
import iconReducer from "./icon-reducer";
import filterReservationReducer from "./filter-reservation-reducer";
import filterLocationReducer from "./filter-location-reducer";
import locationDetailReducer from "./location-detail-reducer";
import locationTypeReducer from "./location-type-reducer";
import iconAppbarReducer from "./icon-appbar-reducer";
import notificationReducer from "./notification-reducer";
import listRolesReducer from "./list-roles-reducer";
import graphDataReducer from "./graph-data-reducer";
import msalReducer from "./msal-reducer";
import backdropLoading from "./backdrop-reducer";

const RootReducer = combineReducers({
  userReducer,
  userSesionReducer,
  iconReducer,
  filterReservationReducer,
  filterLocationReducer,
  locationDetailReducer,
  locationTypeReducer,
  iconAppbarReducer,
  notificationReducer,
  listRolesReducer,
  graphDataReducer,
  msalReducer,
  backdropLoading,
});

export default RootReducer;
