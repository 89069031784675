//@vendors
import React from 'react';
import { IconButton, Menu } from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import BlockingIcon from '@mui/icons-material/RemoveCircleOutline';

// @constants
import { BootstrapTooltip } from '../../utils/constants-utils';

// @style
import './style-card.css';

const MenuMoreOptions = ({
    anchorEl,
    openMoreOption,
    handleClose,
    userRole,
    handleClickEditLocation,
    data,
    handleCloceInactive,
    isBtnDisabled,
    handleClickLockLocation
}) => {
  return (
    <>
        <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={openMoreOption}
            onClose={handleClose}
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
            }}
        >
            {userRole === 'SUPERADMIN' || userRole === 'HR'
                ?   <div className='containerIconLocation'>
                        <BootstrapTooltip title='Edit' placement='top' arrow>
                            <IconButton
                                size='small'
                                className='iconBtnCardLocation'
                                onClick={() => handleClickEditLocation(data?.id)}
                            >
                                <EditIcon fontSize='small' className='iconCardLocation'/>
                            </IconButton>
                        </BootstrapTooltip>

                        <BootstrapTooltip title='Inactive' placement='top' arrow>
                            <IconButton
                                size='small'
                                className='iconBtnCardLocation'
                                onClick={handleCloceInactive}
                                disabled={isBtnDisabled}
                            >
                                <DeleteIcon fontSize='small' className='iconCardLocation'/>
                            </IconButton>
                        </BootstrapTooltip>

                        {userRole === 'SUPERADMIN'
                            ?   <BootstrapTooltip title='Lock' placement='top' arrow>
                                    <IconButton
                                        size='small'
                                        className='iconBtnCardLocation'
                                        onClick={handleClickLockLocation}
                                        disabled={isBtnDisabled}
                                    >
                                        <BlockingIcon fontSize='small' className='iconCardLocation'/>
                                    </IconButton>
                                </BootstrapTooltip>
                            :   null

                        }
                    </div>
                :   null
            }
        </Menu>
    </>
  )
}

export default MenuMoreOptions