// vendors
import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from '@mui/material';

// components
import FormFeature from './form-feature'

// style
import './features.css';

// @actions
import { saveFeatures } from '../../../actions/feature-action';

const FeatureModal = ({
    open,
    handleClickNewFeature,
    getAllFeatures,
    handleClickClose,
    openMessage,
    messageByRequest,
    openMessageWarning
}) => {

    const onSubmit = (data) => {
        const newData ={
            name: data.name
        }
        const msj = 'Are you sure to add this feature?';
        openMessage(msj, handleClickSendData, newData);
    }

    const handleClickSendData = async (info) => {
        handleClickClose();
        const response = await saveFeatures(info);
        messageByRequest(response, openMessageWarning, 'post', handleClickSuccess);
    }

    const handleClickSuccess = () => {
        getAllFeatures();
        handleClickNewFeature();
    }

  return (
    <div>
        <Dialog open={open}>
        <DialogTitle className='dialogTitleFeatures'>
          <Grid container className='titleFeatures'>
            <Typography variant='h6' style={{fontWeight: 'bold', textTransform: 'uppercase', fontSize: 15}}>
              Add new amenity
            </Typography>
          </Grid>
        </DialogTitle>

        <DialogContent className='dialogContentFeatures' >
            <FormFeature
                onSubmit={onSubmit}
                handleClickNewFeature={handleClickNewFeature}
            />
        </DialogContent>
        </Dialog>
    </div>
  )
}

export default FeatureModal;