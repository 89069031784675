// @vendors
import React from 'react';

// @style
import './style-conditions.css';

const ViewFamily = () => {
  return (
    <div className='containAdults'>
        <h3 className='subtitleMainH3'>Kids Friendly Option</h3>
        <div>
        <ul>
            <li>In Kids Friendly option you can reserve rooms and enjoy family time.</li>
            <li>Pets allowed according to the property rules.</li>
            <li>Each reservation will include two private rooms: bedroom with double accommodation for a couple and bedroom for multiple accommodation (number of beds will vary) as well as bathroom for family use.</li>
            <li>Employee/contractor can book up to 2 rooms in the house according to the tenure in Team.</li>
            <li>The property is not exclusive for any visitors during the trip, other employees/contractors can reserve other rooms during the same dates.</li>
        </ul>
        
        <p className='textShort'>To book Type 2 Team Housing see conditions below:</p>

        <div className='containTimes'>
            <h4 className='subtitleMain'>Employee/Contractor with +6 months up to a year of tenure in Team:</h4>
            <p className='textGeneral'>
            Employees/contractors that have been working in Team for at least 6 months and up to a year can reserve Family Package of 2 rooms for a short trip per year. Should be booked following below guidelines: 
            </p>

            <ul>
                <li>1 double accommodation room + private bathroom + 1 kids’ room with multiple beds (number will vary). </li>
                <li>Reservation is for 3 nights Monday to Thursday or Thursday to Sunday.</li>
                <li>Employee/contractor can bring guests according to the maximum capacity of the 2 Family rooms for the trip providing full name and ID number of the guests.</li>
            </ul>
        </div>

        <div className='containTimes'>
            <h4 className='subtitleMain'>Employee/Contractor with +1 years up to 3 years of tenure in Team:</h4>
            <p className='textGeneral'>
            Employees/contractors that have been working in Team for at least 1 year and up to 3 years can reserve Family Package of 2 rooms for 2 short trips per year or 1 long trip per year. Should be booked following below guidelines:
            </p>

            <p className='textShort'>Short trip</p>

            <ul>
            <li>1 double accommodation room + private bathroom + 1 kids’ room with multiple beds (number will vary).</li>
            <li>Reservation is for 3 nights Monday to Thursday or Thursday to Sunday.</li>
            <li>Employee/contractor can bring guests according to the maximum capacity of the 2 Family rooms for the trip providing full name and ID number of the guests.</li>
            </ul>

            <p className='textShort'>Long trip</p>

            <ul>
                <li>1 double accommodation room + private bathroom + 1 kids’ room with multiple beds (number will vary).</li>
                <li>Reservation is for 6 nights Monday to Sunday.</li>
                <li>Employee/contractor can bring guests according to the maximum capacity of the 2 Family rooms for the trip providing full name and ID number of the guests.</li>
            </ul>
        </div>

        <div className='containTimes'>
            <h4 className='subtitleMain'>Employee/Contractor with +3 up to 5 years of tenure in Team</h4>
            <p className='textGeneral'>
            Employees/contractors that have been working in Team for at least 3 years and up to 5 years can reserve Family Packages of 3 Family rooms for 2 short trips per year or 1 long trip per year. Should be booked following below guidelines: 
            </p>

            <p className='textShort'>Short trip</p>

            <ul>
                <li>2 double accommodation rooms + private bathroom + 1 kids’ room with multiple beds (number will vary).</li>
                <li>Reservation is for 3 nights Monday to Thursday or Thursday to Sunday.</li>
                <li>Employee/contractor can bring guests according to the maximum capacity of the 3 Family rooms for the trip providing full name and ID number of the guests.</li>
            </ul>

            <p className='textShort'>Long trip</p>

            <ul>
            <li>2 double accommodation rooms + private bathroom + 1 kids’ room with multiple beds (number will vary).</li>
            <li>Reservation is for 6 nights Monday to Sunday.</li>
            <li>Employee/contractor can bring guests according to the maximum capacity of the 3 Family rooms for the trip providing full name and ID number of the guests.</li>
            </ul>
        </div>

        <div className='containTimes'>
            <h4 className='subtitleMain'>Employee/Contractor with +5 years of tenure in Team</h4>
            <p className='textGeneral'>
            Employees/contractors that have been working in Team for at least 5 years can reserve Family Package of 3 Family rooms for 3 short trips per calendar year or 1 long trip per year + 1 short trip per year for a total of 9 nights per year. Should be booked following below guidelines: 
            </p>

            <p className='textShort'>Short trip</p>

            <ul>
                <li>2 double accommodation rooms + private bathroom + 1 kids’ room with multiple beds (number will vary).</li>
                <li>Reservation is for 3 nights Monday to Thursday or Thursday to Sunday.</li>
                <li>Employee/contractor can bring guests according to the capacity of the 3 Family rooms for the trip providing full name and ID number of the guests.</li>
            </ul>

            <p className='textShort'>Long trip</p>

            <ul>
                <li>2 double accommodation rooms + private bathroom + 1 kids’ room with multiple beds (number will vary).</li>
                <li>Reservation is for 6 nights Monday to Sunday.</li>
                <li>Employee/contractor can bring guests according to the capacity of the 3 Family rooms for the trip providing full name and ID number of the guests.</li>
            </ul>
        </div>

        </div>
    </div>
  )
}

export default ViewFamily