// @vendors
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Autocomplete,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/HighlightOffOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import Locale  from 'date-fns/locale/es';
import Locale  from 'date-fns/locale/en-US';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {useDispatch} from 'react-redux'

// @constants
import { CssTextField, ENTERMAX, ENTERMIN, MINDATE, VALIDEMAIL, VALIDFORMATDATE } from '../../utils/constants-utils';
import {dateFormat} from '../../utils/functions-utils';
import { dataIsGroup, reservationStatus } from '../../utils/data-json';
import { FILTER_RESERVATION } from '../../redux/constants';

// @style
import '../../App.css';

const defaultValue = {
    form: '',
    to: ''
}

const SearchReservation = ({
    listLocation,
    setListLocation,
    citys,
    queryLocationValue
}) => {
    const newDate = new Date();
    const dispatch = useDispatch()
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(dateFormat(minDate))

    const schema = yup.object().shape({
        from: yup.date()
            .nullable()
            .notRequired()
            .min(new Date(1900, 11, 31, 23,59,29), MINDATE)
            .typeError(VALIDFORMATDATE),
        to: yup.date()
            .nullable()
            .notRequired()
            .min(new Date(1900, 11, 31, 23,59,29), MINDATE)
            .typeError(VALIDFORMATDATE),
        status: yup.string().nullable().notRequired(),
        isGroup: yup.string().nullable().notRequired(),
        email: yup
            .string()
            .email(VALIDEMAIL)
            .nullable()
            .notRequired()
            .min(8, ENTERMIN(8))
            .max(50, ENTERMAX(50))
            .transform(v => v ? v : null),
    });

    const {register, handleSubmit, control, setValue, getValues, formState: { errors }} = useForm({
        resolver: yupResolver(schema),
        defaultValue,
        mode: 'all',
        reValidateMode: 'onChange',
    });

    const onSubmit = (info) => {
        const newData = {...info, location:listLocation}
        if(newData.from !== null ||
            newData.to !== null ||
            newData.status !== null ||
            newData.isGroup !== null ||
            newData.email !== null
        ){
            dispatch({
                type: FILTER_RESERVATION,
                data: newData,
                hasFilter: true
            });
        }
    }

    const clearFilter = () => {
        setValue('from', null);
        setValue('to', null);
        setValue('status', '');
        setValue('isGroup', '');
        setValue('email', '');
        setMinDate(null);
        setMaxDate(null);
        clearStateFilter();
        setListLocation([]);
    }

    const clearStateFilter = () => {
        dispatch({
            type: FILTER_RESERVATION,
            data:{
                location: null,
                from: null,
                to: null,
                status: null,
                isGroup: null,
                email: null
            },
            hasFilter: true
        })
    }

    const onChangeDate = (value) => {
        setMinDate(value);
    };

    const changeMaxDate = (date) => {
        let getData = getValues();
        if (!getData.from) {
          setMaxDate(date);
        } else {
          setMaxDate(dateFormat(newDate));
        }
    };
    
    const MaxInitDate = () => {
        const getData = getValues();
        if (getData.to) {
            setMaxDate(dateFormat(getData.to));
        }
    };

  return (
    <div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>

                <Grid item xs={12} md={3} lg={3}>
                    <FormControl  fullWidth >
                        <Autocomplete
                            name="location"
                            options={citys}
                            className='autoComplete'
                            onChange={(e, newVal) => {
                                setListLocation(newVal);
                            }}
                            getOptionLabel={(x) => x?.name}
                            renderInput={(params) =>( 
                                <CssTextField
                                    {...params} 
                                    label="Location" 
                                    variant='outlined'
                                    {...register('location')}
                                    defaultValue=""
                                    size="small"
                                    onChange={(e) => queryLocationValue(e)}
                                    placeholder='Enter a name'
                                    className='autoComplete'
                                    InputLabelProps={{shrink: true}}
                                    error={ errors.hasOwnProperty("location") && errors["location"].message}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={3} lg={3}>
                    <FormControl fullWidth>
                        <LocalizationProvider locale={Locale} dateAdapter={AdapterDateFns}>
                            <Controller
                                fullWidth
                                control={control}
                                name='from'
                                size="small"
                                defaultValue={null}
                                render={({ field: { onChange, value, }}) => (
                                    <DatePicker
                                        {...register("from")}
                                        variant="outlined"
                                        allowSameDateSelection={true}
                                        label="Check-in"
                                        name='from'
                                        onAccept={onChangeDate}
                                        onOpen={MaxInitDate}
                                        defaultValue={null}
                                        value={value}
                                        onChange={ onChange}
                                        maxDate={maxDate}
                                        minDate='01.01.1900'
                                        renderInput={(params) => 
                                            <CssTextField 
                                                {...params} 
                                                InputLabelProps={{shrink: true}}
                                                {...register("from")}
                                                name='from'
                                                size="small" 
                                                variant="outlined"
                                                defaultValue={null}
                                                className='inputForm'
                                                error={errors.hasOwnProperty("from") && errors["from"].message}
                                            />
                                        }

                                    />
                                )}
                            /> 
                            <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                                {errors.hasOwnProperty("from") && errors["from"].message}
                            </FormHelperText>
                        </LocalizationProvider>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={3} lg={3}>
                    <FormControl fullWidth>
                        <LocalizationProvider locale={Locale} dateAdapter={AdapterDateFns}>
                            <Controller
                                fullWidth
                                control={control}
                                name='to'
                                size="small"
                                defaultValue={null}
                                render={({ field: { onChange, value, }}) => (
                                    <DatePicker
                                        {...register("to")}
                                        variant="outlined"
                                        label="Check-out"
                                        name='to'
                                        defaultValue={null}
                                        onAccept={changeMaxDate}
                                        minDate={minDate ? minDate : new Date('1900-01-01')}
                                        value={value}
                                        onChange={(newValue) => {
                                            onChange(newValue)
                                        }}
                                        renderInput={(params) => 
                                            <CssTextField 
                                                {...params} 
                                                InputLabelProps={{shrink: true}}
                                                {...register("to")}
                                                name='to'
                                                size="small" 
                                                value={value}
                                                defaultValue={null}
                                                className='inputForm'
                                                error={errors.hasOwnProperty("to") && errors["to"].message}
                                            />
                                        }

                                    />
                                )}
                            /> 
                            <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                                {errors.hasOwnProperty("to") && errors["to"].message}
                            </FormHelperText>
                        </LocalizationProvider>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={3} lg={3}>
                  <FormControl fullWidth>
                    <CssTextField
                      InputLabelProps={{shrink: true}}
                      {...register('email')}
                      control={control}
                      type="text"
                      name='email'
                      className='inputForm'
                      defaultValue=""
                      variant="outlined"
                      fullWidth
                      label="Email"
                      size="small"
                      error={errors.hasOwnProperty("email") && errors["email"].message}
                    />
                    <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                      {errors.hasOwnProperty("email") && errors["email"].message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                    <FormControl 
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={ errors.hasOwnProperty("status") && errors["status"].message}
                    >
                        <InputLabel id="outlined-select-status" shrink={true} >Status</InputLabel>
                        <Controller
                            fullWidth
                            control={control}
                            {...register('status')}
                            size='small'
                            name='status'
                            defaultValue=""
                            render={({field: {onChange, value}}) => (
                                <Select
                                    label="status"
                                    {...register('status')}
                                    notched={true}
                                    className='inputForm'
                                    value={value}
                                    onChange={onChange}
                                    inputProps={{
                                        name: "status",
                                        id: "outlined-select-status"
                                    }}
                                >
                                    {reservationStatus?.map((resp) => (
                                        <MenuItem
                                            className='menuItemSelelct'
                                            key={resp?.name} 
                                            value={resp.name}
                                            >
                                                {resp?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                    <FormControl 
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={ errors.hasOwnProperty("isGroup") && errors["isGroup"].message}
                    >
                        <InputLabel id="outlined-select-isGroup" shrink={true} >Is Group </InputLabel>
                        <Controller
                            fullWidth
                            control={control}
                            {...register('isGroup')}
                            size='small'
                            name='isGroup'
                            defaultValue=""
                            render={({field: {onChange, value}}) => (
                                <Select
                                    label="Is Group "
                                    {...register('isGroup')}
                                    notched={true}
                                    className='inputForm'
                                    value={value}
                                    onChange={onChange}
                                    inputProps={{
                                        name: "isGroup",
                                        id: "outlined-select-isGroup"
                                    }}
                                >
                                    {dataIsGroup?.map((resp) => (
                                        <MenuItem
                                            className='menuItemSelelct'
                                            key={resp?.id} 
                                            value={resp.id}
                                            >
                                                {resp?.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={4} lg={4} >
                    <Button
                        variant="contained"
                        type='submit'
                        className='btnSearchFilter'
                    >
                        <SearchIcon fontSize='small' className='iconButonFormFilter'/>
                        Search
                    </Button>
                    <Button
                        variant="contained"
                        onClick={clearFilter}
                        className='btnCleanFilter'
                    >
                        <ClearIcon fontSize='small' className='iconButonFormFilter'/>
                        Clean up
                    </Button>
                    
                </Grid>
            </Grid>
        </form>
    </div>
  )
}

export default SearchReservation