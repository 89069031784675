import HttpClient from '../services/http-client';
import { HEADERS, LOCATION, LOCKLOCATION } from './contants';

export const getLocation = (paginator, filters) => {
    let filterString = '?';
    let symbolPaginator = '&';
    if(filters.Name)filterString=`${filterString}Name=${filters.Name}&`;
    if(filters.city)filterString=`${filterString}city=${filters.city}&`;
    if(filters.locationType)filterString=`${filterString}LocationType=${filters.locationType}&`;
    if(filterString === '?'){
        filterString = '';
        symbolPaginator = '?';
    }else{
        filterString = filterString.substring(0, filterString.length -1);
    }
    return new Promise((resolve) => {
        HttpClient.get(`${LOCATION}${filterString}${symbolPaginator}PageNumber=${paginator.page}&PageSize=${paginator.size}`, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response);
        })
    })
}

export const locationById = (idLocation) => {
    return new Promise((resolve) => {
        HttpClient.get(`${LOCATION}/${idLocation}`, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response);
        })
    })
}

export const sendLocation = (body) => {
    return new Promise((resolve) => {
        HttpClient.post(`${LOCATION}`, body, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response);
        })
    })
}

export const updateLocation = (idLocation, body) => {
    return new Promise((resolve) => {
        HttpClient.put(`${LOCATION}/${idLocation}`, body, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}

export const inactiveLocation = (idLocation) => {
    return new Promise((resolve) => {
        HttpClient.delete(`${LOCATION}/${idLocation}`, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}

// lock location

export const lockLocation = (body) => {
    return new Promise((resolve) => {
        HttpClient.post(`${LOCKLOCATION}`, body, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}