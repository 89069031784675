import HttpClient from '../services/http-client';
import { FILE, HEADERS } from './contants';

export const getFileByLocation = (id) => {
    return new Promise((resolve) => {
        HttpClient.get(`${FILE}/${id}`, HEADERS).then((response) => {
            resolve(response);
        }).catch((e) => {
            resolve(e?.response);
        })
    })
}