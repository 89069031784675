import {USER_DATA } from "../constants";
export const initialState = {
    userData: {}, 
}

const userReducer = (state = initialState, action) => {
    switch (action?.type) {
        case USER_DATA:
            return {
                ...state,
                userData: action?.userData
            };
            
        default:
            return state;
    }
}

export default userReducer;
