// @vendors
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// @style
import "./change-password.css";
import "../../App.css";

// @constants
import {
  CssOutlinedInput,
  CssTextField,
  ENTERMAX,
  ENTERMIN,
  REQUIRED_FIELD,
  VALIDEMAIL,
} from "../../utils/constants-utils";

// @action
import { updatedCredential } from "../../actions/change-pass.word-action";

// @hooks
import { useMessageRequest } from "../../hooks/use-message-request";

const FormChangePassword = ({
  credential,
  openMessage,
  openMessageWarning,
  handleClickClose,
  checkChangePassword,
  isLoading,
  setIsLoading,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { messageByRequest } = useMessageRequest();

  const schema = yup.object().shape({
    senderEmail: yup
      .string()
      .nullable()
      .required(REQUIRED_FIELD)
      .email(VALIDEMAIL)
      .min(8, ENTERMIN(8))
      .max(90, ENTERMAX(90)),
    password: yup
      .string()
      .nullable()
      .required(REQUIRED_FIELD)
      .min(5, ENTERMIN(5))
      .max(50, ENTERMAX(50)),
    receiverEmail: yup
      .string()
      .nullable()
      .required(REQUIRED_FIELD)
      .email(VALIDEMAIL)
      .min(8, ENTERMIN(8))
      .max(90, ENTERMAX(90)),
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValue: { senderEmail: null, password: null, receiverEmail: null },
    mode: "all",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (credential?.id) {
      setValue("senderEmail", credential?.senderEmail);
      setValue("password", credential?.senderPassword);
      setValue("receiverEmail", credential?.receiverEmail);
    }
  }, [credential, setValue]);

  const handleClickSubmit = (data) => {
    const newData = {
      senderEmail: data.senderEmail,
      senderPassword: data.password,
      receiverEmail: data.receiverEmail,
    };
    const msj = "The credential information will be changed, are you sure?";
    openMessage(msj, handleClickSend, newData);
  };

  const handleClickSend = async (info) => {
    setIsLoading(true);
    handleClickClose();
    const response = await updatedCredential(credential?.id, info);
    messageByRequest(response, openMessageWarning, "post");
    setIsLoading(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword((current) => !current);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="containerFormChangePassword">
      <div className="conatinerTitleChangePassword">
        <h3 className="titleMain">Change password to email</h3>
      </div>
      <div className="containerListKeepMind">
        <h5 className="titleList">Keep in mind</h5>
        <ul className="contlist">
          <li>
            <p>first the data must be updated in the mail</p>
          </li>
          <li>
            <p>
              The data entered when making the update through this form must
              correspond to those entered in the update from the mail, otherwise
              you will not be notified.
            </p>
          </li>
        </ul>
      </div>

      <form onSubmit={handleSubmit(handleClickSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <FormControl fullWidth>
              <CssTextField
                InputLabelProps={{ shrink: true }}
                {...register("senderEmail")}
                control={control}
                type="text"
                name="senderEmail"
                className="inputFormDisabled"
                defaultValue=""
                variant="outlined"
                disabled={true}
                fullWidth
                label="Sender Email *"
                size="small"
                error={
                  errors.hasOwnProperty("senderEmail") &&
                  errors["senderEmail"].message
                }
              />
              <FormHelperText style={{ color: "#f44336", paddingLeft: 0 }}>
                {errors.hasOwnProperty("senderEmail") &&
                  errors["senderEmail"].message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-password" shrink={true}>
                Password *
              </InputLabel>
              <CssOutlinedInput
                InputLabelProps={{ shrink: true }}
                {...register("password")}
                control={control}
                name="password"
                className="inputForm"
                type={showPassword ? "text" : "password"}
                defaultValue=""
                variant="outlined"
                fullWidth
                label="Password *"
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                error={
                  errors.hasOwnProperty("password") &&
                  errors["password"].message
                }
              />
              <FormHelperText style={{ color: "#f44336", paddingLeft: 0 }}>
                {errors.hasOwnProperty("password") &&
                  errors["password"].message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <FormControl fullWidth>
              <CssTextField
                InputLabelProps={{ shrink: true }}
                {...register("receiverEmail")}
                control={control}
                type="text"
                name="receiverEmail"
                className="inputForm"
                defaultValue=""
                variant="outlined"
                fullWidth
                label="Receiver Email *"
                size="small"
                error={
                  errors.hasOwnProperty("receiverEmail") &&
                  errors["receiverEmail"].message
                }
              />
              <FormHelperText style={{ color: "#f44336", paddingLeft: 0 }}>
                {errors.hasOwnProperty("receiverEmail") &&
                  errors["receiverEmail"].message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Button
              variant="contained"
              type="submit"
              className="btnChangePassword"
              disabled={isLoading}
            >
              Change
            </Button>
          </Grid>

          <Grid item xs={12} md={12} lg={12} style={{ marginTop: 20 }}>
            <h4 className="textAboutTest">
              The following button will allow you to test for the new
              credentials.
            </h4>
          </Grid>
          <Grid item xs={12} md={12} lg={12} style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              className="btnTestChangePassword"
              onClick={checkChangePassword}
              disabled={isLoading}
            >
              test new credential
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default FormChangePassword;
