// @vendors
import React from 'react';

// @style
import './style-conditions.css';

const ViewAdults = () => {
  return (
    <div className='containAdults'>
        <h3 className='subtitleMainH3'>Adults only</h3>
        <div>
        <ul>
            <li>In adults only type of property no kids are allowed (must be 18+).</li>
            <li>Pets might be allowed according to the property rules .</li>
            <li>Each reservation will include private bedroom bathroom.</li>
            <li>Employee/contractor can book up to 2 rooms in the house for limited period of time according to their tenure in Team.</li>
            <li>The property is not exclusive for any visitors during the trip, other employees/contractors can reserve other rooms during the same dates.</li>
        </ul>
        
        <p className='textShort'>To book Type 1 Team Housing see conditions below:</p>

        <div className='containTimes'>
            <h4 className='subtitleMain'>Employee/Contractor with +6 months up to a year of tenure in Team:</h4>
            <p className='textGeneral'>
            Employees/contractors that have been working in Team for at least 6 months and up to a year can reserve 1 room for one trip per calendar year. Should be booked following the guidelines below:  
            </p>

            <ul>
            <li>1 double accommodation room + private bathroom.</li>
            <li>Reservation is for 3 nights Monday to Thursday or Thursday to Sunday.</li>
            <li>Employee/contractor can bring a +1 for the trip providing full name and ID number of the guest.</li>
            </ul>
        </div>

        <div className='containTimes'>
            <h4 className='subtitleMain'>Employee/Contractor with +1 years up to 3 years of tenure in Team:</h4>
            <p className='textGeneral'>
            Employees/contractors that have been working in Team for at least 1 year and up to 3 years can reserve 1 room for 2 short trips per calendar year or 1 long trip per calendar year. Should be booked following below guidelines:
            </p>

            <p className='textShort'>Short trip</p>

            <ul>
            <li>1 double accommodation room + private bathroom</li>
            <li>Reservation is for 3 nights Monday to Thursday or Thursday to Sunday.</li>
            <li>Employee/contractor can bring a +1 for the trip providing full name and ID number of the guest.</li>
            </ul>

            <p className='textShort'>Long trip</p>

            <ul>
            <li>1 double accommodation room + private bathroom</li>
            <li>Reservation is for 6 nights Monday to Sunday.</li>
            <li>Employee/contractor can bring a +1 for the trip providing full name and ID number of the guest.</li>
            </ul>
        </div>

        <div className='containTimes'>
            <h4 className='subtitleMain'>Employee/Contractor with +3 up to 5 years of tenure in Team</h4>
            <p className='textGeneral'>
            Employees/contractors that have been working in Team for at least 3 years and up to 5 years can reserve 2 rooms for 2 short trips per year or 2 rooms for 1 long trip per year. Should be booked following below guidelines: 
            </p>

            <p className='textShort'>Short trip</p>

            <ul>
            <li>2 double accommodation rooms + private bathroom</li>
            <li>Reservation is for 3 nights Monday to Thursday or Thursday to Sunday.</li>
            <li>Employee/contractor can bring a +3 for the trip providing full name and ID number of the guest.</li>
            </ul>

            <p className='textShort'>Long trip</p>

            <ul>
            <li>2 double accommodation rooms + private bathroom</li>
            <li>Reservation is for 6 nights Monday to Sunday.</li>
            <li>Employee/contractor can bring a +3 for the trip providing full name and ID number of the guest.</li>
            </ul>
        </div>

        <div className='containTimes'>
            <h4 className='subtitleMain'>Employee/Contractor with +5 years of tenure in Team</h4>
            <p className='textGeneral'>
            Employees/contractors that have been working in Team for at least 5 years can reserve 2 rooms for 3 short trips per year (3 nights each) or 1 long trip per year (6 nights) + 1 short trip per year (3 nights) for a total of 9 nights per calendar year. Should be booked following below guidelines: 
            </p>

            <p className='textShort'>Short trip</p>

            <ul>
            <li>2 double accommodation rooms + private bathroom</li>
            <li>Reservation is for 3 nights Monday to Thursday or Thursday to Sunday.</li>
            <li>Employee/contractor can bring a +3 for the trip providing full name and ID number of the guest.</li>
            </ul>

            <p className='textShort'>Long trip</p>

            <ul>
            <li>2 double accommodation rooms + private bathroom</li>
            <li>Reservation is for 6 nights Monday to Sunday.</li>
            <li>Employee/contractor can bring a +3 for the trip providing full name and ID number of the guest.</li>
            </ul>
        </div>

        </div>
    </div>
  )
}

export default ViewAdults