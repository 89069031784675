import HttpClient from "../services/http-client";
import {
  CANCELEDRESERVATIONS,
  GROUPRESERVATION,
  HEADERS,
  MYRESERVATION,
  RESERVATIONHISTORY,
  RESERVATIONS,
} from "./contants";

export const getReservation = (paginator, filters) => {
  let filterString = "?";
  let symbolPaginator = "&";
  if (filters.EmployeeId)
    filterString = `${filterString}EmployeeId=${filters.EmployeeId}&`;
  if (filters.location)
    filterString = `${filterString}Location=${filters.location}&`;
  if (filters.from) filterString = `${filterString}Arrival=${filters.from}&`;
  if (filters.to) filterString = `${filterString}Departure=${filters.to}&`;
  if (filters.status) filterString = `${filterString}Status=${filters.status}&`;
  if (filters.IsGroup)
    filterString = `${filterString}IsGroup=${filters.IsGroup}&`;
  if (filters.Email) filterString = `${filterString}Email=${filters.Email}&`;
  if (filterString === "?") {
    filterString = "";
    symbolPaginator = "?";
  } else {
    filterString = filterString.substring(0, filterString.length - 1);
  }
  return new Promise((resolve) => {
    HttpClient.get(
      `${RESERVATIONS}${filterString}${symbolPaginator}PageNumber=${paginator.page}&PageSize=${paginator.size}`,
      HEADERS
    )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error?.response);
      });
  });
};

export const getCancelReservation = (paginator) => {
  return new Promise((resolve) => {
    HttpClient.get(
      `${CANCELEDRESERVATIONS}?PageNumber=${paginator.page}&PageSize=${paginator.size}`,
      HEADERS
    )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error?.response);
      });
  });
};

export const getMyReservationById = (idEmployee, today) => {
  return new Promise((resolve) => {
    HttpClient.get(`${MYRESERVATION}${idEmployee}?today=${today}`, HEADERS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error?.response);
      });
  });
};

export const geReservationHistory = (filters, paginator) => {
  return new Promise((resolve) => {
    HttpClient.get(
      `${RESERVATIONHISTORY}${filters.idEmployee}?today=${filters.today}&PageNumber=${paginator.page}&PageSize=${paginator.size}`,
      HEADERS
    )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error?.response);
      });
  });
};

export const newReservation = (body) => {
  return new Promise((resolve) => {
    HttpClient.post(`${RESERVATIONS}`, body, HEADERS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error?.response);
      });
  });
};

export const updateReservation = (idReservation, body) => {
  return new Promise((resolve) => {
    HttpClient.put(`${RESERVATIONS}/${idReservation}`, body, HEADERS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error?.response);
      });
  });
};

export const createGoupReservation = (body) => {
  return new Promise((resolve) => {
    HttpClient.post(`${GROUPRESERVATION}`, body, HEADERS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error?.response);
      });
  });
};
