// @vendors
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// @constants
import { ICON_REDUCER } from "../../redux/constants";

// @styles
import "../../App.css";
import "./style-conditions.css";
import { Tab, Tabs, useMediaQuery } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import ViewAdults from "./view-adults";
import ViewFamily from "./view-family";
import ViewGroup from "./view-group";
import ViewConditions from "./view-conditions";
import LookVideo from "./look-video";

const TermsAndConditions = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const matches = useMediaQuery("(min-width:750px)");

  useEffect(() => {
    dispatch({
      type: ICON_REDUCER,
      index: 7,
    });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="rootLocation">
      <h3 className="titleMain">Team Housing Benefit Policy</h3>
      <p className="textHeaders1">
        Team employees/contractors are eligible to enjoy our Team Housing
        benefit explained below:
      </p>

      <div>
        <div className="containHeaders">
          <h4 className="subtitleMain">Goal</h4>
          <p className="textHeaders">
            To provide Team International members with recreational and office
            space in locations around the world to promote: Teamwork, innovative
            office space, flexibility, and work-life balance.
          </p>
          <h4 className="subtitleMain">Target</h4>
          <p className="textHeaders">
            Team International employees/contractors and their families that
            have been working for the company for +6 months.
          </p>
        </div>

        <div className="containTabTerms">
          <TabContext value={value} className="tabContext">
            <div className="contTabList">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                sx={{
                  maxWidth: { xs: 300, sm: matches ? 680 : 340 },
                  minWidth: { xs: 200, sm: 200 },
                }}
              >
                <Tab label="Video" className="itemTab" />
                <Tab label="Adults only" className="itemTab" />
                <Tab label="Kids Friendly" className="itemTab" />
                <Tab label="Team Buildings" className="itemTab" />
                <Tab label="Conditions" className="itemTab" />
              </Tabs>
            </div>

            <TabPanel value={0}>
              <LookVideo />
            </TabPanel>

            <TabPanel value={1}>
              <ViewAdults />
            </TabPanel>

            <TabPanel value={2}>
              <ViewFamily />
            </TabPanel>

            <TabPanel value={3}>
              <ViewGroup />
            </TabPanel>

            <TabPanel value={4}>
              <ViewConditions />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
