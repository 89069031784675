// @vendors
import { useSelector } from "react-redux";

// @constants
import {
    MESSAGEERRORGENERAL,
    MESSAGEEXPIRATIONMESSAGE,
    MESSAGESUCCESS,
    MESSAGESUCCESSUPDATE,
    MESSAGETRYAGAIN
} from "../utils/constants-utils";
import {
    acquireAccessToken,
    saveTimeToken,
    tokenExpirationTime
} from "../utils/functions-utils";

export const useMessageRequest = () => {
    const state = useSelector((state) => state);
    const config = state?.msalReducer.config;

    const returnErrorForRequest = (response) => {
        if(response?.data?.errors?.EmployeeId?.length > 0) return 'Employee must not be empty.';
        if(response?.data?.Errors?.length > 0) return response?.data?.Errors?.map(x => x).toString();
        if(response?.data?.errors?.length > 0) return response?.data?.errors?.map(x => x).toString();
        if(response?.data?.errors?.length === 0 && response?.data?.Errors?.length === 0) return MESSAGETRYAGAIN;
    }

    const messageByRequest = (response, openMessageWarning, verb, returnLaterRequestSuccess) => {
        if(response?.status === 200 || response?.status === 201){
          const messj = verb === 'post' ? MESSAGESUCCESS : MESSAGESUCCESSUPDATE;
          openMessageWarning(messj, 'Done', 'SUCCESS', returnLaterRequestSuccess);
          return;
        }
        if(response?.status === 401){
          acquireAccessToken(config).then((accesstoken) => {
            const timeExp = tokenExpirationTime(accesstoken);
              saveTimeToken(timeExp)
              window.localStorage.setItem("token_app", accesstoken);
          });
          openMessageWarning(MESSAGEEXPIRATIONMESSAGE, 'Try again');
          return;
        }
        if(response?.status > 399 && response?.status !== 401){
            const description = returnErrorForRequest(response);
            openMessageWarning(description, 'Try again');
            return;
        }
        if(!response?.status) return openMessageWarning(MESSAGEERRORGENERAL, 'Try again');
    }

    return {
        messageByRequest
    }
}