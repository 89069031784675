// @vendors
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

// @constants
import { ICON_REDUCER } from "../../redux/constants";

// @style
import "./change-password.css";

// @components
import FormChangePassword from "./form-change-password";
import {
  getCredential,
  testNewCredential,
} from "../../actions/change-pass.word-action";
import { useState } from "react";
import ConfirmationMessage from "../../utils/modal-confirm/confirm-message";
import { useModalMessage } from "../../hooks/use-modal-message";

const ChangePasswordToEmail = () => {
  const dispatch = useDispatch();
  const [credential, setCredential] = useState();
  const { message, handleClickClose, openMessage, openMessageWarning } =
    useModalMessage();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch({
      type: ICON_REDUCER,
      index: 8,
    });
  }, [dispatch]);

  const getUserCredential = async () => {
    const response = await getCredential();
    if (response?.status === 200 || response?.status === 201)
      setCredential(response?.data);
  };

  useEffect(() => {
    getUserCredential();
  }, []);

  const checkChangePassword = async () => {
    setIsLoading(true);
    const response = await testNewCredential();
    if (response?.status === 200) {
      const msj = response?.data;
      openMessageWarning(msj, "", "SUCCESS");
    } else {
      const msj = response?.data?.Errors[0];
      openMessageWarning(msj, "", "WARNING");
    }
    setIsLoading(false);
  };

  return (
    <div className="containerChangePassword">
      <div className="divEmtyChangePassword"></div>
      <div className="divViewChangePassword">
        <FormChangePassword
          credential={credential}
          openMessage={openMessage}
          openMessageWarning={openMessageWarning}
          handleClickClose={handleClickClose}
          checkChangePassword={checkChangePassword}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </div>

      <ConfirmationMessage
        description={message.description}
        textButtonSubmit={message.textButtonSubmit}
        handleClick={message.handleClick}
        handleClickOut={message.handleClickOut}
        oneButtons={message.oneButtons}
        type={message.type}
        open={message.open}
      />
    </div>
  );
};

export default ChangePasswordToEmail;
