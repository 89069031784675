// @vendors
import React from 'react';
import { Controller } from 'react-hook-form';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {
    Grid,
    Button,
    FormControl,
    FormHelperText
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Locale from 'date-fns/locale/en-US';

// @constants
import { CssTextField } from '../../../utils/constants-utils';
import { changeDate, MaxInitDate } from '../../../utils/functions-utils';

// @style
import '../../../App.css';

const FormLock = ({
    onSubmit,
    register,
    handleSubmit,
    control,
    getValues,
    errors,
    handleClickCancelForm,
    minDate,
    setMinDate,
    maxDate,
    setMaxDate,
    isDisableb
}) => {
    const newDate = new Date();

    const onchangedate = (val) => {
        setMinDate(val);
    }  

  return (
    <div style={{marginTop:'5px'}}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth>
                        <LocalizationProvider locale={Locale} dateAdapter={AdapterDateFns}>
                            <Controller
                                fullWidth
                                control={control}
                                name='arrival'
                                size="small"
                                defaultValue={null}
                                render={({ field: { onChange, value, }}) => (
                                    <DatePicker
                                        {...register("arrival")}
                                        variant="outlined"
                                        allowSameDateSelection={true}
                                        label="Start Date *"
                                        name='arrival'
                                        defaultValue={null}
                                        onAccept={onchangedate}
                                        onOpen={() => MaxInitDate(getValues, setMaxDate)}
                                        value={value}
                                        onChange={ onChange}
                                        minDate={new Date()}
                                        maxDate={maxDate}
                                        renderInput={(params) => 
                                            <CssTextField 
                                                {...params} 
                                                InputLabelProps={{shrink: true}}
                                                {...register("arrival")}
                                                name='arrival'
                                                size="small" 
                                                variant="outlined"
                                                defaultValue={null}
                                                className='inputForm'
                                                error={errors.hasOwnProperty("arrival") && errors["arrival"].message}
                                            />
                                        }

                                    />
                                )}
                            /> 
                            <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                                {errors.hasOwnProperty("arrival") && errors["arrival"].message}
                            </FormHelperText>
                        </LocalizationProvider>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth>
                        <LocalizationProvider locale={Locale} dateAdapter={AdapterDateFns}>
                            <Controller
                                fullWidth
                                control={control}
                                name='departure'
                                size="small"
                                defaultValue={null}
                                render={({ field: { onChange, value, }}) => (
                                    <DatePicker
                                        {...register("departure")}
                                        variant="outlined"
                                        label="End Date *"
                                        name='departure'
                                        defaultValue={null}
                                        onAccept={(e) => changeDate(e, getValues, setMaxDate, newDate)}
                                        minDate={minDate ? minDate : new Date()}
                                        value={value}
                                        onChange={(newValue) => {
                                            onChange(newValue)
                                        }}
                                        renderInput={(params) => 
                                            <CssTextField 
                                                {...params} 
                                                InputLabelProps={{shrink: true}}
                                                {...register("departure")}
                                                name='departure'
                                                size="small" 
                                                value={value}
                                                defaultValue={null}
                                                className='inputForm'
                                                error={errors.hasOwnProperty("departure") && errors["departure"].message}
                                            />
                                        }

                                    />
                                )}
                            /> 
                            <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                                {errors.hasOwnProperty("departure") && errors["departure"].message}
                            </FormHelperText>
                        </LocalizationProvider>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={12} lg={12} className='contBtnModals'>
                    <Button
                        variant='contained'
                        onClick={handleClickCancelForm}
                        className='btnSubmitModal'
                    >
                        cancel
                    </Button>

                    <Button
                        variant='contained'
                        type='submit'
                        className='btnSubmitModal'
                        disabled={isDisableb}
                    >
                        save
                    </Button>

                </Grid>

            </Grid>
        </form>
    </div>
  )
}

export default FormLock