import HttpClient from '../services/http-client';
import { CITYS, COUNTRIES, HEADERS } from './contants';

export const getCitys = (filter) => {
    return new Promise((resolve) => {
        HttpClient.get(`${CITYS}?name=${filter.name}`, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}

export const saveCity = (body) => {
    return new Promise((resolve) => {
        HttpClient.post(`${CITYS}`, body, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}

export const getCountry = () => {
    return new Promise((resolve) => {
        HttpClient.get(`${COUNTRIES}`, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}

export const saveCountry = (body) => {
    return new Promise((resolve) => {
        HttpClient.post(`${COUNTRIES}`, body, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}