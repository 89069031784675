// @vendors
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// @actions
import { getCountry, saveCity } from "../../../actions/city-country-action";

// @constants
import { ICON_REDUCER } from "../../../redux/constants";
import {
  ENTERMAX,
  ENTERMIN,
  ENTERVALIDNAME,
  REQUIRED_FIELD,
  VALIDNAME,
} from "../../../utils/constants-utils";
import { handleOpenBackdrop } from "../../../utils/functions-utils";

// @style
import "./add-citys.css";

// @components
import FormCity from "./form-city";

const defaultValue = {
  name: "",
  country: "",
  code: "",
};

const AddCitys = ({
  openMessage,
  handleClickClose,
  messageByRequest,
  openMessageWarning,
}) => {
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);

  const schema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(REQUIRED_FIELD)
      .min(3, ENTERMIN(3))
      .max(100, ENTERMAX(100))
      .matches(VALIDNAME, ENTERVALIDNAME("name")),
    code: yup
      .string()
      .nullable()
      .required(REQUIRED_FIELD)
      .min(2, ENTERMIN(2))
      .max(20, ENTERMAX(20)),
    country: yup.string().nullable().required(REQUIRED_FIELD),
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValue,
    mode: "all",
    reValidateMode: "onChange",
  });

  const cleanForm = () => {
    setValue("name", "");
    setValue("code", "");
    setValue("country", "");
  };

  useEffect(() => {
    dispatch({
      type: ICON_REDUCER,
      index: 6,
    });
  }, [dispatch]);

  const getAllCountry = async () => {
    const response = await getCountry();
    if (response?.status === 200 || response?.status === 201) {
      setCountries(response?.data);
    } else {
      setCountries([]);
    }
  };

  useEffect(() => {
    getAllCountry();
  }, []);

  const onSubmit = (data) => {
    const newData = {
      locationCountryId: data.country,
      name: data.name,
      code: data.code.toUpperCase(),
      active: true,
    };
    const msj = "Are you sure to add this city?";
    openMessage(msj, handleClickCity, newData);
  };

  const handleClickCity = async (data) => {
    handleOpenBackdrop(dispatch, true);
    handleClickClose();
    const response = await saveCity(data);
    messageByRequest(response, openMessageWarning, "post", cleanForm);
    handleOpenBackdrop(dispatch, false);
  };

  return (
    <div className="divRootCity">
      <div className="containerTwoCity">
        <div className="contFormCity">
          <FormCity
            countries={countries}
            onSubmit={onSubmit}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            errors={errors}
            cleanForm={cleanForm}
          />
        </div>
      </div>
    </div>
  );
};

export default AddCitys;
