// @vendors
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";

// @action
import { getEmployeeById } from "../../actions/employee-action";
import { getLocation } from "../../actions/location-action";
import { createGoupReservation } from "../../actions/reservations-action";

// @constants
import { ICON_REDUCER } from "../../redux/constants";
import {
  areOlderDatesToCurrentDate,
  debounce,
  handleOpenBackdrop,
  verifyFileToDowload,
} from "../../utils/functions-utils";
import ConfirmationMessage from "../../utils/modal-confirm/confirm-message";
import { ROUTES } from "../../components/routes/constants";

// @components
import FormGroupReservation from "./form-group-reservation";

// @style
import "./group-reservation.css";
import "../../App.css";

// @hooks
import { useModalMessage } from "../../hooks/use-modal-message";
import { useMessageRequest } from "../../hooks/use-message-request";

const GroupReservation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const user = state?.userReducer.userData;
  const [citys, setCitys] = useState([]);
  const [listLocation, setListLocation] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [listEmployees, setListEmployees] = useState([]);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const { message, handleClickClose, openMessage, openMessageWarning } =
    useModalMessage();
  const { messageByRequest } = useMessageRequest();

  useEffect(() => {
    dispatch({
      type: ICON_REDUCER,
      index: 5,
    });
  }, [dispatch]);

  const returnValidName = (name) => {
    const newName =
      name && name !== null && name !== "string" && name !== "null" ? name : "";
    return newName;
  };

  const onSubmit = (data) => {
    const employees = listEmployees?.map((employee) => {
      const guest = {
        name: `${employee.firtsName} ${returnValidName(employee.secondName)} ${
          employee.surName
        } ${returnValidName(employee.secondSurName)}`,
        age: null,
        documentNumber: employee?.documentNumber
          ? employee?.documentNumber
          : null,
        employeeId: employee?.id,
        active: true,
        nationality: employee?.nationality ? employee?.nationality : "",
        origin: "",
        birthDate: employee?.birthDate ? moment(employee?.birthDate) : null,
      };
      return guest;
    });
    const dates = areOlderDatesToCurrentDate(
      data?.arrival,
      data?.departure,
      openMessageWarning
    );
    if (dates) return;
    else onSubmitedFinalize(data, employees);
  };

  const onSubmitedFinalize = (data, employees) => {
    const initDate = moment(data.arrival).format("YYYY-MM-DD");
    const endDate = moment(data.departure).format("YYYY-MM-DD");
    const newData = {
      locationId: listLocation?.id,
      employeeId: user?.id,
      arrival: `${initDate}T14:00:00.000Z`,
      departure: `${endDate}T11:00:00.000Z`,
      guests: employees?.length,
      numberBedrooms: listLocation?.bedrooms,
      numberDoubleRooms: listLocation?.doubleRooms,
      status: "Pending",
      origin: "",
      description: data.description,
      active: true,
      reservationGuest: employees,
    };
    const msj = `Are you sure to make this reservation?. Keep in mind that the location has an availability of ${
      listLocation?.bedrooms
    } rooms for the ${employees?.length + 1} team participants`;
    openMessage(msj, sendGroupReservation, newData);
  };

  const sendGroupReservation = async (data) => {
    handleOpenBackdrop(dispatch, true);
    setIsBtnDisabled(true);
    handleClickClose();
    const response = await createGoupReservation(data);
    if (response?.status === 200 || response?.status === 201)
      verifyFileToDowload(listLocation?.id);
    messageByRequest(response, openMessageWarning, "post", messageSuccess);
    setIsBtnDisabled(false);
    handleOpenBackdrop(dispatch, false);
  };

  const messageSuccess = () => {
    navigate(ROUTES.MYRESERVATION);
  };

  const getLocationByName = async (value) => {
    const paginator = { page: 0, size: 10 };
    const filter = { Name: value };
    const response = await getLocation(paginator, filter);
    if (response?.status === 200 || response?.status === 201) {
      setCitys(response?.data?.locations.resource);
    } else {
      setCitys([]);
    }
  };

  const queryLocationValue = debounce(function (e) {
    if (e.target.value?.length > 2) {
      getLocationByName(e.target.value);
    }
  }, 1000);

  const queryEmployeeValue = debounce(function (e) {
    if (e.target.value.length > 2) {
      const getAllEmployees = async () => {
        const filter = { FullName: e.target.value };
        const response = await getEmployeeById(filter);
        if (response?.status === 200 || response?.status === 201) {
          setEmployees(response?.data);
        } else {
          setEmployees([]);
        }
      };
      getAllEmployees(e);
    }
  }, 1000);

  return (
    <div className="containerGroup">
      <div className="contEmptyGroup"></div>
      <div className="contBodyGroup">
        <div className="containerForm">
          <div className="contTitleGroup">
            <h3 className="titleMain">Group reservation</h3>
          </div>
          <FormGroupReservation
            citys={citys}
            listLocation={listLocation}
            setListLocation={setListLocation}
            onSubmit={onSubmit}
            employees={employees}
            queryLocationValue={queryLocationValue}
            setListEmployees={setListEmployees}
            listEmployees={listEmployees}
            queryEmployeeValue={queryEmployeeValue}
            isBtnDisabled={isBtnDisabled}
          />
        </div>
      </div>
      <ConfirmationMessage
        description={message.description}
        textButtonSubmit={message.textButtonSubmit}
        handleClick={message.handleClick}
        handleClickOut={message.handleClickOut}
        oneButtons={message.oneButtons}
        type={message.type}
        open={message.open}
      />
    </div>
  );
};

export default GroupReservation;
