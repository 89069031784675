import { LIST_ROLES } from "../constants";

export const initialState = {
    roles: ''
};

const listRolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIST_ROLES :
            return {
                ...state,
                roles: action.roles
            }
    
        default:
            return state;
    }
}

export default listRolesReducer;