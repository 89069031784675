// @vendors
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

// @action
import { updatedEmploye } from "../actions/employee-action";

// @constants
import { USER_DATA } from "../redux/constants";

// @hooks
import { useModalMessage } from "./use-modal-message";

export const useUpdatedEmployee = () => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const user = state?.userReducer.userData;
    const  {openMessageWarning} = useModalMessage();

    const validName = (text) => {
        const name = text && text !== 'string' && text !== 'null' ? text : '';
        return name;
    }

    const updatedEmploy = (employee) => {
        const newData = {
          firtsName: user?.firtsName,
          secondName: employee?.secondName ? employee?.secondName : validName(user?.secondName),
          surName: user?.surName,
          secondSurName: employee?.secondSurname ? employee?.secondSurname : validName(user?.secondSurName),
          documentNumber: employee?.idEmployee ? employee?.idEmployee : user?.documentNumber,
          email: user?.email,
          origin: '',
          phone: employee?.phone ? employee?.phone : user?.phone,
          nationality: employee?.nationality ? employee?.nationality : user?.nationality,
          hiredDate: user?.hiredDate,
          birthDate: employee?.birthDate ? moment(employee?.birthDate).toISOString() : user?.birthDate,
          active: true
        }
        isValidSentEmployee(newData);
    }

    const validPropertUser = (prop) => {
        const property = !prop || prop === null || prop === 'string' || prop === 0 || prop === 'null' ? true : false;
        return property;
    }

    const isValidSentEmployee = (newData) => {
        const phone = validPropertUser(user?.phone);
        const nationality = validPropertUser(user?.nationality);
        const documentNumber = validPropertUser(user?.documentNumber);
        const secondName = validPropertUser(user?.secondName);
        const secondSurname = validPropertUser(user?.secondSurName);
        const birthDate = validPropertUser(user?.birthDate);
        if(phone || nationality || documentNumber || secondName || secondSurname || birthDate)hasUpdateUser(newData);
    }
    
    const hasUpdateUser = async (body) => {
        const response = await updatedEmploye(user?.id, body);
        if(response?.status === 200 || response?.status === 201){
          dispatch({
            type: USER_DATA,
            userData: response?.data,
          });
        }else if(response?.status > 399){
            openMessageWarning("The employee's information was not updated");
        }
    }

    return {
        updatedEmploy
    }
}