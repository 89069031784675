// @vendors
import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Box } from "@mui/system";
import moment from "moment";

// @components
import ReservationDetail from "./ReservationDetail";

const formatedDate = (date) => moment(date).format("YYYY-MM-DD");

const addDay = (date) => formatedDate(moment(date).add(1, "day").toString());

export const AvailabilityCalendar = ({ data }) => {
  const currentDate = new Date();
  const [open, setOpen] = useState(false);
  const [reservations, setReservations] = useState([]);

  const handleOpen = () => setOpen((e) => !e);

  const openAndSelect = (e, info) => {
    const datePicked = formatedDate(e?.dateStr);
    const filteredEvents = info.filter((item) => {
      if (
        moment(datePicked).isSameOrAfter(item?.start) &&
        moment(datePicked).isSameOrBefore(item?.departure)
      ) {
        return item;
      }
    });
    setReservations(filteredEvents);
    handleOpen();
  };

  const returnClassNameToCalendar = (param) => {
    const TOTALBEDROOMS = data?.bedrooms + data?.doubleRooms;
    const TOTALRECEIVED = param?.numberBedrooms + param?.numberDoubleRooms;
    if (TOTALBEDROOMS === TOTALRECEIVED) return "fullCalendar2";
    return "fullCalendar";
  };

  const filteredData =
    data?.locationReservations &&
    [...data?.locationReservations]?.map((item) => ({
      ...item,
      start: formatedDate(item?.arrival),
      end: addDay(item?.departure),
      display: "background",
      bedrooms: data?.bedrooms,
      className: returnClassNameToCalendar(item),
    }));

  return (
    <>
      <Box className="calendar-container">
        <FullCalendar
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth",
          }}
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          initialDate={currentDate}
          allDaySlot={false}
          editable={false}
          eventDurationEditable={false}
          height="32rem"
          events={filteredData}
          dateClick={(e) => openAndSelect(e, filteredData)}
        />
      </Box>
      <ReservationDetail
        open={open}
        onClose={handleOpen}
        data={reservations}
        location={data}
      />
    </>
  );
};
