export const ROUTE_ROOT = process.env.REACT_APP_SERVER;
//export const ROUTE_ROOT = process.env.REACT_APP_SERVER_LOCALHOST;
export const LOGIN = "/api/accounts/log-in";
export const REGISTER = "/api/accounts/register-in";
export const EMPLOYEES = "/api/Employees";
export const LOCATION = "/api/locations";
export const CITYS = "/api/cities";
export const COUNTRIES = "/api/countries";
export const RESERVATIONS = "/api/reservations";
export const CANCELEDRESERVATIONS = "/api/reservations/canceled";
export const LOCATIONTYPE = "/api/locationType";
export const FEATURES = "/api/features";
export const MYRESERVATION = "/api/reservations/My-Reservations/";
export const RESERVATIONHISTORY = "/api/reservations/Reservation-History/";
export const NOTIFICATIONS = "/api/notifications";
export const ROLE = "/api/authorization/Assign-role";
export const APP_URL = process.env.REACT_APP_SERVER_TALENT;
export const MAINPHOTO = "/api/locations/gallery/photo";
export const GALLERYPHOTO = "/api/locations/gallery/photos";
export const GETPHOTO = "/api/locations/gallery/";
export const GROUPRESERVATION = "/api/reservations/reservation-group";
export const UPDATEEMPLOYEES = "/api/excel";
export const GETTOKEN = "/api/authorization/Generate-token";
export const LOCKLOCATION = "/api/reservations/reservation-ghost";
export const FILE = "/api/file";
export const GALLERYBYLOCATION = "/api/gallery_azure/location/";
export const IMAGELOCATION = "/api/gallery_azure";
export const GALLERYLOCATION = "/api/gallery_azure/list";
export const CREDENTIAL = "/api/credential";
export const HEADERS = { "Content-Type": "application/json" };
