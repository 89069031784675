// @vendors
import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// @constants
import { REQUIRED_FIELD } from "../../../utils/constants-utils";
import {
  debounce,
  handleOpenBackdrop,
  jwtRol,
} from "../../../utils/functions-utils";
import { ROUTES } from "../../../components/routes/constants";

// @components
import FormAssign from "./form-assign";

// @actions
import {
  assignRolle,
  getEmployeeById,
  getTokenByEmail,
} from "../../../actions/employee-action";

const defaultValue = {
  rol: "",
};

const AssignRole = ({
  openMessage,
  handleClickClose,
  messageByRequest,
  openMessageWarning,
}) => {
  const [employees, setEmployees] = useState([]);
  const [listEmployee, setListEmployee] = useState([]);
  const [assignRole, setAssignRole] = useState("");
  const state = useSelector((state) => state);
  const user = state?.userReducer.userData;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schema = yup.object().shape({
    rol: yup.string().nullable().required(REQUIRED_FIELD),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValue,
    mode: "all",
    reValidateMode: "onChange",
  });

  const onSubmit = (data) => {
    if (assignRole === "") {
      const text = "Please specify if you are going to assign or remove a role";
      openMessageWarning(text, "Try Again");
      return;
    }
    if (listEmployee?.length === 0 || listEmployee === null) {
      const text = "Please select an employee";
      openMessageWarning(text, "Try Again");
      return;
    }
    assignDataForRequest(data);
  };

  const assignDataForRequest = (data) => {
    const newData = {
      isAssigning: assignRole === "false" ? false : true,
      email: listEmployee?.email,
      roleName: data.rol,
    };
    const msj =
      assignRole === "false"
        ? `Are you sure to remove the ${data.rol} role to the employee ${listEmployee.firtsName} ${listEmployee.surName}?`
        : `Are you sure to assign the ${data.rol} role to the employee ${listEmployee.firtsName} ${listEmployee.surName}?`;
    openMessage(msj, handleClickAssignRole, newData);
  };

  const handleClickAssignRole = async (info) => {
    handleOpenBackdrop(dispatch, true);
    handleClickClose();
    const response = await assignRolle(info);
    messageByRequest(
      response,
      openMessageWarning,
      "post",
      messageSucessRequestRole
    );
    handleOpenBackdrop(dispatch, false);
  };

  const messageSucessRequestRole = () => {
    const functionRedirect =
      listEmployee?.id === user?.id
        ? handleClickCancelSuccess()
        : handleClickCancel();
    return functionRedirect;
  };

  const handleClickCancelSuccess = () => {
    getTokenUser();
    navigate(ROUTES.LOCATION);
  };

  // if the user updated his roles
  const getTokenUser = async () => {
    const response = await getTokenByEmail(user?.email);
    jwtRol(response?.data?.token, dispatch);
  };

  const getEmployeeByName = debounce(function (e) {
    if (e.target.value.length > 2) {
      const getEmployee = async (e) => {
        const filter = { FullName: e.target.value };
        const response = await getEmployeeById(filter);
        if (response?.status === 200 || response?.status === 201) {
          setEmployees(response?.data);
        } else {
          setEmployees([]);
        }
      };
      getEmployee(e);
    }
  }, 1000);

  const handleClickCancel = () => {
    setAssignRole("");
    setValue("rol", "");
    setListEmployee([]);
    setEmployees([]);
    handleClickClose();
  };

  const handleClickAssign = (e) => {
    setAssignRole(e.target.value);
  };

  return (
    <div style={{ display: "flex" }}>
      <FormAssign
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        employees={employees}
        setListEmployee={setListEmployee}
        getEmployeeByName={getEmployeeByName}
        handleClickCancel={handleClickCancel}
        handleClickAssign={handleClickAssign}
        assignRole={assignRole}
      />
    </div>
  );
};

export default AssignRole;
