import HttpClient from '../services/http-client';
import { HEADERS, NOTIFICATIONS } from './contants';

export const getNotification = (userId,paginador) => {
    return new Promise((resolve) => {
        HttpClient.get(`${NOTIFICATIONS}?employeeId=${userId}&read=false&PageNumber=${paginador.page}&PageSize=${paginador.size}`, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}

export const updateNotification = (idNotification, body) => {
    return new Promise((resolve) => {
        HttpClient.put(`${NOTIFICATIONS}/${idNotification}`, body, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}