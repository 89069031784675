// @vendors
import React, { useState, useEffect } from "react";
import { Button, Divider, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import parse from "html-react-parser";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Lazy } from "swiper";
import moment from "moment";

// @style swiper
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/lazy";

// @style
import "../../App.css";
import "./detail.css";

// @img
import noPhoto from "../../assets/svg/no_image.svg";

// @constants
import { ROUTES } from "../../components/routes/constants";
import { LOCATION_DETAIL } from "../../redux/constants";

// @components
import ListDetailLocation from "../../components/list/list-detail-location";
import AvailabilityModal from "../../components/modals/availability/availability-modal";
import ConfirmationMessage from "../../utils/modal-confirm/confirm-message";

// @action
import { locationById } from "../../actions/location-action";
import { getGalleryByLocation } from "../../actions/photo-action";

// @hooks
import { useModalMessage } from "../../hooks/use-modal-message";
import { MESSAGEERROR } from "../../utils/constants-utils";

const DetailLocation = () => {
  const { idLocation } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openAvailability, setOpenAvailability] = useState(false);
  const [location, setLocation] = useState({});
  const [mainImage, setMainImage] = useState({});
  const [gallery, setGallery] = useState([]);
  const matches = useMediaQuery("(min-width:1000px)");
  const { message, openMessageWarning } = useModalMessage();

  const getImages = async () => {
    const response = await getGalleryByLocation(idLocation);
    if (response?.status === 200 || response?.status === 201) {
      const isMain = response?.data?.find((x) => x?.isMain === true);
      setMainImage(isMain);
      setGallery(response?.data);
    } else openMessageWarning(MESSAGEERROR, "Try Again");
  };

  useEffect(() => {
    getImages();
  }, []);

  const handleClickReserve = () => {
    navigate(`${ROUTES.RESERVEID}${idLocation}`);
  };

  const seeAvailability = () => {
    setOpenAvailability(!openAvailability);
  };

  const getLocationById = async () => {
    const response = await locationById(idLocation);
    if (response?.status === 200 || response?.status === 201) {
      modificateDateForReservation(response?.data);
    } else openMessageWarning(MESSAGEERROR, "Try Again");
  };

  const modificateDateForReservation = (data) => {
    if (data?.locationReservations) {
      const listUpdate = data.locationReservations.map((item) => {
        let newDeparture = new Date(item.departure);
        newDeparture.setDate(newDeparture.getDate() - 1);
        const formatDeparture = moment(newDeparture).toISOString();
        const info = { ...item, departure: formatDeparture };
        return info;
      });
      const bookUpdated = { ...data, locationReservations: listUpdate };
      addNewObjToLocation(bookUpdated);
    }
  };

  const addNewObjToLocation = (info) => {
    setLocation(info);
    dispatch({
      type: LOCATION_DETAIL,
      data: info,
    });
  };

  useEffect(() => {
    if (idLocation) getLocationById();
  }, [idLocation]);

  const handleClickSelectImage = (image) => {
    setMainImage(image);
  };

  return (
    <div className="rootGeneral">
      <Grid container>
        <h3 className="titleMain">{location?.name}</h3>

        <div className="contAvailability">
          <Button
            variant="contained"
            onClick={handleClickReserve}
            className="btnAvailable"
          >
            Book
          </Button>
          <Button
            variant="contained"
            onClick={seeAvailability}
            className="btnAvailable"
          >
            availability
          </Button>
        </div>

        <div className="contImgs">
          <div className="containerImgMain">
            <img
              src={mainImage?.photo ? mainImage?.photo : noPhoto}
              alt="image_main"
              className="imgMainDetail"
            />
          </div>

          <div className="containerGallery">
            <Swiper
              style={{
                "--swiper-navigation-color": "#5f788d",
                "--swiper-pagination-color": "#243747",
              }}
              Lazy={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Lazy, Pagination, Navigation]}
              className="mySwiper"
              slidesPerView={matches ? 2 : 1}
              spaceBetween={0}
            >
              {gallery?.map((image, index) => (
                <div key={index} className="contItemGallery">
                  <SwiperSlide
                    onClick={() => handleClickSelectImage(image)}
                    key={index}
                  >
                    <img
                      src={image?.photo}
                      alt="image_gallery"
                      className="imageGallery"
                      style={{ borderRadius: 10 }}
                    />
                  </SwiperSlide>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
      </Grid>
      <Grid container>
        <div className="contDetDescription">
          <div className="itemDescription">
            <Grid container className="itemDescriptionCont">
              <ListDetailLocation
                text={`Location Type : ${location?.type ?? ""}`}
              />
              <ListDetailLocation text={`Location : ${location?.city ?? ""}`} />
              <ListDetailLocation
                text={`Address : ${location?.adress ?? ""}`}
              />
              <ListDetailLocation text={`Phone : ${location?.phone ?? ""}`} />
              <ListDetailLocation text={`Email : ${location?.email ?? ""}`} />
              {location?.bedrooms > 0 && (
                <ListDetailLocation text={`${location?.bedrooms} Bedrooms`} />
              )}
              {location?.doubleRooms > 0 && (
                <ListDetailLocation text={"Double accommodation"} />
              )}
              {location?.locationFeatureJoins?.map((item, index) => (
                <ListDetailLocation key={index} text={item?.feature} />
              ))}
            </Grid>
          </div>

          <Divider />

          <div className="divDescriptionLocation">
            <p className="textDesDetail">
              {parse(location?.description ?? "")}
            </p>
          </div>
        </div>
      </Grid>

      <AvailabilityModal
        open={openAvailability}
        seeAvailability={seeAvailability}
        location={location}
      />

      <ConfirmationMessage
        description={message.description}
        textButtonSubmit={message.textButtonSubmit}
        handleClick={message.handleClick}
        handleClickOut={message.handleClickOut}
        oneButtons={message.oneButtons}
        type={message.type}
        open={message.open}
      />
    </div>
  );
};

export default DetailLocation;
