export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENTID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: process.env.REACT_APP_REDIRECTURI
    },
};

export const loginRequest = {
    scopes: ["api://81959db0-40f2-47b3-a898-e0d2ae3932ea/api.scope"]
};

export const loginRequestGraph = {
    scopes: ["User.Read"]
};

export const graphConfig = {
    //graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
    graphMeEndpoint: "https://graph.microsoft.com/beta/me/profile"
};