// @vendors
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Grid, Link, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

// @constants
import { ICON_REDUCER } from "../../redux/constants";
import { ROUTES } from "../../components/routes/constants";
import { debounce, handleOpenBackdrop } from "../../utils/functions-utils";

// @style
import "../../App.css";
import "./style-add-location.css";

// @components
import LocationForm from "./form-location";
import ConfirmationMessage from "../../utils/modal-confirm/confirm-message";
import { BootstrapTooltip, MESSAGEERROR } from "../../utils/constants-utils";
import FeatureModal from "../../components/modals/features/feature-modal";

// @action
import {
  locationById,
  sendLocation,
  updateLocation,
} from "../../actions/location-action";
import { getCitys } from "../../actions/city-country-action";
import { getFeatures } from "../../actions/feature-action";
import {
  addGalleryForLocation,
  addPhotoForLocation,
  updatedPhoto,
} from "../../actions/photo-action";

// @hooks
import { useModalMessage } from "../../hooks/use-modal-message";
import { useMessageRequest } from "../../hooks/use-message-request";

const AddLocations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { idLocation } = useParams();
  const state = useSelector((state) => state);
  const locationTypes = state?.locationTypeReducer.types;
  const [listLocation, setListLocation] = useState([]);
  const [contentDescription, setContentDescription] = useState("");
  const [mainPhoto, setMainPhoto] = useState(null);
  const [listGallery, setListGallery] = useState([]);
  const [citys, setCitys] = useState([]);
  const [features, setFeatures] = useState([]);
  const [featuresSelected, setFeaturesSelected] = useState([]);
  const [openModalFeature, setOpenModalFeature] = useState(false);
  const [locationId, setLocationId] = useState({});
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [isAdult, setIsAdult] = useState(false);
  const [isFamily, setIsFamily] = useState(false);
  const { message, handleClickClose, openMessage, openMessageWarning } =
    useModalMessage();
  const { messageByRequest } = useMessageRequest();

  useEffect(() => {
    if (idLocation) {
      dispatch({
        type: ICON_REDUCER,
        index: 2.2,
      });
    } else {
      dispatch({
        type: ICON_REDUCER,
        index: 2,
      });
    }
  }, [dispatch, idLocation]);

  //query only when there is an id
  const getLocationId = async () => {
    const response = await locationById(idLocation);
    if (response?.status === 200 || response?.status === 201) {
      const data = response?.data;
      setLocationId(data);
      setContentDescription(data.description);
      getAllcitys(data?.city);
      getAllFeatures(data);
      let listFeatureLocation = [];
      data?.locationFeatureJoins?.map((x) => {
        const hasFeature = featuresSelected?.find(
          (item) => item?.locationFeatureId === x.featureId
        );
        if (!hasFeature) {
          const addFeature = { locationFeatureId: x.featureId };
          listFeatureLocation.push(addFeature);
        }
        return x;
      });
      setFeaturesSelected(listFeatureLocation);
    } else openMessageWarning(MESSAGEERROR);
  };

  useEffect(() => {
    if (idLocation) {
      getLocationId();
    }
  }, [idLocation]);

  const getAllFeatures = async (data) => {
    let newListFeatures = [];
    const response = await getFeatures();
    if (response?.status === 200 || response?.status === 201) {
      response?.data?.map((item) => {
        const selected =
          data?.locationFeatureJoins?.find((x) => item.id === x.featureId) ??
          false;
        let newData = {
          locationFeatureId: item.id,
          name: item.name,
          check: selected ? true : false,
        };
        newListFeatures.push(newData);
        return item;
      });
      setFeatures(newListFeatures);
    } else openMessageWarning(MESSAGEERROR);
  };

  useEffect(() => {
    if (!idLocation) {
      getAllFeatures();
    }
  }, [idLocation]);

  const onSubmit = (info) => {
    if (listLocation?.length === 0 || listLocation === null) {
      const msj = "The location field is required, please select one.";
      openMessageWarning(msj, "Accept");
      return;
    }
    if (contentDescription?.length === 0) {
      const msj = "The description field is required, please enter a text.";
      openMessageWarning(msj, "Accept");
      return;
    }
    if (mainPhoto === null) {
      const msj = "Please select a main photo.";
      openMessageWarning(msj, "Accept");
      return;
    }
    if (listGallery.length === 0) {
      const msj = "Please enter at least one image in gallery.";
      openMessageWarning(msj, "Accept");
      return;
    }
    nextOnSubmit(info);
  };

  const nextOnSubmit = (info) => {
    const locatioType = locationTypes?.find(
      (x) => x.name === info.locationType
    );
    const newData = {
      locationTypeId: locatioType.id,
      locationCityId: listLocation?.id,
      name: info.name,
      description: contentDescription,
      email: info.email,
      phone: info.phone,
      adress: info.address,
      bedrooms: info.nbedrooms,
      doubleRooms: info.doubleRoom ? info.doubleRoom : 0,
      locationFeature: featuresSelected,
      active: true,
    };
    const description = idLocation
      ? "Are you sure to update this location?"
      : "Are you sure to create this location?";
    openMessage(description, handleClickSendData, newData);
  };

  const handleClickSendData = async (info) => {
    handleOpenBackdrop(dispatch, true);
    setIsBtnDisabled(true);
    handleClickClose();
    const response = idLocation
      ? await updateLocation(idLocation, info)
      : await sendLocation(info);
    if (idLocation) {
      if (response?.status === 200 || response?.status === 201) {
        const idLocation = response?.data.id;
        updateMainIamge(idLocation);
      }
      const verb = idLocation ? "put" : "post";
      messageByRequest(response, openMessageWarning, verb, handleClickSuccess);
    } else {
      if (response?.status === 200 || response?.status === 201) {
        const idLocation = response?.data.id;
        saveMainIamge(idLocation);
        saveGalleryImage(idLocation);
      }
      const verb = idLocation ? "put" : "post";
      messageByRequest(response, openMessageWarning, verb, handleClickSuccess);
    }
    setIsBtnDisabled(false);
    handleOpenBackdrop(dispatch, false);
  };

  const handleClickSuccess = () => {
    navigate(ROUTES.LOCATION);
  };

  const returnNewFeatures = (e, check) => {
    const newListFeatures = [];
    features.map((item) => {
      if (item.locationFeatureId === e.target.value) {
        const selectedItem = { ...item, check: check };
        newListFeatures.push(selectedItem);
      } else {
        newListFeatures.push(item);
      }
      setFeatures(newListFeatures);
      return item;
    });
  };

  // save main photo by location
  const saveMainIamge = async (idNewLocation) => {
    const formData = new FormData();
    formData.append("LocationId", idNewLocation);
    formData.append("IsMain", true);
    formData.append("FormFile", mainPhoto);
    await addPhotoForLocation(formData);
  };

  //save gallery by location
  const saveGalleryImage = async (idNewLocation) => {
    const formData = new FormData();
    formData.append("LocationId", idNewLocation);
    for (let i = 0; i < listGallery?.length; i++) {
      formData.append("FormFiles", listGallery[i]);
    }
    await addGalleryForLocation(formData);
  };

  // update main photo by location
  const updateMainIamge = async (idNewLocation) => {
    const formData = new FormData();
    formData.append("LocationId", idNewLocation);
    formData.append("IsMain", true);
    formData.append("FormFile", mainPhoto?.image);
    await updatedPhoto(mainPhoto?.id, formData);
  };

  const handleClickCheckFeature = (e) => {
    if (e.target.checked === true) {
      returnNewFeatures(e, true);
      const objectFeature = { locationFeatureId: e.target.value };
      setFeaturesSelected([...featuresSelected, objectFeature]);
    } else {
      returnNewFeatures(e, false);
      const newList = featuresSelected.filter(
        (x) => x?.locationFeatureId !== e.target.value
      );
      setFeaturesSelected(newList);
    }
  };

  const handleClickNewFeature = () => {
    setOpenModalFeature(!openModalFeature);
  };

  const getAllcitys = async (city, isFirts) => {
    if (city !== undefined) {
      let filter = { name: city };
      const response = await getCitys(filter);
      if (isFirts) setListLocation(response?.data[0]);
      if (response?.status === 200 || response?.status === 201) {
        setCitys(response?.data);
      } else {
        setCitys([]);
      }
    }
  };

  // get citys by text
  const queryLocationValue = debounce(function (e) {
    if (e.target.value.length > 2) {
      getAllcitys(e.target.value);
    }
  }, 1000);

  return (
    <div className="rootGeneral">
      <Grid>
        <Grid>
          <h3 className="titleMain">
            {idLocation ? "Edit location" : "Add location"}
          </h3>
        </Grid>

        <Grid>
          <LocationForm
            citys={citys}
            setListLocation={setListLocation}
            contentDescription={contentDescription}
            setContentDescription={setContentDescription}
            onSubmit={onSubmit}
            setMainPhoto={setMainPhoto}
            setListGallery={setListGallery}
            locationTypes={locationTypes}
            locationId={locationId}
            queryLocationValue={queryLocationValue}
            listLocation={listLocation}
            getAllcitys={getAllcitys}
            isBtnDisabled={isBtnDisabled}
            setIsFamily={setIsFamily}
            setIsAdult={setIsAdult}
            isAdult={isAdult}
            isFamily={isFamily}
          />

          <div className="linkAddFeature">
            <Typography variant="h6" className="titleAmenities">
              Location amenities
            </Typography>
            <BootstrapTooltip title="New Amenity" placement="top" arrow>
              <Link
                underline="none"
                style={{ color: "#5f788d", cursor: "pointer" }}
                onClick={handleClickNewFeature}
              >
                Add Amenity
              </Link>
            </BootstrapTooltip>
          </div>
          <Grid container>
            {features?.map((feature, index) => (
              <Grid item xs={6} md={4} lg={4} key={index}>
                <Grid container style={{ display: "flex" }}>
                  <Grid item xs={2} md={2} lg={2} style={{ maxWidth: "40px" }}>
                    <Checkbox
                      value={feature.locationFeatureId}
                      checked={
                        feature?.check ? feature.locationFeatureId : false
                      }
                      onChange={(e) => handleClickCheckFeature(e)}
                      sx={{ color: "#5f788d !important" }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      id={feature.locationFeatureId}
                    />
                  </Grid>
                  <Grid item xs={10} md={10} lg={10} style={{ margin: "auto" }}>
                    <Typography>{feature.name}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <FeatureModal
        open={openModalFeature}
        handleClickNewFeature={handleClickNewFeature}
        getAllFeatures={idLocation ? getLocationId : getAllFeatures}
        handleClickClose={handleClickClose}
        openMessage={openMessage}
        messageByRequest={messageByRequest}
        openMessageWarning={openMessageWarning}
      />

      <ConfirmationMessage
        description={message.description}
        textButtonSubmit={message.textButtonSubmit}
        handleClick={message.handleClick}
        handleClickOut={message.handleClickOut}
        oneButtons={message.oneButtons}
        type={message.type}
        open={message.open}
      />
    </div>
  );
};

export default AddLocations;
