// @vendors
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

// @constants
import { ROUTES } from './constants';

const PrivateRoutes = ({children}) => {
  const authenticated = useSelector((state) => state?.userSesionReducer?.authenticated);
 
  if(!authenticated){
    return <Navigate to={ROUTES.PAGENOTFOUND}/>
  }
  
  return children;
}

export default PrivateRoutes;