// @vendors
import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// @components
import FormLock from "./form-lock";

// @actions
import { lockLocation } from "../../../actions/location-action";

// @style
import "./style-lock.css";

// @constants
import {
  areOlderDatesToCurrentDate,
  handleOpenBackdrop,
} from "../../../utils/functions-utils";
import {
  MINDATE,
  REQUIRED_FIELD,
  VALIDFORMATDATE,
} from "../../../utils/constants-utils";

// @hooks
import { useMessageRequest } from "../../../hooks/use-message-request";

const defaultValue = {
  arrival: null,
  departure: null,
};

const LockLocationMoadal = ({
  open,
  setOpenModalLock,
  data,
  openMessage,
  openMessageWarning,
  handleClickClose,
}) => {
  const state = useSelector((state) => state);
  const user = state?.userReducer.userData;
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(minDate);
  const [isDisableb, setIsDisableb] = useState(false);
  const { messageByRequest } = useMessageRequest();
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    arrival: yup
      .date()
      .nullable()
      .required(REQUIRED_FIELD)
      .min(new Date(1900, 11, 31, 23, 59, 29), MINDATE)
      .typeError(VALIDFORMATDATE),
    departure: yup
      .date()
      .nullable()
      .required(REQUIRED_FIELD)
      .min(new Date(1900, 11, 31, 23, 59, 29), MINDATE)
      .typeError(VALIDFORMATDATE),
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValue,
    mode: "all",
    reValidateMode: "onChange",
  });

  const handleClickCancelForm = () => {
    setValue("arrival", null);
    setValue("departure", null);
    setMinDate(null);
    setMaxDate(null);
    setOpenModalLock(false);
  };

  const nexSubmit = (info) => {
    const initDate = moment(info.arrival).format("YYYY-MM-DD");
    const endDate = moment(info.departure).format("YYYY-MM-DD");
    const newData = {
      locationId: data?.id,
      employeeId: user?.id,
      arrival: `${initDate}T14:00:00.000Z`,
      departure: `${endDate}T11:00:00.000Z`,
      guests: 0,
      numberBedrooms: data?.bedrooms,
      numberDoubleRooms: data?.doubleRooms,
      status: "Approved",
      description: "Block for admin",
      origin: "",
      active: true,
      reservationGuest: [],
    };
    const msj = "Are you sure to block this location for these dates?";
    openMessage(msj, handleClickSubmit, newData);
  };

  const onSubmit = (info) => {
    const dates = areOlderDatesToCurrentDate(
      info?.arrival,
      info?.departure,
      openMessageWarning
    );
    if (dates) return;
    else nexSubmit(info);
  };

  const handleClickSubmit = async (info) => {
    handleOpenBackdrop(dispatch, true);
    setIsDisableb(true);
    handleClickClose();
    const response = await lockLocation(info);
    messageByRequest(
      response,
      openMessageWarning,
      "post",
      handleClickCancelForm
    );
    setIsDisableb(false);
    handleOpenBackdrop(dispatch, false);
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle className="dialogTitleLock">
          <Grid container className="titleLock">
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                textTransform: "uppercase",
                fontSize: 15,
              }}
            >
              Lock Location
            </Typography>
          </Grid>
        </DialogTitle>

        <DialogContent className="dialogContentLock">
          <FormLock
            onSubmit={onSubmit}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            getValues={getValues}
            errors={errors}
            handleClickCancelForm={handleClickCancelForm}
            minDate={minDate}
            setMinDate={setMinDate}
            maxDate={maxDate}
            setMaxDate={setMaxDate}
            isDisableb={isDisableb}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LockLocationMoadal;
