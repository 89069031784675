// @vendors
import { Box, Container, Modal, Paper, Typography } from "@mui/material";
import MultipeBedIcon from "@mui/icons-material/FamilyRestroomOutlined";
import DoubleBedIcon from "@mui/icons-material/WcOutlined";

// @style
import "../style-availability.css";
import { BootstrapTooltip } from "../../../../utils/constants-utils";

const ReservationDetail = ({ open, onClose, data, location }) => {
  const returnNumberAvailableBed = () => {
    let rooms = {
      bedRooms: 0,
      doubleRooms: 0,
    };
    data?.map((book) => {
      return (rooms = {
        ...rooms,
        bedRooms: rooms.bedRooms + book.numberBedrooms,
        doubleRooms: rooms.doubleRooms + book.numberDoubleRooms,
      });
    });
    return rooms;
  };

  const availableRooms = returnNumberAvailableBed();
  const singleBed = location?.bedrooms - availableRooms.bedRooms;
  const doubleBed = location?.doubleRooms - availableRooms.doubleRooms;

  const columnsBody = (title, text) => {
    return (
      <div className="avContainTitleText">
        <h5 className="avTitle">{title}</h5>
        <h6 className="avText">{text < 0 ? 0 : text}</h6>
      </div>
    );
  };

  const returnTextIfAvailableOrNo = () => {
    if (data?.length <= 0)
      return "There are not reservations for the selected date.";
    if (singleBed + doubleBed === 0)
      return "There are no rooms available for this date, please ask for a different date.";
    if (singleBed + doubleBed > 0) {
      let firtsWords = `For this date there are `;
      if (singleBed > 0 && doubleBed > 0)
        return (firtsWords = `${firtsWords} ${singleBed} double rooms and ${doubleBed} multiple rooms available.`);
      if (singleBed > 0 && doubleBed === 0)
        return (firtsWords = `${firtsWords} ${singleBed} double rooms available.`);
      if (singleBed === 0 && doubleBed > 0)
        return (firtsWords = `${firtsWords} ${doubleBed} multiple rooms available.`);
    }
  };

  const returnTextForAvailability = () => {
    const color = singleBed + doubleBed === 0 ? "#fb0303" : "#5ce706";
    return (
      <p className="textNoReservation" style={{ color: color }}>
        {returnTextIfAvailableOrNo()}
      </p>
    );
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Container>
          <Paper className="reservation-modal-container">
            <Typography variant="h5" textAlign="center" className="mod-res-det">
              Reservation detail
            </Typography>
            <div className="contIconBed">
              <BootstrapTooltip
                title="Full Double Accommodation"
                placement="top"
                arrow
              >
                <div className="contIconBedTwo">
                  <DoubleBedIcon className="styleIcon" />
                  <Typography variant="body2">{location?.bedrooms}</Typography>
                </div>
              </BootstrapTooltip>
              <BootstrapTooltip
                title="Full Multiple Accommodation"
                placement="top"
                arrow
              >
                <div className="contIconBedTwo">
                  <MultipeBedIcon className="styleIcon" />
                  <Typography variant="body2">
                    {location?.doubleRooms}
                  </Typography>
                </div>
              </BootstrapTooltip>
            </div>

            <div className="avContainerAvailability">
              {columnsBody("Total reservations: ", data?.length)}
              {columnsBody("Double rooms available: ", singleBed)}
              {location?.type === "Family"
                ? columnsBody("Multiples Rooms available: ", doubleBed)
                : null}
            </div>

            <Box className="empty-reservation-container">
              {returnTextForAvailability()}
            </Box>
          </Paper>
        </Container>
      </Modal>
    </>
  );
};

export default ReservationDetail;
