// @vendors
import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// @style
import './page-not-found.css';

// @constants
import { ROUTES } from '../routes/constants';
import { useMsal } from '@azure/msal-react';

const PageNotFound = () => {
  const state = useSelector((state) => state?.userSesionReducer.authenticated);
  const navigate = useNavigate();
  const {instance} = useMsal();
 
  const navigateHome = () => {
    navigate(state ? ROUTES.LOCATION : ROUTES.LOCATION);
  }

  useEffect(() => {
    if(!state){
      instance.logoutRedirect({
        postLogoutRedirectUri:"/"
      })
    }
  }, [state, instance])
  

  return (
      <div className='rootNotFound'>
        <div className='divTwoNotFound'>
          
          <h3 className='not404Found'>{state ? '404' : '!!!!important'}</h3>
          <Typography variant='h3' className='titleNotFound'>
            {state ? 'page not found' : 'Please, you must log in again.'}
          </Typography>

          <div style={{marginTop: '20px'}}>
            <Button
              variant='contained'
              onClick={navigateHome}
              className='btnNotFound'
            >
              {state ? 'Go home' : 'Go back'}
            </Button>
          </div>
        </div>
      </div>
  )
}

export default PageNotFound;