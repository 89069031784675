import { GRAPH_DATA } from "../constants";

const initialState = {
    data: {}
}

const graphDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GRAPH_DATA:
            
            return {
                ...state,
                data: action.data
            }
    
        default:
            return state;
    }
}
export default graphDataReducer;