import { LOCATION_DETAIL } from "../constants";

const initialState = {
    data: {}
}

const locationDetailReducer = (state=initialState, action) => {
    switch (action.type) {
        case LOCATION_DETAIL:
            return{
                ...state,
                data: action.data
            }
    
        default:
            return state;
    }
}

export default locationDetailReducer;