// @vendors
import { Grid } from '@mui/material';

// @Style
import "./style.css";

const ListDetailLocation = ({ text }) => {
  return (
    <Grid item xs={6} md={4} lg={4} className='cont-item-lis'>
      <ul className='list-container'>
        <li>
          <p>{ text }</p>
        </li>
      </ul>
    </Grid>
  )
}

export default ListDetailLocation