// @vendors
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Locale from "date-fns/locale/en-US";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useNavigate } from "react-router-dom";

// @constants
import {
  CssTextField,
  ENTERMAX,
  ENTERMIN,
  ENTERONLYNUMBER,
  MINDATE,
  ONLYNUMBER,
  REQUIRED_FIELD,
  VALIDFORMATDATE,
} from "../../utils/constants-utils";
import { ROUTES } from "../../components/routes/constants";
import { listNumbers, listNumbersGuest } from "../../utils/data-json";
import {
  changeDate,
  handleUserKeyPress,
  MaxInitDate,
} from "../../utils/functions-utils";

// @style
import "./style-reserve.css";

const defaultValue = {
  arrival: "",
  departure: "",
  guests: "",
  bedroom: "",
  doublebed: "",
  locationType: "",
  employeeName: "",
  secondName: "",
  lastName: "",
  secondSurname: "",
  phone: "",
  nationality: "",
  idEmployee: "",
  originEmploy: "",
  birthDate: "",
};

const FormReserve = ({
  onSubmit,
  locationTypeState,
  locationType,
  isBtnDisabled,
  location,
  numberGuest,
  errorsGuests,
  user,
  handleChangeGuest,
  onNumberGuests,
  isValidToBirthDateGuest,
}) => {
  const navigate = useNavigate();
  const newDate = new Date();
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(minDate);
  const [numberGuests, setNumberGuests] = useState(false);
  const [hasPhone, setHasPhone] = useState(false);
  const [hasNationality, setHasNationality] = useState(false);
  const [hasDocument, setHasDocument] = useState(false);
  const [hasSecondName, setHasSecondName] = useState(false);
  const [hasSecondSurname, setHasSecondSurname] = useState(false);
  const [hasBirthDate, setHasBirthDate] = useState(false);

  const schema = yup.object().shape({
    arrival: yup
      .date()
      .nullable()
      .required(REQUIRED_FIELD)
      .min(new Date(1900, 11, 31, 23, 59, 29), MINDATE)
      .typeError(VALIDFORMATDATE),
    departure: yup
      .date()
      .nullable()
      .required(REQUIRED_FIELD)
      .min(new Date(1900, 11, 31, 23, 59, 29), MINDATE)
      .typeError(VALIDFORMATDATE),
    guests: numberGuests
      ? yup.string().nullable().required(REQUIRED_FIELD)
      : yup.string().nullable().notRequired(),
    bedroom: yup.string().nullable().notRequired(),
    doublebed: yup.string().nullable().notRequired(),
    locationType: yup.string().nullable().notRequired(),
    employeeName: yup.string().nullable().notRequired(),
    secondName: yup
      .string()
      .nullable()
      .notRequired()
      .min(3, ENTERMIN(3))
      .max(30, ENTERMAX(30))
      .transform((v) => (v ? v : null)),
    lastName: yup.string().nullable().notRequired(),
    secondSurname: yup
      .string()
      .nullable()
      .notRequired()
      .min(3, ENTERMIN(3))
      .max(30, ENTERMAX(30))
      .transform((v) => (v ? v : null)),
    phone: !hasPhone
      ? yup
          .string()
          .nullable()
          .required(REQUIRED_FIELD)
          .min(5, ENTERMIN(5))
          .max(15, ENTERMAX(15))
          .matches(ONLYNUMBER, ENTERONLYNUMBER)
      : yup.string().nullable().notRequired(),
    nationality: !hasNationality
      ? yup
          .string()
          .nullable()
          .required(REQUIRED_FIELD)
          .min(3, ENTERMIN(3))
          .max(30, ENTERMAX(30))
      : yup.string().nullable().notRequired(),
    idEmployee: !hasDocument
      ? yup
          .string()
          .nullable()
          .required(REQUIRED_FIELD)
          .min(5, ENTERMIN(5))
          .max(16, ENTERMAX(16))
      : yup.string().nullable().notRequired(),
    originEmploy: yup
      .string()
      .nullable()
      .required(REQUIRED_FIELD)
      .min(3, ENTERMIN(3))
      .max(30, ENTERMAX(30)),
    birthDate: !hasBirthDate
      ? yup
          .date()
          .nullable()
          .required(REQUIRED_FIELD)
          .min(new Date(1900, 11, 31, 23, 59, 29), MINDATE)
          .typeError(VALIDFORMATDATE)
      : null,
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValue,
    mode: "all",
    reValidateMode: "onChange",
  });

  const resetForm = () => {
    setValue("arrival", "");
    setValue("departure", "");
    setValue("guests", "");
    setValue("bedroom", "");
    setValue("doublebed", "");
    setValue("locationType", "");
    setValue("employeeName", "");
    setValue("secondName", "");
    setValue("lastName", "");
    setValue("secondSurname", "");
    setValue("phone", "");
    setValue("nationality", "");
    setValue("idEmployee", "");
    setValue("originEmploy", "");
    setValue("birthDate", "");
    setMinDate(null);
    setMaxDate(null);
  };

  const cancelReservation = () => {
    resetForm();
    navigate(ROUTES.LOCATION);
  };

  const lookSimpleRoom = watch("bedroom");
  const lookDoublebed = watch("doublebed");

  useEffect(() => {
    if (lookSimpleRoom > 0 && lookDoublebed > 0) {
      setNumberGuests(true);
    } else if (lookSimpleRoom > 1) {
      setNumberGuests(true);
    } else if (lookDoublebed > 1) {
      setNumberGuests(true);
    } else {
      setNumberGuests(false);
    }
  }, [lookDoublebed, lookSimpleRoom]);

  useEffect(() => {
    setValue("locationType", locationTypeState);
  }, [locationTypeState, setValue, user]);

  // validation of value that is get in the requestof the user in session
  const assignValueToField = (name, textParam) => {
    const value =
      textParam && textParam !== "string" && textParam !== "null"
        ? textParam
        : "";
    setValue(`${name}`, value);
  };

  const validValueForState = (setSate, info) => {
    const value =
      info && info !== "string" && info !== "null" && info !== 0 ? true : false;
    setSate(value);
  };

  useEffect(() => {
    if (user?.id) {
      validValueForState(setHasPhone, user?.phone);
      validValueForState(setHasNationality, user?.nationality);
      validValueForState(setHasDocument, user?.documentNumber);
      validValueForState(setHasSecondName, user?.secondName);
      validValueForState(setHasSecondSurname, user?.secondSurName);
      validValueForState(setHasBirthDate, user?.birthDate);
      assignValueToField("employeeName", user?.firtsName);
      assignValueToField("secondName", user?.secondName);
      assignValueToField("lastName", user?.surName);
      assignValueToField("secondSurname", user?.secondSurName);
      assignValueToField("phone", user?.phone);
      assignValueToField("nationality", user?.nationality);
      assignValueToField("idEmployee", user?.documentNumber);
      assignValueToField("birthDate", user?.birthDate);
    }
  }, [user]);

  const onchangedate = (val) => {
    setMinDate(val);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <h5 className="titleNumberGuest">User data</h5>

          <Grid item xs={12} md={4} lg={4}>
            <FormControl fullWidth>
              <CssTextField
                InputLabelProps={{ shrink: true }}
                {...register("employeeName")}
                control={control}
                type="text"
                name="employeeName"
                className="inputFormDisabled"
                defaultValue={user?.firtsName}
                disabled
                variant="outlined"
                fullWidth
                label="First Name *"
                size="small"
                error={
                  errors.hasOwnProperty("employeeName") &&
                  errors["employeeName"].message
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <FormControl fullWidth>
              <CssTextField
                InputLabelProps={{ shrink: true }}
                {...register("secondName")}
                control={control}
                type="text"
                name="secondName"
                disabled={hasSecondName}
                className={hasSecondName ? "inputFormDisabled" : "inputForm"}
                defaultValue=""
                variant="outlined"
                fullWidth
                label="Second Name"
                size="small"
                error={
                  errors.hasOwnProperty("secondName") &&
                  errors["secondName"].message
                }
              />
              <FormHelperText style={{ color: "#f44336" }}>
                {errors.hasOwnProperty("secondName") &&
                  errors["secondName"].message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <FormControl fullWidth>
              <CssTextField
                InputLabelProps={{ shrink: true }}
                {...register("lastName")}
                control={control}
                type="text"
                name="lastName"
                className="inputFormDisabled"
                defaultValue=""
                disabled
                variant="outlined"
                fullWidth
                label="Last Name *"
                size="small"
                error={
                  errors.hasOwnProperty("lastName") &&
                  errors["lastName"].message
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <FormControl fullWidth>
              <CssTextField
                InputLabelProps={{ shrink: true }}
                {...register("secondSurname")}
                control={control}
                type="text"
                name="secondSurname"
                defaultValue=""
                disabled={hasSecondSurname}
                className={hasSecondSurname ? "inputFormDisabled" : "inputForm"}
                variant="outlined"
                fullWidth
                label="Second surname"
                size="small"
                error={
                  errors.hasOwnProperty("secondSurname") &&
                  errors["secondSurname"].message
                }
              />
              <FormHelperText style={{ color: "#f44336" }}>
                {errors.hasOwnProperty("secondSurname") &&
                  errors["secondSurname"].message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <FormControl fullWidth>
              <CssTextField
                InputLabelProps={{ shrink: true }}
                {...register("phone")}
                control={control}
                type="number"
                name="phone"
                disabled={hasPhone}
                className={hasPhone ? "inputFormDisabled" : "inputForm"}
                defaultValue=""
                variant="outlined"
                fullWidth
                label="Phone *"
                size="small"
                onKeyPress={handleUserKeyPress}
                error={
                  errors.hasOwnProperty("phone") && errors["phone"].message
                }
              />
            </FormControl>
            <FormHelperText style={{ color: "#f44336" }}>
              {errors.hasOwnProperty("phone") && errors["phone"].message}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <FormControl fullWidth>
              <CssTextField
                InputLabelProps={{ shrink: true }}
                {...register("nationality")}
                control={control}
                type="text"
                name="nationality"
                className={hasNationality ? "inputFormDisabled" : "inputForm"}
                defaultValue=""
                disabled={hasNationality}
                variant="outlined"
                fullWidth
                label="Nationality *"
                size="small"
                error={
                  errors.hasOwnProperty("nationality") &&
                  errors["nationality"].message
                }
              />
            </FormControl>
            <FormHelperText style={{ color: "#f44336" }}>
              {errors.hasOwnProperty("nationality") &&
                errors["nationality"].message}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <FormControl fullWidth>
              <CssTextField
                InputLabelProps={{ shrink: true }}
                {...register("idEmployee")}
                control={control}
                type="text"
                name="idEmployee"
                className={hasDocument ? "inputFormDisabled" : "inputForm"}
                defaultValue=""
                disabled={hasDocument}
                variant="outlined"
                fullWidth
                label="ID *"
                size="small"
                error={
                  errors.hasOwnProperty("idEmployee") &&
                  errors["idEmployee"].message
                }
              />
              <FormHelperText style={{ color: "#f44336" }}>
                {errors.hasOwnProperty("idEmployee") &&
                  errors["idEmployee"].message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <FormControl fullWidth>
              <LocalizationProvider
                locale={Locale}
                dateAdapter={AdapterDateFns}
              >
                <Controller
                  fullWidth
                  control={control}
                  name="birthDate"
                  size="small"
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      {...register("birthDate")}
                      variant="outlined"
                      allowSameDateSelection={true}
                      label="BirthDate *"
                      name="birthDate"
                      defaultValue={null}
                      value={value}
                      onChange={onChange}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <CssTextField
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          {...register("birthDate")}
                          name="birthDate"
                          size="small"
                          variant="outlined"
                          defaultValue={null}
                          className={
                            hasBirthDate ? "inputFormDisabled" : "inputForm"
                          }
                          disabled={hasBirthDate}
                          error={
                            errors.hasOwnProperty("birthDate") &&
                            errors["birthDate"].message
                          }
                        />
                      )}
                    />
                  )}
                />
                <FormHelperText style={{ color: "#f44336", paddingLeft: 0 }}>
                  {errors.hasOwnProperty("birthDate") &&
                    errors["birthDate"].message}
                </FormHelperText>
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <FormControl fullWidth>
              <CssTextField
                type="text"
                {...register("originEmploy")}
                control={control}
                InputLabelProps={{ shrink: true }}
                name="originEmploy"
                className="inputForm"
                defaultValue=""
                variant="outlined"
                fullWidth
                label="Hometown *"
                size="small"
                error={
                  errors.hasOwnProperty("originEmploy") &&
                  errors["originEmploy"].message
                }
              />
              <FormHelperText style={{ color: "#f44336" }}>
                {errors.hasOwnProperty("originEmploy") &&
                  errors["originEmploy"].message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <h5 className="titleNumberGuest">Reservation data</h5>

          <Grid item xs={12} md={4} lg={4}>
            <FormControl fullWidth>
              <LocalizationProvider
                locale={Locale}
                dateAdapter={AdapterDateFns}
              >
                <Controller
                  fullWidth
                  control={control}
                  name="arrival"
                  size="small"
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      {...register("arrival")}
                      variant="outlined"
                      allowSameDateSelection={true}
                      label="Check-in *"
                      name="arrival"
                      defaultValue={null}
                      onAccept={onchangedate}
                      onOpen={() => MaxInitDate(getValues, setMaxDate)}
                      value={value}
                      onChange={onChange}
                      minDate={new Date()}
                      maxDate={maxDate}
                      renderInput={(params) => (
                        <CssTextField
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          {...register("arrival")}
                          name="arrival"
                          size="small"
                          variant="outlined"
                          defaultValue={null}
                          className="inputForm"
                          error={
                            errors.hasOwnProperty("arrival") &&
                            errors["arrival"].message
                          }
                        />
                      )}
                    />
                  )}
                />
                <FormHelperText style={{ color: "#f44336", paddingLeft: 0 }}>
                  {errors.hasOwnProperty("arrival") &&
                    errors["arrival"].message}
                </FormHelperText>
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <FormControl fullWidth>
              <LocalizationProvider
                locale={Locale}
                dateAdapter={AdapterDateFns}
              >
                <Controller
                  fullWidth
                  control={control}
                  name="departure"
                  size="small"
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      {...register("departure")}
                      variant="outlined"
                      label="Check-out *"
                      name="departure"
                      defaultValue={null}
                      onAccept={(e) =>
                        changeDate(e, getValues, setMaxDate, newDate)
                      }
                      minDate={minDate ? minDate : new Date()}
                      value={value}
                      onChange={(newValue) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <CssTextField
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          {...register("departure")}
                          name="departure"
                          size="small"
                          value={value}
                          defaultValue={null}
                          className="inputForm"
                          error={
                            errors.hasOwnProperty("departure") &&
                            errors["departure"].message
                          }
                        />
                      )}
                    />
                  )}
                />
                <FormHelperText style={{ color: "#f44336", paddingLeft: 0 }}>
                  {errors.hasOwnProperty("departure") &&
                    errors["departure"].message}
                </FormHelperText>
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={
                errors.hasOwnProperty("locationType") &&
                errors["locationType"].message
              }
            >
              <InputLabel id="outlined-select-locationType" shrink={true}>
                Location Type
              </InputLabel>
              <Controller
                fullWidth
                control={control}
                {...register("locationType")}
                size="small"
                name="locationType"
                defaultValue={locationTypeState}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="locationType"
                    {...register("locationType")}
                    notched={true}
                    className="inputForm"
                    value={value}
                    onChange={onChange}
                    disabled
                    inputProps={{
                      name: "locationType",
                      id: "outlined-select-locationType",
                    }}
                  >
                    {locationType?.map((resp) => (
                      <MenuItem
                        className="menuItemSelelct"
                        key={resp?.name}
                        value={resp.name}
                      >
                        {resp?.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText style={{ color: "#f44336", paddingLeft: 0 }}>
                {errors.hasOwnProperty("locationType") &&
                  errors["locationType"].message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={
                numberGuests
                  ? errors.hasOwnProperty("guests") && errors["guests"].message
                  : null
              }
            >
              <InputLabel id="outlined-select-guests" shrink={true}>
                Guests
              </InputLabel>
              <Controller
                fullWidth
                control={control}
                {...register("guests")}
                size="small"
                name="guests"
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="Guests"
                    {...register("guests")}
                    notched={true}
                    className="inputForm"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      onNumberGuests(e);
                    }}
                    inputProps={{
                      name: "guests",
                      id: "outlined-select-guests",
                    }}
                  >
                    {listNumbers?.map((item) => (
                      <MenuItem
                        className="menuItemSelelct"
                        key={item}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText style={{ color: "#f44336", paddingLeft: 0 }}>
                {numberGuests
                  ? errors.hasOwnProperty("guests") && errors["guests"].message
                  : null}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={
                errors.hasOwnProperty("bedroom") && errors["bedroom"].message
              }
            >
              <InputLabel id="outlined-select-bedroom" shrink={true}>
                Double Accommodation
              </InputLabel>
              <Controller
                fullWidth
                control={control}
                {...register("bedroom")}
                size="small"
                name="bedroom"
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="Double Accommodation"
                    {...register("bedroom")}
                    notched={true}
                    className="inputForm"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    inputProps={{
                      name: "bedroom",
                      id: "outlined-select-bedroom",
                    }}
                  >
                    {listNumbersGuest?.map((item) =>
                      item.id <= location?.bedrooms ? (
                        <MenuItem
                          className="menuItemSelelct"
                          key={item?.id}
                          value={item?.id}
                        >
                          {item?.value}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                )}
              />
              <FormHelperText style={{ color: "#f44336", paddingLeft: 0 }}>
                {errors.hasOwnProperty("bedroom") && errors["bedroom"].message}
              </FormHelperText>
            </FormControl>
          </Grid>

          {locationTypeState === "Family" ? (
            <Grid item xs={12} md={4} lg={4}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                error={
                  errors.hasOwnProperty("doublebed") &&
                  errors["doublebed"].message
                }
              >
                <InputLabel id="outlined-select-doublebed" shrink={true}>
                  Multiple Accommodation
                </InputLabel>
                <Controller
                  fullWidth
                  control={control}
                  {...register("doublebed")}
                  size="small"
                  name="doublebed"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label="Multiple Accommodation"
                      {...register("doublebed")}
                      notched={true}
                      className="inputForm"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      inputProps={{
                        name: "doublebed",
                        id: "outlined-select-doublebed",
                      }}
                    >
                      {listNumbersGuest?.map((item) =>
                        item.id <= location?.doubleRooms ? (
                          <MenuItem
                            className="menuItemSelelct"
                            key={item?.id}
                            value={item.id}
                          >
                            {item?.value}
                          </MenuItem>
                        ) : null
                      )}
                    </Select>
                  )}
                />
                <FormHelperText style={{ color: "#f44336", paddingLeft: 0 }}>
                  {errors.hasOwnProperty("doublebed") &&
                    errors["doublebed"].message}
                </FormHelperText>
              </FormControl>
            </Grid>
          ) : null}

          {numberGuest?.length
            ? numberGuest.map((guest, index) => (
                <Grid container spacing={2} style={{ margin: 0 }}>
                  <h4 className="titleNumberGuest">{`Information guest number ${
                    index + 1
                  }`}</h4>
                  <Grid item xs={12} md={4} lg={4}>
                    <FormControl fullWidth>
                      <CssTextField
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        name="name"
                        className="inputForm"
                        value={guest.name}
                        onChange={(e) => handleChangeGuest(index, e, "name")}
                        variant="outlined"
                        fullWidth
                        label="Full Name Guest *"
                        size="small"
                      />
                      <FormHelperText style={{ color: "#f44336" }}>
                        {errorsGuests[`guest[${index}]name`] &&
                          errorsGuests[`guest[${index}]nameMessage`]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} lg={4}>
                    <FormControl fullWidth>
                      <CssTextField
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        name="documentNumber"
                        className="inputForm"
                        value={guest.documentNumber}
                        onChange={(e) => handleChangeGuest(index, e, "idGuest")}
                        variant="outlined"
                        fullWidth
                        label="ID Guest *"
                        size="small"
                      />
                      <FormHelperText style={{ color: "#f44336" }}>
                        {errorsGuests[`guest[${index}]documentNumber`] &&
                          errorsGuests[`guest[${index}]documentNumberMessage`]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} lg={4}>
                    <FormControl fullWidth>
                      <CssTextField
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        name="nationality"
                        className="inputForm"
                        value={guest.nationality}
                        onChange={(e) =>
                          handleChangeGuest(index, e, "nationality")
                        }
                        variant="outlined"
                        fullWidth
                        label="Nationality *"
                        size="small"
                      />
                      <FormHelperText style={{ color: "#f44336" }}>
                        {errorsGuests[`guest[${index}]nationality`] &&
                          errorsGuests[`guest[${index}]nationalityMessage`]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} lg={4}>
                    <FormControl fullWidth>
                      <CssTextField
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        name="origin"
                        className="inputForm"
                        value={guest.origin}
                        onChange={(e) => handleChangeGuest(index, e, "origin")}
                        variant="outlined"
                        fullWidth
                        label="Hometown *"
                        size="small"
                      />
                      <FormHelperText style={{ color: "#f44336" }}>
                        {errorsGuests[`guest[${index}]origin`] &&
                          errorsGuests[`guest[${index}]originMessage`]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} lg={4}>
                    <FormControl fullWidth>
                      <LocalizationProvider
                        locale={Locale}
                        dateAdapter={AdapterDateFns}
                      >
                        <DatePicker
                          variant="outlined"
                          allowSameDateSelection={true}
                          label="BirthDate *"
                          name="birthDateGuests"
                          value={guest?.birthDate}
                          onChange={(e) =>
                            isValidToBirthDateGuest(index, e, "birthDate")
                          }
                          maxDate={new Date()}
                          renderInput={(params) => (
                            <CssTextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              name="birthDate"
                              size="small"
                              variant="outlined"
                              className="inputForm"
                              error={
                                errorsGuests[
                                  `guest[${index}]birthDateGuests`
                                ] &&
                                errorsGuests[
                                  `guest[${index}]birthDateGuestsMessage`
                                ]
                              }
                            />
                          )}
                        />
                        <FormHelperText
                          style={{ color: "#f44336", paddingLeft: 0 }}
                        >
                          {errorsGuests[`guest[${index}]birthDateGuests`] &&
                            errorsGuests[
                              `guest[${index}]birthDateGuestsMessage`
                            ]}
                        </FormHelperText>
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                </Grid>
              ))
            : null}

          <Grid item xs={12} md={12} lg={12}>
            <Button
              variant="contained"
              type="submit"
              className="btnReserveForm"
              disabled={isBtnDisabled}
            >
              Book
            </Button>
            <Button
              variant="contained"
              className="btnCancelReserve"
              onClick={cancelReservation}
            >
              cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default FormReserve;
