import HttpService from "../services/http-client";
import { CREDENTIAL, HEADERS } from "./contants";

export const getCredential = () => {
  return new Promise((resolve) => {
    HttpService.get(`${CREDENTIAL}`, HEADERS)
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        resolve(e?.response);
      });
  });
};

export const testNewCredential = () => {
  return new Promise((resolve) => {
    HttpService.get(`${CREDENTIAL}/test`, HEADERS)
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        resolve(e?.response);
      });
  });
};

export const updatedCredential = (id, body) => {
  return new Promise((resolve) => {
    HttpService.put(`${CREDENTIAL}/${id}`, body, HEADERS)
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        resolve(e?.response);
      });
  });
};
