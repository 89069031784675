// @vendors
import React from 'react';
import {
    Button,
    FormControl,
    FormHelperText,
    Grid
} from '@mui/material';

// @constants
import { CssTextField} from '../../../utils/constants-utils';

// @style
import './country.css';
import '../../../App.css';

const FormCountry = ({
  onSubmit,
  handleClickCancel,
  register,
  handleSubmit,
  control,
  errors,
  handleClickCancelForm
}) => {

  return (
    <div style={{marginTop: '10px'}}>
         <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>

            <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth>
                    <CssTextField
                      InputLabelProps={{shrink: true}}
                      {...register('name')}
                      control={control}
                      type="text"
                      name='name'
                      className='inputForm'
                      defaultValue=""
                      variant="outlined"
                      fullWidth
                      label="Name *"
                      size="small"
                      error={errors.hasOwnProperty("name") && errors["name"].message}
                    />
                    <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                      {errors.hasOwnProperty("name") && errors["name"].message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth>
                    <CssTextField
                      InputLabelProps={{shrink: true}}
                      {...register('code')}
                      control={control}
                      type="text"
                      name='code'
                      className='inputForm'
                      defaultValue=""
                      variant="outlined"
                      fullWidth
                      label="IATA Code *"
                      size="small"
                      error={errors.hasOwnProperty("code") && errors["code"].message}
                    />
                    <FormHelperText style={{color: "#f44336", paddingLeft: 0}}>
                      {errors.hasOwnProperty("code") && errors["code"].message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} lg={12} className='contBtnModals'>
                    <Button
                        variant='contained'
                        onClick={handleClickCancelForm}
                        className='btnSubmitModal'
                    >
                        clean up
                    </Button>

                    <Button
                        variant='contained'
                        type='submit'
                        className='btnSubmitModal'
                    >
                        save
                    </Button>

                </Grid>

            </Grid>
        </form>
    </div>
  )
}

export default FormCountry