// @vendors
import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const BackdropLoading = () => {
  const isOpen = useSelector((state) => state.backdropLoading.isOpenBackdrop);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default BackdropLoading;
