export const headers = {
    initialHeaders:[
        {title: 'Names', field: 'names', child: null},
        {title: 'Last Names', field: 'lastnames', child: null},
        {title: 'Location Name', field: 'location', child: null},
        {title: 'Status', field: 'status', child: null},
        {title: 'Check-in', field: 'from', child: null},
        {title: 'Check-out', field: 'to', child: null},
    ],
    otherHeaders:[
        {title: 'BirthDate', field: 'birthDate', child: null},
        {title: 'Phone', field: 'phone', child: null},
        {title: 'Nationality', field: 'nationality', child: null},
        {title: 'ID', field: 'idEmploy', child: null},
        {title: 'Origin', field: 'origin', child: null},
        {title: 'Location City', field: 'city', child: null},
        {title: 'Request Date', field: 'requestday', child: null},
        {title: 'No. Nights', field: 'days', child: null},
        {title: 'No. People', field: 'guests', child: null},
        {title: 'Is Group', field: 'isGroup', child: null}
    ]
}