import {MSAL_DATA } from "../constants";
const initialState = {
    config: {}
}

const msalReducer = (state = initialState, action) => {
    switch (action?.type) {
        case MSAL_DATA:
            return {
                ...state,
                config: action?.config
            };
            
        default:
            return state;
    }
}

export default msalReducer;