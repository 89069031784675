// vendors
import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// style
import './updated-gallery.css';
import {
    addGalleryForLocation,
    deletePhotoForGallery,
    updatedPhoto
} from '../../../actions/photo-action';
import { useState } from 'react';

// @actions

const UpdatedGalleryModal = ({
    open,
    onClose,
    gallery,
    setGallery,
    idLocation,
    lookingForImages
}) => {

    const [image, setImage] = useState({});

    const deleteImageGallery = async (img) => {
        const response = await deletePhotoForGallery(img?.id);
        if(response?.status === 200 || response?.status === 201){
            const newList = gallery.filter((x) => x?.id !== img?.id);
            setGallery(newList);
        }
    }

    const handleClickNewPhoto = (type) => {
        const input = document.getElementById(type ? "new-image-update" : "edit-image-gallery");
        input.click();
    }

    const handleClickImage = (e) => {
        const files = e.target.files;
        addImageToGallery(files[0]);
    }

    const addImageToGallery = async (image) => {
        const formData = new FormData();
        formData.append("LocationId", idLocation);
        formData.append("FormFiles", image);
        const response = await addGalleryForLocation(formData);
        if(response?.status === 200 || response?.status === 201)lookingForImages(idLocation);
    }

    const handleEditImage = (image) => {
        setImage(image);
        handleClickNewPhoto(false);
    }

    const handleClickEditImage = (e) => {
        const files = e.target.files;
        const formData = new FormData();
        formData.append("LocationId", idLocation);
        formData.append("IsMain", false);
        formData.append("FormFile", files[0]);
        updatedImageGallery(formData);
    }

    const updatedImageGallery = async (file) => {
        const response = await updatedPhoto(image?.id, file);
        if(response?.status === 200 || response?.status === 201)lookingForImages(idLocation);
    }

  return (
    <div>
        <Dialog
            open={open}
            style={{background: '#243747 !important'}}
            fullWidth={true}
            maxWidth='md'
        >
            <DialogTitle className='dialogTitleModalGallery'>
                <Grid container className='titleModalGallery'>
                    <Typography variant='h6' style={{fontWeight: 'bold', textTransform: 'uppercase', fontSize: 15}}>
                        Update gallery
                    </Typography>
                </Grid>
            </DialogTitle>

            <DialogContent className='dialogContentModalGallery'>
                <IconButton
                    onClick={() => handleClickNewPhoto(true)}
                    className='iconAddImage'
                >
                    <Tooltip title="Add Image">
                        <AddCircleIcon className='iconAddImageGallery'/>
                    </Tooltip>
                </IconButton>
                <div className='containMianModalUpdated'>
                    {gallery?.map((img) => (
                        <div className='contSecondaryModalUpdated'>
                            <div className='divImgModalUpdate'>
                                <img src={img?.photo} alt='image_gallery' className='imgModalUpdated'/>
                                <div className='contIconsModalUpdated'>
                                    <Tooltip title="Edit image">
                                        <IconButton 
                                            className='iconEditGallery'
                                            onClick={() => handleEditImage(img)}
                                        >
                                            <EditIcon className='iconDeleteModalGallery'/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete image">
                                        <IconButton 
                                            className='iconEditGallery'
                                            onClick={() => deleteImageGallery(img)}
                                        >
                                            <DeleteIcon className='iconDeleteModalGallery' />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </DialogContent>

            <DialogActions className='contBtnCloceModal'>
                <Button
                    variant="contained"
                    className='btnCloceModalUpdated'
                    onClick={onClose}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
        <input 
            type="file"
            name="newImage"
            id="edit-image-gallery"
            onChange={handleClickEditImage}
            accept=".png, .jpg, .jpeg"
            style={{ display:"none" }}
        />
        <input 
            type="file"
            name="newImage"
            id="new-image-update"
            onChange={handleClickImage}
            accept=".png, .jpg, .jpeg"
            style={{ display:"none" }}
        />
    </div>
  )
}

export default UpdatedGalleryModal;