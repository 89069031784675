// @vendors
import React, {useState} from 'react';
import { Divider, Menu, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// @style
import './notification.css';

// @components
import CardNotification from './card-notification';
import { getNotification, updateNotification } from '../../../actions/notification-action';
import { NOTIFICATION_DATA } from '../../../redux/constants';

const ModalNotification = ({open, handleClose, anchorEl}) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const notifications = state?.notificationReducer?.notification;
  const userSsesion = state?.userReducer?.userData;
  const [checked, setChecked] = useState('')

  const handleClickCheckNotification = (e, data) => {
    setChecked(e.target.value);
    if(e.target.checked){
      markNotificationAsRead(data)
    }
  }

  const markNotificationAsRead = async (data) => {
    const newData = {
      employeeId: data.employeeId,
      message: data.message,
      read: true,
      active: true
    }
    const response = await updateNotification(data?.id, newData);
    if(response?.status === 200 || response?.status === 201){
      getNotificationById();
    }
  }

  const getNotificationById = async () => {
    const paginador = {page:0, size:100}
    const response = await getNotification(userSsesion.id, paginador);
    const data = response?.data?.notifications.resource;
    dispatch({
      type: NOTIFICATION_DATA,
      notification: data
    });
  }

  return (
    <div>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            //maxHeight: 300,
            minHeight: 180,
            width: '40ch',
            borderRadius: 10
          },
        }}
      >
        <div className='contNotification'>
          <h4 className='titleNotification' onClick={handleClose} >Notifications</h4>

          <Divider/>

          {notifications?.length > 0
            ? notifications?.map((notification,index) => (
                <CardNotification
                  key={ index }
                  data={notification}
                  handleClickCheckNotification={handleClickCheckNotification}
                  checked={checked}
                />
              ))
            : <div className='containerNoNotification'>
                <Typography className='textNoNotification'>You have no unread notifications</Typography>
            </div> 
          }
    
        </div>
      </Menu>
    </div>
  )
}

export default ModalNotification