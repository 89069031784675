// @vendors
import React, {useState, useEffect} from 'react';
import { Typography } from '@mui/material';
import CameraIcon from '@mui/icons-material/CameraAltOutlined'
import { useParams } from 'react-router-dom';

// @style
import '../style-add-location.css';

// @action
import { getGalleryByLocation } from '../../../actions/photo-action';

const AddImage = ({setMainPhoto}) => {
    const [img, setImg] = useState(null);
    const [updateImage, setUpdateImage] = useState({});

  const { idLocation } = useParams();

    const handleImage = async (e) => {  
      const files = e.target.files;
      createPreview(files);

      if(idLocation){
        setMainPhoto({id:updateImage?.id, image:files[0]});
      }else{
        setMainPhoto(files[0]);
      }
    }

    const createPreview = (files) => {
        const imgCodified = URL.createObjectURL(files[0])
        setImg(imgCodified) 
    }
    
    const handlePhoto = () => {
        const add_photo = document.getElementById('add_photo')
        add_photo.click();
    }

    const lookingForImages = async (id) => {
        const response = await getGalleryByLocation(id);
        const data = response?.data?.find(({ isMain })=>isMain);
        setUpdateImage(data);
        if(data?.id){
          setImg(data?.photo);
          setMainPhoto({id: data?.id, image: data?.photo})
        }
    };

    useEffect(() => {
        if(idLocation){
          lookingForImages(idLocation);
        } 
    }, [idLocation]);

  return (
    <>
      <Typography variant='body1' style={ { fontSize: '14px' } }>
        Thumbnail
      </Typography>
    
      <input 
        type='file' 
        onChange={ handleImage } 
        id='add_photo' 
        style={{ display: 'none' }}
        accept={['image/png', 'image/jpeg', 'image/jpg']} 
      />
      <div className='addImage' id='see_photo_container' onClick={handlePhoto}>
        { img !== null 
          ? 
            <figure className='image-container'>
              <img src={img} alt='img' id='see_photo'  className='imageAddLocation'/>   
            </figure>
          :
            <CameraIcon style={{color: '#5f788d', fontSize: '60px'}}/>
        }
      </div>
    </>
  )
}

export default AddImage