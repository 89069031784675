import { ICON_REDUCER } from "../constants";

const initialState = {
    index: 0,
}

const iconReducer = (state = initialState, action) => {
    switch (action.type) {
        case ICON_REDUCER:
            return {
                ...state,
                index: action.index
            }
    
        default:
            return state;
    }
}

export default iconReducer;