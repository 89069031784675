// @vendors
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// @components
import FormReserve from "./form-reserve";

// @style
import "./style-reserve.css";

// @constants
import { keepInMnd } from "../../utils/data-json";
import ConfirmationMessage from "../../utils/modal-confirm/confirm-message";
import {
  areOlderDatesToCurrentDate,
  handleOpenBackdrop,
  verifyFileToDowload,
} from "../../utils/functions-utils";
import { ROUTES } from "../../components/routes/constants";

// @actions
import { locationById } from "../../actions/location-action";
import { newReservation } from "../../actions/reservations-action";

// @hooks
import { useModalMessage } from "../../hooks/use-modal-message";
import { useUpdatedEmployee } from "../../hooks/use-updated-employee";
import { useMessageRequest } from "../../hooks/use-message-request";
import { useListGuest } from "../../hooks/use-list-guests";

const Reserve = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { idLocation } = useParams();
  const user = state?.userReducer.userData;
  const locationDetail = state?.locationDetailReducer.data;
  const [locationTypeState, setLocationTypeState] = useState("");
  const locationType = state?.locationTypeReducer.types;
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [location, setLocation] = useState({});
  const { message, handleClickClose, openMessage, openMessageWarning } =
    useModalMessage();
  const { updatedEmploy } = useUpdatedEmployee();
  const { messageByRequest } = useMessageRequest();
  const {
    handleChangeGuest,
    numberGuest,
    errorsGuests,
    onNumberGuests,
    isValidToBirthDateGuest,
  } = useListGuest();

  useEffect(() => {
    if (locationDetail?.type) {
      setLocationTypeState(locationDetail.type);
      setLocation(locationDetail);
    } else {
      const getLocationById = async () => {
        const response = await locationById(idLocation);
        setLocationTypeState(response?.data.type);
        setLocation(response?.data);
      };
      getLocationById();
    }
  }, [idLocation, locationDetail]);

  const onSubmit = (data) => {
    const guests = data.guests ? parseInt(data.guests) : 0;
    const simpleBed = data.bedroom ? parseInt(data.bedroom) : 0;
    const doubleBed = data.doublebed ? parseInt(data.doublebed) : 0;

    if (doubleBed === 0 && simpleBed === 0) {
      const text = "Enter the number of rooms required";
      openMessageWarning(text, "Try Again");
      return;
    }
    if (guests === 0 && doubleBed > 0 && simpleBed > 0) {
      const text = "Select a valid number of guests";
      openMessageWarning(text, "Try Again");
      return;
    }
    if (guests === 0 && (doubleBed > 1 || simpleBed > 1)) {
      const text = "Select a valid number of guests";
      openMessageWarning(text, "Try Again");
      return;
    }
    if (locationTypeState === "Adults" && guests + 1 < simpleBed) {
      const text = "Select only required rooms";
      openMessageWarning(text, "Try Again");
      return;
    }
    if (locationTypeState === "Family" && guests + 1 < simpleBed + doubleBed) {
      const text = "Select only required rooms";
      openMessageWarning(text, "Try Again");
      return;
    }
    if (guests + 1 > simpleBed * 2 + doubleBed * 3) {
      const text = "Select the necessary rooms for all the guests";
      openMessageWarning(text, "Try Again");
      return;
    }
    const date = areOlderDatesToCurrentDate(
      data?.arrival,
      data?.departure,
      openMessageWarning
    );
    if (date) return;
    else addGuestInBook(data);
  };

  const validDataGuest = () => {
    const bool = numberGuest?.map((guest) => {
      let isValid = false;
      if (
        guest.name === "" ||
        guest.documentNumber === "" ||
        guest.nationality === "" ||
        guest.origin === "" ||
        guest.birthDate === null
      ) {
        isValid = true;
      } else {
        isValid = false;
      }
      return isValid;
    });
    return bool;
  };

  //checks if any error property is true and returns it
  const validListError = (obj) => {
    for (const key in obj) {
      if (obj[key] === true) return true;
    }
  };

  const addGuestInBook = (data) => {
    const validErr = validListError(errorsGuests);
    if (validErr) return;

    if (numberGuest.length) {
      const isVal = validDataGuest();
      const findBool = isVal.find((x) => x === true) ?? false;
      if (findBool) {
        const text = "All fields for guests are required";
        openMessageWarning(text, "Try Again");
        return;
      }
    }
    nextOnSubmit(data);
  };

  const nextOnSubmit = (data) => {
    const newListGuest = numberGuest?.map((guest) => {
      return { ...guest, birthDate: moment(guest.birthDate).toISOString() };
    });
    const initDate = moment(data.arrival).format("YYYY-MM-DD");
    const endDate = moment(data.departure).format("YYYY-MM-DD");
    const newData = {
      locationId: idLocation,
      employeeId: user?.id,
      arrival: `${initDate}T14:00:00.000Z`,
      departure: `${endDate}T11:00:00.000Z`,
      guests: data.guests ? data.guests : 0,
      numberBedrooms: data.bedroom ? data.bedroom : 0,
      numberDoubleRooms: data.doublebed ? data.doublebed : 0,
      status: "Pending",
      description: null,
      origin: data?.originEmploy,
      active: true,
      reservationGuest: newListGuest,
    };
    const msj = "Are you sure to make this reservation?";
    openMessage(msj, sendReservation, newData);
    updatedEmploy(data);
  };

  const sendReservation = async (info) => {
    handleOpenBackdrop(dispatch, true);
    setIsBtnDisabled(true);
    handleClickClose();
    const response = await newReservation(info);
    setTimeout(() => {
      if (response?.status === 200 || response?.status === 201)
        verifyFileToDowload(idLocation);
      messageByRequest(response, openMessageWarning, "post", navigateTo);
    }, 1000);
    setIsBtnDisabled(false);
    handleOpenBackdrop(dispatch, false);
  };

  const navigateTo = () => {
    navigate(ROUTES.MYRESERVATION);
  };

  return (
    <div className="rootReserve">
      <div className="containerBody">
        <div className="containerPrimaryReserve">
          <div className="contSecondaryReserve">
            <div className="containerFormKeepMind">
              <div className="divKeepInMind">
                <h5 className="titleKeepMind">Keep in mind</h5>
                {keepInMnd.map((item) => (
                  <ul className="listKeepMind">
                    <li>
                      {" "}
                      <p> {item.value} </p>{" "}
                    </li>
                  </ul>
                ))}
              </div>

              <div className="contFormReserve">
                <FormReserve
                  onSubmit={onSubmit}
                  locationTypeState={locationTypeState}
                  locationType={locationType}
                  isBtnDisabled={isBtnDisabled}
                  location={location}
                  numberGuest={numberGuest}
                  errorsGuests={errorsGuests}
                  user={user}
                  handleChangeGuest={handleChangeGuest}
                  onNumberGuests={onNumberGuests}
                  isValidToBirthDateGuest={isValidToBirthDateGuest}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationMessage
        description={message.description}
        textButtonSubmit={message.textButtonSubmit}
        handleClick={message.handleClick}
        handleClickOut={message.handleClickOut}
        oneButtons={message.oneButtons}
        type={message.type}
        open={message.open}
      />
    </div>
  );
};

export default Reserve;
