// @vendors
import { useState } from "react";

export const useModalMessage = () => {

    const [message, setMessage] = useState({
        description: "",
        textButtonSubmit: "",
        handleClick: () => {}, 
        handleClickOut: () => {},
        oneButtons: false, 
        type: "",
        open: false,
    });

    const handleClickClose = (funct) => {
        setMessage({...message, open: false});
        if(funct)funct();
    }

    const openMessage = (description, handleClick, info) => {
        setMessage({
            description: description,
            textButtonSubmit: "Accept",
            handleClick: () => handleClick(info), 
            handleClickOut: () => handleClickClose(),
            type: "QUESTION",
            open: true,
        });
    }

    const openMessageWarning = (description, titleBtn, type, funct) => {
        setMessage({
            description: description,
            textButtonSubmit: titleBtn ? titleBtn : "Accept",
            handleClick: () => handleClickClose(funct),
            type: type ? type : "WARNING",
            oneButtons: true,
            open: true,
        });
    }

    return {
        openMessage,
        openMessageWarning,
        handleClickClose,
        message,
        setMessage
    }
}