import React from "react";
import SuccessIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import WarningIcon from "@mui/icons-material/ErrorOutlineOutlined";
import QuestionIcon from "@mui/icons-material/HelpOutlineOutlined";
import ErrorIcon from "@mui/icons-material/CancelOutlined";
import { Button, Modal } from "@mui/material";
import './confirm-message.css';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ConfirmationMessage = ({
  description,
  open,
  handleClick,
  handleClickOut,
  type,
  title,
  oneButtons,
  textButtonSubmit
}) => {
  
  const [modalStyle] = React.useState(getModalStyle);
  //const type = 'WARNING'
  const iconsAlert = (type) => {
    if (type === "WARNING" || type === "ISEMPTY")
      return  (<WarningIcon style={{ width: "25px", color: "#FFA726", height: "25px" }}/>)
    if (type === "QUESTION")
      return (<QuestionIcon style={{ width: "25px", color: "#5f788d", height: "25px" }}/>)
    if (type === "SUCCESS")
      return ( <SuccessIcon style={{ width: "25px", color: "#5CE706", height: "25px" }}/>);
    if (type === "ERROR")
      return ( <ErrorIcon style={{ width: "25px", color: "#F44336", height: "25px" }}/>);
  };

  const renderButtons = (
    oneButtons,
    textButtonSubmit,
    handleClick,
    handleClickOut,
  ) => {
    if (oneButtons) {
      return (
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={ handleClick }
          className='oneBtnConfirm'
        >
          {textButtonSubmit ? textButtonSubmit : "Aceptar"}
        </Button>
      );
    }else{
      return(
        <div className="containerBtnConfirm">
          <Button
            type={oneButtons ? "button" : "submit"}
            variant="contained"
            color="secondary"
            onClick={handleClick}
            className='btnConfirmMessage'
          >
            {textButtonSubmit ? textButtonSubmit : "Aceptar"}
          </Button>
          <Button
            type="button"
            variant="contained"
            onClick={handleClickOut}
            className='btnConfirmMessage'
          >
            Cancel
          </Button>
        </div>
      )
    }
  };

  const bodyModal = (
    <div style={ modalStyle } className='paper'>
      { type !== 'QUESTION' 
        ? <div className="containerIconAlert">
            { iconsAlert(type) }
              <h4 className="titleConfirmMessage">
                {type === 'WARNING' 
                  ? 'Oops...' 
                  : type === 'SUCCESS'
                  ? 'Congratulations!'
                  : type === 'ISEMPTY'
                  ? 'Complete the information'
                  : 'Something went wrong' 
                }
              </h4>
          </div>
        : null
      }
      { title ? <h1 className='titleAlert'> { title } </h1> : null }
      <p className='text'>{ description }</p>
      <div className='divBtnConfirmMessage'>
        {renderButtons(
          oneButtons,
          textButtonSubmit,
          handleClick,
          handleClickOut,
        )}
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        style={{ textAlign: "center" }}
        open={ open }
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        { bodyModal }
      </Modal>
    </div>
  );
}

export default ConfirmationMessage