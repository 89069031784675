import HttpClient from '../services/http-client';
import { FEATURES, HEADERS } from './contants';

export const getFeatures = () => {
    return new Promise((resolve) => {
        HttpClient.get(`${FEATURES}`, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}

export const saveFeatures = (body) => {
    return new Promise((resolve) => {
        HttpClient.post(`${FEATURES}`,body, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}