// @vendors
import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Grid, TablePagination } from '@mui/material';
import moment from 'moment';

// @constants
import { ICON_REDUCER } from '../../redux/constants';
import Reservation from './reservation';
import { getRequestErrorMessage } from '../../utils/functions-utils';

// @style
import '../../App.css';

// @components
import Empty from '../../components/empty/empty';
import ConfirmationMessage from '../../utils/modal-confirm/confirm-message';
import Loading from '../../components/loading/loading';

// @action
import { geReservationHistory, getMyReservationById } from '../../actions/reservations-action';

// @hooks
import { useModalMessage } from '../../hooks/use-modal-message';
import { MESSAGEERROR } from '../../utils/constants-utils';

const MyReservation = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const user = state?.userReducer.userData;
  const today = moment().format('YYYY-MM-DDT00:00:00Z');
  const [loading, setLoading] = useState(false);
  const [myReservation, setMyReservation] = useState([]);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const {message, handleClickClose, openMessage, openMessageWarning} = useModalMessage();

  const [paginatorRequest, setPaginatorRequest] = useState({
    page: 0,
    size: 6
  })

  const [paginator, setPaginator] = useState({
    responseData: [],
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0 
  })

  const changeNumberrecords = (event ) => {
    setPaginatorRequest((anterior) => ({
    ...anterior,
    size: parseInt(event.target.value, ),
    page: 0,
    }));
  }

  const changePage = (event, newPage) => {
    setPaginatorRequest((anterior) => ({
    ...anterior,
    page:  parseInt(newPage),
    }));
  }

  useEffect(() => {
    dispatch({
      type: ICON_REDUCER,
      index: 3
    })
  }, [dispatch])

  const getMyReservation = async () => {
    const response = await getMyReservationById(user?.id, today);
    if(response?.status === 200 || response?.status === 201){
      const data = response?.data;
      const filterData = data?.filter(x => x.description !== 'Block for admin');
      setMyReservation(filterData);
    }else openMessageWarning(MESSAGEERROR, 'Try Again');
  }

  useEffect(() => {
    getMyReservation();
  }, [])

  const getAllReservationHistory = async () => {
    setLoading(true);
    const filter = {idEmployee: user?.id, today: today};
    const response = await geReservationHistory(filter, paginatorRequest);
    if(response?.status === 200 || response?.status === 201){
      const data = response?.data?.reservations;
      const dataFil = data?.resource;
      const filterData = dataFil?.filter(x => x.description !== 'Block for admin');
      setPaginator({
        responseData: filterData,
        pageNumber: data?.pageNumber,
        pageSize: data?.pageSize,
        totalPages:data?.totalPages,
        totalRecords: data?.totalRecords,
      })
      setLoading(false);
    }else if(response?.status > 399 && response?.status !== 401){
      setLoading(false);
      getRequestErrorMessage(setPaginator, openMessageWarning, 'WARNING');
    }else{
      setLoading(false);
      getRequestErrorMessage(setPaginator, openMessageWarning, 'WARNING');
    }
  }
  
  useEffect(() => {
    getAllReservationHistory();
  }, [paginatorRequest])

  return (
    <div className='rootGeneral'>
      <Grid>
        <Grid container style={{margin: '0 0 30px 0'}}>
          <h3 className='titleMain'>My Current Reservations</h3>
        </Grid>

        {paginator?.responseData?.length > 0 || myReservation?.length > 0
          ? <Grid>
              <Grid>
                {myReservation?.length > 0
                  ? myReservation?.map((row)=> (
                      <Reservation 
                        key={row.id} 
                        info={row}
                        cancel={true}
                        openMessage={openMessage}
                        handleClickClose={handleClickClose}
                        getMyReservation={getMyReservation}
                        setIsBtnDisabled={setIsBtnDisabled}
                        isBtnDisabled={isBtnDisabled}
                        openMessageWarning={openMessageWarning}
                      />
                    ))
                  : <div className='containerMessageNoReservation'>
                        <p className='noReservations'>You don't have reservations</p>
                      </div>
                }
              </Grid>

              <Grid container style={{margin: '30px 0 30px 0'}}>
                <h3 className='titleMain'>Reservation History</h3>
              </Grid>

              <Grid>
                {paginator?.responseData?.length > 0
                  ? paginator?.responseData?.map((row)=> (
                      <Reservation 
                        key={row.id} 
                        info={row} 
                        cancel={false}
                      />
                    ))
                  : <div className='containerMessageNoReservation'>
                        <p className='noReservations'>You don't have reservations that have ended</p>
                      </div>
                }
              </Grid>
            </Grid>
          : loading
            ? <div style={{width: '100%', textAlign: 'center'}}><Loading/></div>
            : <div style={{display: 'flex'}}><Empty/></div>
        }

      </Grid>

      <Grid container justifyContent="center">
        { paginator?.responseData?.length > 0 
          ? <TablePagination
              rowsPerPageOptions={[1,6, 10, 20, 50]}
              count={paginator?.totalRecords}
              rowsPerPage={paginator?.pageSize}
              page={paginator?.pageNumber}
              labelRowsPerPage="Rows per page:"
              onPageChange={ changePage}
              onRowsPerPageChange={ changeNumberrecords}
            />
          : null
              
        }
      </Grid>
      
      <ConfirmationMessage
        description={message.description}
        textButtonSubmit={message.textButtonSubmit}
        handleClick={message.handleClick}
        handleClickOut={message.handleClickOut}
        oneButtons={message.oneButtons}
        type={message.type}
        open={message.open}
      />
    </div>
  )
}

export default MyReservation