import {
  Badge,
  Switch,
  TextField,
  Tooltip,
  tooltipClasses,
  OutlinedInput,
} from "@mui/material";
import { alpha, styled } from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

export const VALIDNAME = /^(([A-Za-zÀ-ÿ])+[ ]?)*$/g;
export const VALIDNAMESPACE = /^(([A-Za-zÀ-ÿ])+?)*$/g;
export const LOWERCASE = /(?=.*[a-z])/;
export const UPPERCASE = /(?=.*[A-Z])/;
export const NUMERIC = /(?=.*[0-9])/;
export const SYMBOL = /(?=.*[!@#$%^&*.])/;
export const ONLYNUMBER = /^(([0-9])+?)*$/g;
export const LETTERANDNUMBER = /^([0-9A-Za-zÀ-ÿ]{0,16})$/;
export const ENTERMIN = (number) => `Enter minimum ${number} characters`;
export const ENTERMAX = (number) => `Enter maximum ${number} characters`;
export const ENTERVALIDNAME = (text) => `Please enter a valid ${text}`;
export const VALIDFORMATDATE = "invalid date format";
export const ENTERONLYNUMBER = "Enter only numbers";
export const MINDATE = "Enter a date greater than the current date";
export const VALIDEMAIL = "Enter a valid email address";
export const REQUIRED_FIELD = "This field is required";
export const MESSAGECHANGESTATUS = (text) =>
  `Are you sure to ${text} this reservation?`;
export const MAXDAYSFORCANCEL =
  "Mr. user, the minimum time to cancel has already been issued (it must be greater than 15 days from the date of arrival)";

// message modal confirm
export const MESSAGESUCCESS = "The information has been sent successfully.";
export const MESSAGEERROR = "The information has not been sent, try again.";
export const MESSAGEPROBLEM = "There was a problem, please try again.";
export const MESSAGESUCCESSUPDATE =
  "The information has been successfully updated.";
export const MESSAGEERRORUPDATE =
  "The information has not been updated, try again.";
export const MESSAGETRYAGAIN =
  "The operation has not been completed, please try again.";
export const MESSAGEERRORGENERAL =
  "At this moment we are having problems loading the information.";
export const MESSAGESAVE = "Are you sure you want to save this information?";
export const MESSAGEDELETE = "The information has been removed";
export const MESSAGEERRORDELETE = "The information has not been removed.";
export const MESSAGEINACTIVATED = "The location has been disabled.";
export const MESSAGEACTIVATED = "The location has been activated.";
export const MESSAGEEXPIRATIONMESSAGE =
  "For technical reasons the information is not saved, please try again.";

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#243747",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#243747",
  },
}));

export const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#5CE706",
    "&:hover": {
      backgroundColor: alpha("#5CE706", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#5CE706",
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "10px",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export const CssTextField = styled(TextField)({
  borderRadius: "20px !important",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#bdbdbd",
      borderRadius: "20px",
      minHeight: "43px",
    },
    "&:hover fieldset": {
      borderColor: "#243747",
    },
  },
});

export const CssOutlinedInput = styled(OutlinedInput)({
  borderRadius: "20px !important",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#bdbdbd",
      borderRadius: "20px",
      minHeight: "43px",
    },
    "&:hover fieldset": {
      borderColor: "#243747",
    },
  },
});

export const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 0,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#ff180A",
  },
}));
