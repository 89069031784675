// @vendors
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";

// @constants
import { AccordionSummary } from "../../utils/constants-utils";
import { headers } from "./headers";
import { getValueByKeyForReservations } from "../../utils/functions-utils";

// @style
import "../reservations/style-reservations.css";
import "../../App.css";

const DetailCanceledReservation = ({ data }) => {
  return (
    <div style={{ margin: "0 0 10px 0", width: "100%" }} key={data?.id}>
      <Accordion style={{ borderRadius: "10px" }}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={
            <ArrowForwardIosSharpIcon
              sx={{
                fontSize: "1.4rem",
                marginLeft: 0,
                color: "#5f788d",
              }}
            />
          }
        >
          <div className="containerDetailTitle" key={data?.id}>
            {headers.initialHeaders.map((header, index) => (
              <div className="column" key={index}>
                <Typography style={{ fontSize: 14 }}>{header.title}</Typography>
                <Typography
                  className="secondaryHeading"
                  style={{ fontSize: 13 }}
                >
                  {getValueByKeyForReservations(data, header)}
                </Typography>
              </div>
            ))}
          </div>
        </AccordionSummary>
        <AccordionDetails className="detailTable">
          <Grid container className="containerDetail">
            {headers.otherHeaders.map((header, index) => (
              <div className="columnReservation" key={index}>
                <Typography style={{ fontSize: 14 }}>{header.title}</Typography>
                <Typography
                  className="secondaryHeading"
                  style={{ fontSize: 13 }}
                >
                  {getValueByKeyForReservations(data, header)}
                </Typography>
              </div>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default DetailCanceledReservation;
