// @vendors
import React from "react";

// @style
import "./style-conditions.css";

const ViewConditions = () => {
  return (
    <div className="containAdults">
      <h3 className="subtitleMainH3">Conditions</h3>
      <div>
        <ul>
          <li>
            Team International will cover the rental property’s monthly cost.
          </li>
          <li>
            Employee/contractors assume the transportation and meals cost.
          </li>
          <li>
            Employee/contractors can use the benefit to work remotely or enjoy
            PTO/vacation days.
          </li>
          <li>
            No per diem is applicable for any trips made to the Team Homes.
          </li>
          <li>
            All reservations will be reviewed and approved by the HR Team
            according to policy specifications and property availability.
          </li>
          <li>
            All reservation must be done using the housing portal:
            https://global.teamhousing.teaminternational.com/dashboard/reservations
          </li>
          <li>
            In case of cancellation, it must be requested through the platform
            at least 15 days before the check-in date. If the user must cancel,
            and does not do it in due time, the user will have to pay 50% of the
            value of the reservation.
          </li>
          <li>
            If, for any reason, the employee leaves TEAM International before
            the date of his/her reservation, the booking will be automatically
            cancelled.
          </li>
        </ul>

        <div className="containTimes">
          <p className="textGeneral">
            It is our intent to administer this plan fairly and equitably to the
            interest of all parties. Team reserves the right to change, alter,
            increase, decrease, substitute, suspend or terminate the plan in
            whole or in part at any time during its term with or without prior
            notification to eligible participants.
          </p>

          <p className="textGeneral">
            This document explains the main features of TEAM INTERNATIONAL’s –
            Benefit Plans. It is not intended to give you all the details or
            cover every possible situation that could occur. If any discrepancy
            should occur, the TEAM President is the final decision-making
            authority.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ViewConditions;
