// @vendors
import React from "react";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/GroupsOutlined";
import TermsconditionIcon from "@mui/icons-material/ContactSupportOutlined";
import LocationIcon from "@mui/icons-material/RoomOutlined";
import AddLocationIcon from "@mui/icons-material/AddLocationOutlined";
import FlyIcon from "@mui/icons-material/FlightTakeoffOutlined";
import CancelFlyIcon from "@mui/icons-material/CancelOutlined";
import PlaneIcon from "@mui/icons-material/FlightOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import ChangePasswordIcon from "@mui/icons-material/SyncLockOutlined";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// @img
import profile from "../../assets/svg/logo_team.svg";

// @constants
import { ROUTES } from "../routes/constants";

// @style
import "./style-navigation.css";

const DrawerApp = ({ open, handleDrawerClose }) => {
  const state = useSelector((state) => state);
  const stateIcon = state?.iconReducer;
  const userRole = state?.listRolesReducer.roles;

  return (
    <Drawer
      variant={!open ? "permanent" : "temporary"}
      sx={{
        width: 230,
        backgroundColor: "#F0F4F7",
        flexShrink: 0,
        display: { xs: `${open ? "block" : "none"}`, sm: "block" },
        "& .MuiDrawer-paper": {
          width: 230,
          background: "#F0F4F7",
          padding: "15px 15px 15px 15px",
          boxSizing: "border-box",
          border: "none",
        },
      }}
      anchor="left"
      open={open}
      onClose={handleDrawerClose}
      ModalProps={{
        keepMounted: false,
      }}
    >
      <div className="drawer">
        <Toolbar>
          <img src={profile} alt="logoTeam" className="imgTeam" />
        </Toolbar>
        <Divider
          style={{ color: "#fff", background: "#fff", margin: "0px 20px" }}
        />
        <List component="div">
          <ListItem
            disablePadding
            component={Link}
            to={ROUTES.TERMSANDCONDITIONS}
            className="listItem"
            onClick={handleDrawerClose}
          >
            <ListItemButton>
              <TermsconditionIcon
                className={
                  stateIcon.index === 7 ? "iconDrawerFocus" : "iconDrawer"
                }
              />
              <ListItemText
                primary="Terms And Conditions"
                className={
                  stateIcon.index === 7 ? "textDrawerFocus" : "textDrawer"
                }
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            component={Link}
            to={ROUTES.LOCATION}
            className="listItem"
            onClick={handleDrawerClose}
          >
            <ListItemButton className="ListItemButton">
              <LocationIcon
                className={
                  stateIcon.index === 1 ? "iconDrawerFocus" : "iconDrawer"
                }
              />
              <ListItemText
                primary="Locations"
                className={
                  stateIcon.index === 1 ? "textDrawerFocus" : "textDrawer"
                }
              />
            </ListItemButton>
          </ListItem>

          {userRole === "SUPERADMIN" || userRole === "HR" ? (
            stateIcon.index !== 2.2 ? (
              <ListItem
                disablePadding
                component={Link}
                to={ROUTES.ADDLOCATION}
                className="listItem"
                onClick={handleDrawerClose}
              >
                <ListItemButton>
                  <AddLocationIcon
                    className={
                      stateIcon.index === 2 ? "iconDrawerFocus" : "iconDrawer"
                    }
                  />
                  <ListItemText
                    primary="Add Locations"
                    className={
                      stateIcon.index === 2 ? "textDrawerFocus" : "textDrawer"
                    }
                  />
                </ListItemButton>
              </ListItem>
            ) : null
          ) : null}

          <ListItem
            disablePadding
            component={Link}
            to={ROUTES.MYRESERVATION}
            className="listItem"
            onClick={handleDrawerClose}
          >
            <ListItemButton>
              <PlaneIcon
                className={
                  stateIcon.index === 3 ? "iconDrawerFocus" : "iconDrawer"
                }
              />
              <ListItemText
                primary="My Reservations"
                className={
                  stateIcon.index === 3 ? "textDrawerFocus" : "textDrawer"
                }
              />
            </ListItemButton>
          </ListItem>

          {userRole === "SUPERADMIN" || userRole === "HR" ? (
            <ListItem
              disablePadding
              component={Link}
              to={ROUTES.RESERVATIONS}
              className="listItem"
              onClick={handleDrawerClose}
            >
              <ListItemButton>
                <FlyIcon
                  className={
                    stateIcon.index === 4 ? "iconDrawerFocus" : "iconDrawer"
                  }
                />
                <ListItemText
                  primary="Reservations"
                  className={
                    stateIcon.index === 4 ? "textDrawerFocus" : "textDrawer"
                  }
                />
              </ListItemButton>
            </ListItem>
          ) : null}

          {userRole === "SUPERADMIN" ? (
            <ListItem
              disablePadding
              component={Link}
              to={ROUTES.CANCELEDRESERVATION}
              className="listItem"
              onClick={handleDrawerClose}
            >
              <ListItemButton>
                <CancelFlyIcon
                  className={
                    stateIcon.index === 9 ? "iconDrawerFocus" : "iconDrawer"
                  }
                />
                <ListItemText
                  primary="Canceled Reservations"
                  className={
                    stateIcon.index === 9 ? "textDrawerFocus" : "textDrawer"
                  }
                />
              </ListItemButton>
            </ListItem>
          ) : null}

          {userRole === "MANAGER" ||
          userRole === "SUPERADMIN" ||
          userRole === "HR" ? (
            <ListItem
              disablePadding
              component={Link}
              to={ROUTES.GROUPRESERVATION}
              className="listItem"
              onClick={handleDrawerClose}
            >
              <ListItemButton>
                <GroupIcon
                  className={
                    stateIcon.index === 5 ? "iconDrawerFocus" : "iconDrawer"
                  }
                />
                <ListItemText
                  primary="Group Booking"
                  className={
                    stateIcon.index === 5 ? "textDrawerFocus" : "textDrawer"
                  }
                />
              </ListItemButton>
            </ListItem>
          ) : null}

          {userRole === "SUPERADMIN" ? (
            <ListItem
              disablePadding
              component={Link}
              to={ROUTES.CHANGEPASSWORDTOEMAIL}
              className="listItem"
              onClick={handleDrawerClose}
            >
              <ListItemButton>
                <ChangePasswordIcon
                  className={
                    stateIcon.index === 8 ? "iconDrawerFocus" : "iconDrawer"
                  }
                />
                <ListItemText
                  primary="change password to email"
                  className={
                    stateIcon.index === 8 ? "textDrawerFocus" : "textDrawer"
                  }
                />
              </ListItemButton>
            </ListItem>
          ) : null}

          {userRole === "SUPERADMIN" || userRole === "HR" ? (
            <ListItem
              disablePadding
              component={Link}
              to={ROUTES.TABSSETTINGS}
              className="listItem"
              onClick={handleDrawerClose}
            >
              <ListItemButton>
                <SettingsIcon
                  className={
                    stateIcon.index === 6 ? "iconDrawerFocus" : "iconDrawer"
                  }
                />
                <ListItemText
                  primary="Settings"
                  className={
                    stateIcon.index === 6 ? "textDrawerFocus" : "textDrawer"
                  }
                />
              </ListItemButton>
            </ListItem>
          ) : null}
        </List>
      </div>
    </Drawer>
  );
};

export default DrawerApp;
