// @vendors
import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Grid, TablePagination } from '@mui/material';
import moment from 'moment';

// @constants
import { FILTER_RESERVATION, ICON_REDUCER } from '../../redux/constants';
import ConfirmationMessage from '../../utils/modal-confirm/confirm-message';
import { debounce, getRequestErrorMessage } from '../../utils/functions-utils';

//style
import '../../App.css';
import './style-reservations.css';

// @components
import ReservationDetails from './reservation-details';
import SearchReservation from './search-reservation';
import Loading from '../../components/loading/loading';
import Empty from '../../components/empty/empty'

// actions
import { getCitys } from '../../actions/city-country-action';
import { getReservation } from '../../actions/reservations-action';

// @hooks
import { useModalMessage } from '../../hooks/use-modal-message';

const Reservations = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const filterReservation = state?.filterReservationReducer;
  const [listLocation, setListLocation] = useState([]);
  const [citys, setCitys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [btnIsDisabled, setBtnIsDisabled] = useState(false);
  const {message, handleClickClose, openMessage, openMessageWarning} = useModalMessage();
  const [dataFilter, setDataFilter] = useState({
    location: null,
    from: null,
    to: null,
    status: null,
    isGroup: null,
    email: null
  })

  const [paginatorRequest, setPaginatorRequest] = useState({
    page: 0,
    size: 10
  })

  const [paginator, setPaginator] = useState({
    responseData: [],
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0 
  })

  const changeNumberrecords = (event ) => {
    setPaginatorRequest((anterior) => ({
    ...anterior,
    size: parseInt(event.target.value, ),
    page: 0,
    }));
  }

  const changePage = (event, newPage) => {
    setPaginatorRequest((anterior) => ({
    ...anterior,
    page:  parseInt(newPage),
    }));
  }

  useEffect(() => {
    dispatch({
      type: ICON_REDUCER,
      index: 4
    })
  }, [dispatch])
  
  const getAllReservation = async () => {
    const filters = {
      location: dataFilter.location === null ? null : dataFilter.location,
      from: dataFilter.from === null ? null : moment(dataFilter.from).toISOString(),
      to: dataFilter.to === null ? null : moment(dataFilter.to).toISOString(),
      status: dataFilter.status === null ? null : dataFilter.status,
      IsGroup: dataFilter.isGroup === null ? null : dataFilter.isGroup,
      Email: dataFilter.email === null ? null : dataFilter.email,
    }
    setLoading(true);
    const response = await getReservation(paginatorRequest, filters);
    if(response?.status === 200 || response?.status === 201){
      const data = response?.data?.reservations;
      setPaginator({
        responseData: data?.resource,
        pageNumber: data?.pageNumber,
        pageSize: data?.pageSize,
        totalPages:data?.totalPages,
        totalRecords: data?.totalRecords,
      })
      setLoading(false);
    }else if(response?.status > 399){
      setLoading(false);
      getRequestErrorMessage(setPaginator, openMessageWarning, 'WARNING');
    }else{
      setLoading(false);
      getRequestErrorMessage(setPaginator, openMessageWarning, 'ERROR');
    }
  }

  useEffect(() => {
    getAllReservation();
  }, [paginatorRequest])
  
  if(filterReservation && filterReservation.hasFilter && dataFilter !== filterReservation.data){
    const filter = filterReservation.data;
    dataFilter.location = filter?.location?.name?.toLowerCase();
    dataFilter.from = filter.from;
    dataFilter.to = filter.to;
    dataFilter.status = filter.status;
    dataFilter.isGroup = filter.isGroup;
    dataFilter.email = filter.email;
    setPaginatorRequest.page = 0;
    dispatch({
      type: FILTER_RESERVATION,
      hasFilter: false
    });
    getAllReservation();
  }

  const getAllcitys = async (city) =>{
    const filter = {name:city};
    const response = await getCitys(filter);
    if(response?.status === 200 || response?.status === 201){
      setCitys(response?.data);
    }else{
      setCitys([]);
    }
  }

  const queryLocationValue = debounce(function(e){
    if(e.target.value.length > 2){
      getAllcitys(e.target.value);
    }
  },1000);

  return (
    <div className='rootGeneral'>
      <Grid>
        <Grid style={{margin: '0 0 30px 0'}}>
          <h3 className='titleMain'>Reservations</h3>
        </Grid>

        <div className='contSearchReservation'>
          <SearchReservation
            setListLocation={setListLocation}
            listLocation={listLocation}
            citys={citys}
            queryLocationValue={queryLocationValue}
          />
        </div>

        <Grid style={{textAlign: 'center', justifyContent: 'center'}}>
        {paginator?.responseData.length > 0 
          ? paginator?.responseData.map((row)=> (
            <ReservationDetails 
              key={row?.id} 
              data={row}
              getAllReservation={getAllReservation}
              setBtnIsDisabled={setBtnIsDisabled}
              btnIsDisabled={btnIsDisabled}
              handleClickClose={handleClickClose}
              openMessageWarning={openMessageWarning}
              openMessage={openMessage}
            />
          ))
          : loading
            ? <Loading/>
            : <div style={{display: 'flex'}}><Empty/></div>
        }
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        { paginator?.responseData.length > 0 
            ? <TablePagination
                rowsPerPageOptions={[6, 10, 20, 50]}
                count={paginator?.totalRecords}
                rowsPerPage={paginator?.pageSize}
                page={paginator?.pageNumber}
                labelRowsPerPage="Rows per page:"
                onPageChange={ changePage}
                onRowsPerPageChange={ changeNumberrecords}
              />
            : null
              
        }
      </Grid>

      <ConfirmationMessage
        description={message.description}
        textButtonSubmit={message.textButtonSubmit}
        handleClick={message.handleClick}
        handleClickOut={message.handleClickOut}
        oneButtons={message.oneButtons}
        type={message.type}
        open={message.open}
      />
    </div>
  )
}

export default Reservations