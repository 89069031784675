import { FILTER_LOCATION } from "../constants";

const initialState = {
    data:{
        location: null,
        name: null,
        locationType: null
    },
    hasFilter: false
}

const filterLocationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FILTER_LOCATION:
            
        return {
            ...state,
            data: action.data,
            hasFilter: action.hasFilter
        }
    
        default:
            return state;
    }
}

export default filterLocationReducer;