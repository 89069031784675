import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import CameraIcon from '@mui/icons-material/CameraAltOutlined';
import { useParams } from 'react-router-dom';
// @style
import '../style-add-location.css';

// @action
import { getGalleryByLocation } from '../../../actions/photo-action';

//@components
import UpdatedGalleryModal from '../../../components/modals/updated-gallery/updated-gallery-modal';

const AddGallery = ({setListGallery}) => {
  const [gallery, setGallery] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const handleModal = () => setOpenModal(!openModal);
  const {idLocation} = useParams();

  const handleImage = async (e) => {
    if(idLocation)setOpenModal(true);
    if(e.target.files.length > 0){
      setGallery([]);    
      setListGallery([]); 
    }
    const files = e.target.files;
    createPreview(files);
    setListGallery(files); 
  }
  
  const createPreview = (files) => {
    for (const file of files) {
          const imgCodified = URL.createObjectURL(file);
          setGallery((current) => current.concat(imgCodified));
      }
  }
    
  const handlePhoto = () => {
      const add_photo = document.getElementById('add_photo_gallery')
      add_photo.click();
  }

  const lookingForImages = async (id) => {
    const response = await getGalleryByLocation(id);
    const result = response?.data?.filter(x => x?.isMain === false);
    if(!result)setGallery([]);
    if(result.length){
      const galleryImages = result.map(item=>{
        return item;
      });
      setGallery(galleryImages);
      setListGallery(result);
    }  
  };

  useEffect(() => {
    if(idLocation){
      lookingForImages(idLocation);
    } 
  }, [idLocation]);

  return (
    <>
      <Typography variant='body1' style={{fontSize: '14px'}}>
        Gallery
      </Typography>
        
      <input 
          type='file' 
          onChange={(e) => handleImage(e)} 
          id='add_photo_gallery' 
          style={{ display: 'none' }} 
          multiple
          accept={['image/png', 'image/jpeg', 'image/jpg']}
      />
      <div className='addImage' id='see_photo_container_gallery' onClick={ idLocation?handleModal:handlePhoto }>
        { gallery.length > 0
          ? <Grid container spacing={ 1 }>
              { gallery.map((item,index) => (
                  <Grid item xs={6} md={6} lg={4} key={index}>
                    <img src={idLocation ? item?.photo : item} alt='img' id='see_photo_gallery' className='imageAddLocationGallery'/> 
                  </Grid>
                ))
              }
            </Grid>
          : <CameraIcon style={{color: '#5f788d', fontSize: '60px'}}/>
        }
      </div>

      <UpdatedGalleryModal
        open={openModal}
        onClose={handleModal}
        gallery={gallery}
        setGallery={setGallery}
        idLocation={idLocation}
        lookingForImages={lookingForImages}
      />
    </>
  )
}

export default AddGallery;