import HttpClient from '../services/http-client';
import {
    GALLERYBYLOCATION,
    GALLERYLOCATION,
    GETPHOTO,
    HEADERS,
    IMAGELOCATION
} from './contants';

export const getPhotoById = (idGallery) => {
    return new Promise((resolve) => {
        HttpClient.get(`${GETPHOTO}${idGallery}`, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}

//CONSULTA NUEVA PARA IMAGENES POR URL

export const getGalleryByLocation = (idLocation) => {
    return new Promise((resolve) => {
        HttpClient.get(`${GALLERYBYLOCATION}${idLocation}`, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}

export const addPhotoForLocation = (formData) => {
    return new Promise((resolve) => {
        HttpClient.post(`${IMAGELOCATION}`, formData, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}

export const addGalleryForLocation = (formData) => {
    return new Promise((resolve) => {
        HttpClient.post(`${GALLERYLOCATION}`, formData, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
}

export const updatedPhoto = (id, formData) => {
    return new Promise((resolve) => {
        HttpClient.put(`${IMAGELOCATION}/${id}`, formData, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
};

export const deletePhotoForGallery = (id) => {
    return new Promise((resolve) => {
        HttpClient.delete(`${IMAGELOCATION}/${id}`, HEADERS).then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error?.response);
        })
    })
};