import { legacy_createStore as createStore } from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import RootReducer from '../reducers';

const persistConfig = {
    key: 'persist-key',
    storage
}

const persistedReducer = persistReducer(persistConfig, RootReducer);
const store = createStore(persistedReducer);

const persistor = persistStore(store);
export default store;
export {persistor}