// @vendors
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Grid, TablePagination } from "@mui/material";

// @constants
import { ICON_REDUCER } from "../../redux/constants";
import ConfirmationMessage from "../../utils/modal-confirm/confirm-message";
import { getRequestErrorMessage } from "../../utils/functions-utils";

//style
import "../../App.css";
import "../reservations/style-reservations.css";

// @components
import ReservationDetails from "./detail-canceled-reservations";
import Loading from "../../components/loading/loading";
import Empty from "../../components/empty/empty";

// actions
import { getCancelReservation } from "../../actions/reservations-action";

// @hooks
import { useModalMessage } from "../../hooks/use-modal-message";

const CanceledReservations = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [btnIsDisabled, setBtnIsDisabled] = useState(false);
  const { message, handleClickClose, openMessage, openMessageWarning } =
    useModalMessage();

  const [paginatorRequest, setPaginatorRequest] = useState({
    page: 0,
    size: 10,
  });

  const [paginator, setPaginator] = useState({
    responseData: [],
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
  });

  const changeNumberrecords = (event) => {
    setPaginatorRequest((anterior) => ({
      ...anterior,
      size: parseInt(event.target.value),
      page: 0,
    }));
  };

  const changePage = (event, newPage) => {
    setPaginatorRequest((anterior) => ({
      ...anterior,
      page: parseInt(newPage),
    }));
  };

  useEffect(() => {
    dispatch({
      type: ICON_REDUCER,
      index: 9,
    });
  }, [dispatch]);

  const getCanceledReservation = async () => {
    setLoading(true);
    const response = await getCancelReservation(paginatorRequest);
    if (response?.status === 200 || response?.status === 201) {
      const data = response?.data?.reservations;
      setPaginator({
        responseData: data?.resource,
        pageNumber: data?.pageNumber,
        pageSize: data?.pageSize,
        totalPages: data?.totalPages,
        totalRecords: data?.totalRecords,
      });
      setLoading(false);
    } else if (response?.status > 399) {
      setLoading(false);
      getRequestErrorMessage(setPaginator, openMessageWarning, "WARNING");
    } else {
      setLoading(false);
      getRequestErrorMessage(setPaginator, openMessageWarning, "ERROR");
    }
  };

  useEffect(() => {
    getCanceledReservation();
  }, [paginatorRequest]);

  return (
    <div className="rootGeneral">
      <Grid>
        <Grid style={{ margin: "0 0 30px 0" }}>
          <h3 className="titleMain">Canceled Reservations</h3>
        </Grid>

        <Grid style={{ textAlign: "center", justifyContent: "center" }}>
          {paginator?.responseData.length > 0 ? (
            paginator?.responseData.map((row) => (
              <ReservationDetails
                key={row?.id}
                data={row}
                getAllReservation={getCanceledReservation}
                setBtnIsDisabled={setBtnIsDisabled}
                btnIsDisabled={btnIsDisabled}
                handleClickClose={handleClickClose}
                openMessageWarning={openMessageWarning}
                openMessage={openMessage}
              />
            ))
          ) : loading ? (
            <Loading />
          ) : (
            <div style={{ display: "flex" }}>
              <Empty />
            </div>
          )}
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        {paginator?.responseData.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[6, 10, 20, 50]}
            count={paginator?.totalRecords}
            rowsPerPage={paginator?.pageSize}
            page={paginator?.pageNumber}
            labelRowsPerPage="Rows per page:"
            onPageChange={changePage}
            onRowsPerPageChange={changeNumberrecords}
          />
        ) : null}
      </Grid>

      <ConfirmationMessage
        description={message.description}
        textButtonSubmit={message.textButtonSubmit}
        handleClick={message.handleClick}
        handleClickOut={message.handleClickOut}
        oneButtons={message.oneButtons}
        type={message.type}
        open={message.open}
      />
    </div>
  );
};

export default CanceledReservations;
