// @vendors
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

// @action
import { saveCountry } from "../../../actions/city-country-action";

// @components
import FormCountry from "./form-country";
import {
  ENTERMAX,
  ENTERMIN,
  ENTERVALIDNAME,
  REQUIRED_FIELD,
  VALIDNAME,
} from "../../../utils/constants-utils";
import { handleOpenBackdrop } from "../../../utils/functions-utils";

const defaultValue = {
  name: "",
  code: "",
};

const AddCountry = ({
  openMessage,
  handleClickClose,
  messageByRequest,
  openMessageWarning,
}) => {
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(REQUIRED_FIELD)
      .min(3, ENTERMIN(3))
      .max(10, ENTERMAX(100))
      .matches(VALIDNAME, ENTERVALIDNAME("name")),
    code: yup
      .string()
      .nullable()
      .required(REQUIRED_FIELD)
      .min(2, ENTERMIN(2))
      .max(20, ENTERMAX(20)),
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValue,
    mode: "all",
    reValidateMode: "onChange",
  });

  const handleClickCancelForm = () => {
    setValue("name", "");
    setValue("code", "");
  };

  const onSubmit = (data) => {
    const newData = {
      name: data.name,
      code: data.code.toUpperCase(),
      active: true,
    };
    const msj = "Are you sure to add this country?";
    openMessage(msj, handleClickCountry, newData);
  };

  const handleClickCountry = async (info) => {
    handleOpenBackdrop(dispatch, true);
    handleClickClose();
    const response = await saveCountry(info);
    messageByRequest(
      response,
      openMessageWarning,
      "post",
      handleClickCancelForm
    );
    handleOpenBackdrop(dispatch, false);
  };

  return (
    <div>
      <FormCountry
        onSubmit={onSubmit}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        control={control}
        handleClickCancelForm={handleClickCancelForm}
      />
    </div>
  );
};

export default AddCountry;
